(function () {
  'use strict';

  angular
    .module('tallyfy.config')
    .config(blockUIConfig);

  blockUIConfig.$inject = ['blockUIConfig'];

  function blockUIConfig(blockUIConfig) {
    blockUIConfig.delay = 0;
    blockUIConfig.template = '<div class="loader"><div class="loader-logo"><img src="/assets/images/logo-padded@2x.png"></div><div class="spinner"></div><div class="loader-message">{{ state.message }}</div>';
    blockUIConfig.message = ''; // Set the default message as empty string
    blockUIConfig.blockBrowserNavigation = true;
    blockUIConfig.autoBlock = false;
    blockUIConfig.autoInjectBodyBlock = false;
  }
})();
