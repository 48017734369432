/**
 * @ngdoc Component
 * @name tallyfy.support.component.tallyfyUserTable
 * @module tallyfy.support
 *
 * @description
 * A component to manage User List
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.support')
    .component('tallyfyUserTable', {
      bindings: {
        orgId: '<?',
        orgName: '=?'
      },
      templateUrl: 'app/modules/support/components/users/tallyfyUserTable/tallyfy-user-table.html',
      controller:
        /*@ngInject*/
        function (_, $q, $filter, $log, $state, Helper, $stateParams, DateUtils, moment, ENV_CONFIG, MOESIF_URL, Auth, blockUI, Growl, SupportService, OrganizationsService, $confirm) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('supportUserList'),
            growl = new Growl();

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.initTableConfig = initTableConfig;
          $ctrl.getUsers = getUsers;

          $ctrl.listPager = { per_page: 10, page: 1 };
          $ctrl.perPageData = [5, 10, 20];

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() { }
        
          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */  
          function onChanges() { }
          
          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }
          
          function initTableConfig() {
            $ctrl.tableOptions = getTableConfig();
          }

          function getTableConfig() {
            var hideAndShowColumns = [{
              field: 'user',
              title: $filter('translate')('regularUsers.table.header.user'),
              initState: true
            }, {
              field: "role",
              title: $filter('translate')('regularUsers.table.header.role'),
              initState: true
            }, {
              field: "createdAt",
              title: $filter('translate')('regularUsers.table.header.firstCreated'),
              initState: true
            }, {
              field: "lastAccessed",
              title: $filter('translate')('regularUsers.table.header.lastAccessed'),
              initState: true
            }, {
              field: 'accessed',
              title: $filter('translate')('regularUsers.table.header.accessed'),
              initState: true
            }];
            
            return {
              beautyScrollStyle: true,
              gridConfig: getUserTableOptions(),
              tableState: {
                sort: {},
                columns: {}
              },
              tableMenus: {
                export: true,
                hideAndShowColumns: hideAndShowColumns
              },
              tableFilterModel: {
                user: {
                  value: '',
                  operator: 'contains'
                },
                role: {
                  value: '',
                  operator: 'contains'
                }
              },
              templateScope: {
                callbacks: {
                  resetPassword: resetPassword,
                  switchUser: switchUser,
                  masqueradeUser: masqueradeUser,
                  sortTable: sortTable,
                  onColumnFilter: onColumnFilter
                },
                variables: {
                  moesifURL: _.toLower(ENV_CONFIG.APP_ENV) === 'prod' ? MOESIF_URL.production : MOESIF_URL.staging,
                  capsuleCRMUrl: ENV_CONFIG.TALLYFY_CAPSULECRM_URL,
                  orgName: $ctrl.orgName,
                  dateFormat: OrganizationsService.getDateFormat(),
                  searchParams: {},
                  editedUser: void 0,
                  isOnEdit: false
                }
              }
            };
          }
          
          function sortTable(column) {
            $ctrl.tableOptions.gridConfig.dataSource.data([]);
            $ctrl.isLoading = true;
            if ($ctrl.tableOptions.tableState.sort.field !== column) {
              $ctrl.tableOptions.tableState.sort.dir = 'asc';
              $ctrl.tableOptions.tableState.sort.field = column;
            } else {
              if ($ctrl.tableOptions.tableState.sort.dir === 'desc') {
                $ctrl.tableOptions.tableState.sort = {};
              } else {
                $ctrl.tableOptions.tableState.sort.dir = $ctrl.tableOptions.tableState.sort.dir !== 'asc' ? 'asc' : 'desc';
                $ctrl.tableOptions.tableState.sort.field = column;
              }
            }
            kendo.ui.progress($ctrl.tableOptions.tableElement, true);
            var sign = $ctrl.tableOptions.tableState.sort.field === 'role'
              ? ($ctrl.tableOptions.tableState.sort.dir !== 'asc' ? '-' : '')
              : ($ctrl.tableOptions.tableState.sort.dir !== 'asc' ? '' : '-');
            getUsers(
              { sort: !Helper.isObjectEmpty($ctrl.tableOptions.tableState.sort) ? sign + column : void 0 }
            ).then(function (res) {
              $ctrl.tableOptions.gridConfig.dataSource.data(res);
              $ctrl.isLoading = false;
              kendo.ui.progress($ctrl.tableOptions.tableElement, false);
            }, function () {
              $ctrl.isLoading = false;
              kendo.ui.progress($ctrl.tableOptions.tableElement, false);
            });
          }
          
          function onColumnFilter(column) {
            $ctrl.textTemplatePagination = { per_page: 10, page: 1 };
            $ctrl.tableOptions.tableState.sort = {};
            $ctrl.tableOptions.gridConfig.dataSource.data([]);
            $ctrl.isLoading = true;
            kendo.ui.progress($ctrl.tableOptions.tableElement, true);
            getUsers({ q: $ctrl.tableOptions.templateScope.variables.searchParams.q }).then(function (res) {
              $ctrl.tableOptions.gridConfig.dataSource.data(res);
              $ctrl.isLoading = false;
              kendo.ui.progress($ctrl.tableOptions.tableElement, false);
            }, function () {
              $ctrl.isLoading = false;
              kendo.ui.progress($ctrl.tableOptions.tableElement, false);
            });
          }

          function getUserTableOptions() {
            return {
              columns: getUserTableColumns(),
              dataSource: new kendo.data.DataSource({
                pageSize: 10
              }),
              noRecords: {
                template: kendo.template($('#userListTableNoData').html())
              },
              resizable: true
            };
          }

          // user table columns
          function getUserTableColumns() {
            var columns = [{
              field: "user",
              exportKeyMap: {
                key: "user",
                label: $filter('translate')('regularUsers.table.header.user')
              },
              headerTemplate: kendo.template($('#userInfoColumnHeaderTemplate').html()),
              template: kendo.template($('#userInfoColumnTemplate').html()),
              width: 280
            }, {
              field: "role",
              exportKeyMap: {
                key: "role",
                label: $filter('translate')('regularUsers.table.header.role')
              },
              headerTemplate: kendo.template($('#userRoleColumnTemplate').html()),
              template: kendo.template($('#userRoleColumnHeaderTemplate').html()),
              width: 80
            }, {
              field: "createdAt",
              exportKeyMap: {
                key: "createdAt",
                label: $filter('translate')('regularUsers.table.header.firstCreated')
              },
              template: kendo.template($('#userCreatedAtColumnTemplate').html()),
              headerTemplate: kendo.template($('#userCreatedAtColumnHeaderTemplate').html()),
              width: 100
            }, {
              field: "lastAccessed",
              exportKeyMap: {
                key: "lastAccessed",
                label: $filter('translate')('regularUsers.table.header.lastAccessed')
              },
              template: kendo.template($('#userLastAccessedColumnTemplate').html()),
              headerTemplate: kendo.template($('#userLastAccessedColumnHeaderTemplate').html()),
              width: 140
            }, {
              field: "accessed",
              template: kendo.template($('#userAccessedColumnTemplate').html()),
              title: $filter('translate')('regularUsers.table.header.accessed'),
              width: 400
            }];
            return columns;
          }

          function getUsers(args) {
            var defer = $q.defer();
            $ctrl.isLoading = true;
            if (!args) {
              $ctrl.listPager.page = 0;
            }
            $ctrl.listPager = angular.extend($ctrl.listPager, {
              page: $ctrl.listPager.page || 1
            });
            var requestParams = {
              type: $ctrl.orgId,
              action: 'users',
              page: $ctrl.listPager.page,
              per_page: $ctrl.listPager.per_page
            };
            if ($ctrl.tableOptions.tableState.sort.field) {
              var sortDir = $ctrl.tableOptions.tableState.sort.field === 'role'
                ? ($ctrl.tableOptions.tableState.sort.dir !== 'asc' ? '-' : '')
                : ($ctrl.tableOptions.tableState.sort.dir !== 'asc' ? '' : '-');
              requestParams.sort = sortDir + $ctrl.tableOptions.tableState.sort.field;
            }
            if (_.get(args, 'q')) {
              requestParams.q = args.q;
            }
            SupportService.allUsers(requestParams).then(function (response) {
              var users = response.data || [];
              users = _.map(response.data, function(user) {
                user.activities = [];
                var invitedByUser = _.find(response.data, { 'id': user.invited_by }), disableByUser = _.find(response.data, { 'id': user.disabled_by }), reactivatedByUser = _.find(response.data, { 'id': user.reactivated_by });
                user.invited_by && invitedByUser ? user.activities.push({ userEmail: invitedByUser.email, actStatus: 1, activityDate: user.approved_at }) : angular.noop();
                user.disabled_by && disableByUser ? user.activities.push({ userEmail: disableByUser.email, actStatus: 2, activityDate: user.disabled_at }) : angular.noop();
                user.reactivated_by && reactivatedByUser ? user.activities.push({ userEmail: reactivatedByUser.email, actStatus: 3, activityDate: user.reactivated_at }) : angular.noop();
                user.roleStr = (_.get(user, 'roles') && _.get(user, 'roles.data') && _.get(user, 'roles.data[0]').name) ? _.get(user, 'roles.data[0]').name : 'N/A';
                return user;
              });
              $ctrl.listPager = response.meta.pagination;
              defer.resolve(users);
            }, function () {
              defer.reject();
            });
            return defer.promise;
          }

          function switchUser(uid) {
            var user = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            $confirm({
              'header': user.disabled_at ? $filter('translate')('support.modal.userEnable.header') : $filter('translate')('support.modal.userDisable.header'),
              'body': '',
              'buttons': {
                'accept': user.disabled_at ? $filter('translate')('support.users.buttons.enable') : $filter('translate')('support.users.buttons.disable'),
                'cancel': $filter('translate')('support.buttons.cancel')
              },
              'modalType': !user.disabled_at ? 'modal-danger' : angular.noop()
            }).then(function () {
              $ctrl.switchedUser = null;
              var requestParams = {
                action: $stateParams.user_org,
                sub_action_id: user.id,
                flag: user.disabled_at ? 1 : 0
              };
              SupportService.switchUser(requestParams).then(function (response) {
                if (response.data.result) {
                  if (user.disabled_at) {
                    user.disabled_at = null;
                  } else {
                    user.disabled_at = DateUtils.toUTC(moment().format());
                  }
                }
                $ctrl.tableOptions.gridConfig.dataSource.sync();
                $ctrl.tableOptions.tableElement.data('kendoGrid').refresh();
                $ctrl.switchedUser = user.id;
              }, function (error) {
              });
            });
          }

          function masqueradeUser(uid) {
            var user = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            blockUI.start();
            Auth.masqueradeUser(user, { admin: true }).then(function (organization) {
              blockUI.stop();
              $state.go('home', { org_id: organization.id }, { reload: true });
            }, function (error) {
              $log.error(error);
              blockUI.stop();
            });
          }

          /**
           * @ngdoc ngdoc
           * @name resetPassword
           * @private
           * @description Reset a user's password
           * @param uid
           */
          function resetPassword(uid) {
            $confirm({
              'header': 'support.modal.resetPassword.header',
              'body': '',
              'buttons': {
                'accept': 'support.users.buttons.reset_password',
                'cancel': 'global.confirmModal.buttons.cancel'
              },
              modalType: 'modal-confirm-delete-user'
            }).then(function () {
              var user = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
              blockUI.start();
              SupportService.resetPassword({
                sub_action_id: user.id
              }).then(function (resp) {
                if (resp.data) {
                  var info = 'New user password: <b>' + resp.data.new_password + '</b>';
                  growl.success(info);
                }
                blockUI.stop();
              });
            });
          }
        }
    });
})();