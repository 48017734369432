/**
 * @ngdoc Component
 * @name tallyfy.users.component.createGuestComponent
 * @module tallyfy.organizations.users
 *
 * @description
 * create guest
 *
 * @author Shubham Prasanna ( gmail::shubhamprasanna@gmail.com )
 */
 (function () {
  'use strict';
  angular
    .module('tallyfy.organizations.users')
    .component('createGuestComponent', {
      templateUrl: 'app/modules/users/guestUsers/create-guest/create-guest.component.html',
      bindings: {
        dismiss: '&',
        close: '&',
        resolve: '<?'
      },
      controller:
        /*@ngInject*/
        function (blockUI, UsersService, RolesService, _, CONST, $rootScope, TFY_EVENTS, $filter, growl, $state, $scope, MODAL_CLOSE_EVENTS, store, RegularUserTableService, TimeZone, OrganizationsService) {

          var $ctrl = this,
            blockUI = blockUI.instances.get('createGuestForm'),
            onModalClosePreventHandler;

          /*
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * Expose bindable methods
           * these methods are accessible in view
           */
          $ctrl.cancel = cancel;
          $ctrl.resetValidity = resetServerValidity;
          $ctrl.createGuest = createGuest;

          /**
           * public properties
           */
          $ctrl.user = {};
          $ctrl.emailPattern = CONST.EMAIL_REGEX;
          $ctrl.URLRegex = CONST.URL_REGEX;
          $ctrl.timeZones = [];
          $ctrl.isTimezoneDropdownOpen = false;

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            if ($ctrl.resolve && _.get($ctrl.resolve, 'email')) {
              $ctrl.user.email = _.get($ctrl.resolve, 'email');
            }
            $ctrl.focusOnFName = true;
            $ctrl.orgDetail =  _.get($rootScope, 'orgSamlInfo');
            $ctrl.user.timezone = _.get($ctrl.orgDetail, 'timezone');
            getCountryTimezone();
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */

          function onDestroy() {
            onModalClosePreventHandler();
          }

          /**
           * @ngdoc method
           * @name getCountryTimezone
           * @private
           * @description Get country timezone
           */
           function getCountryTimezone() {
            blockUI.start();
            TimeZone.getAll().then(function (response) {
              blockUI.stop();
              $ctrl.timeZones = response;
              $ctrl.user.timezone = $ctrl.user.timezone !== 'UTC' ? _.find($ctrl.timeZones, { value: $ctrl.user.timezone }) : 'UTC';
            }, function () {
               blockUI.stop();
            });
          }

          /*
           * @ngdoc method
           * @name cancel
           * @description Dismiss modal
           * @public
           * @returns {undefined}
           */
          function cancel() {
            $ctrl.dismiss();
          }

          /**
           * @ngdoc method
           * @name resetServerValidity
           * @description reset server side validation
           * @param key
           */
          function resetServerValidity(key) {
            if ($ctrl.guestForm[key].$invalid) {
              $ctrl.guestForm[key].$setValidity('server', true);
              delete $ctrl.guestForm[key].errorMessage;
            }
          }

          /**
           * Modal close prevent handler
           */
          onModalClosePreventHandler = $scope.$on('modal.closing', function (event, reason) {
            if ($ctrl.guestForm.$dirty && (reason === MODAL_CLOSE_EVENTS.OUTSIDECLICK || reason === MODAL_CLOSE_EVENTS.ESCAPEPRESS)) {
              event.preventDefault();
            }
          });

          /**
           * @ngdoc method
           * @name createGuest
           * @description create a new guest
           */
          function createGuest () {
            blockUI.start();
            $ctrl.user.timezone = $ctrl.user.timezone.value;
            OrganizationsService.createGuest($ctrl.user).then(function (response) {
              store.clearGuestsStore();
              store.getLightweightGuests();
              if ($state.current.name === "settings.org.orgGuestUsers") {
                $rootScope.$emit('USER:UPDATE_USERS', { guest: response.data });
              }
              blockUI.stop();
              growl.success($filter('translate')('guestUsers.messages.createdSuccessfully', {
                fullname: response.data.first_name + ' ' + response.data.last_name
              }), { referenceId: 'global', disableCloseButton: true });
              $ctrl.dismiss();
            }, function (error) {
              if (error.data.validation_errors) {
                _.forEach(error.data.validation_errors, function (msg, index) {
                  $ctrl.guestForm[index].$setValidity('server', false);
                  $ctrl.guestForm[index].errorMessage = _.first(msg);
                });
              } else {
                growl.error(error.data.message, { referenceId: 'GuestAdditionSuccessController', disableCloseButton: true });
              }
              blockUI.stop();
            });
          }
          //end of controller
        }
    });
})();
