/**
 * @ngdoc Service
 * @name tallyfy.account.fccount
 *
 * @module tallyfy.account.favorite
 *
 * @description
 * FavoriteService
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */

(function () {
  'use strict';

  angular
    .module('tallyfy.account')
    .service('FavoriteService', FavoriteService);

  FavoriteService.$inject = ['_', 'FavoriteRepository', '$rootScope', 'USER_STATE'];

  function FavoriteService(_, FavoriteRepository, $rootScope, USER_STATE) {
    var _this = this,
      isGuest = !!($rootScope.userState === USER_STATE.GUEST);

    this.getFavorite = function (arg) {
      var param = arg || {};
      angular.extend(param, { user: $rootScope.identity.id });
      return FavoriteRepository.getFavorite(param).$promise;
    };

    this.getGuest = function (arg) {
      var param = arg || {};
      return FavoriteRepository.getGuest(param).$promise;
    };

    this.getTargetOption = function () {
      return [
        { name: 'Email', translate: "favorite.option.email", value: 'email', icon:'fa-solid fa-envelope'},
        { name: 'Webhook', translate: "favorite.option.webhook", value: 'webhook', icon:'fa-solid fa-flag'},
        { name: 'Chat', translate: "favorite.option.chat", value: 'chat', icon:'fa-solid fa-comment'}];
    };

    this.getFrequencyOption = function () {
      return [
        { name: 'Electric', translate: "favorite.option.electric", explanationTranslate: "favorite.option.explanation.electric", value: 'electric', icon: 'fa-solid fa-bolt'},
        { name: 'Mindful', translate: "favorite.option.mindful", explanationTranslate: "favorite.option.explanation.mindful", value: 'mindful', icon:'fa-solid fa-brain'},
        { name: 'Chilled', translate: "favorite.option.chilled", explanationTranslate: "favorite.option.explanation.chilled", value: 'chilled', icon:'fa-solid fa-tint'}];
    };

    this.getWatchingOption = function (isGuest) {
      var options = [
        { name: 'member', translate: "favorite.option.member", value: 'user', checked: true, isEnabled: !isGuest },
        { name: 'blueprint', translate: "favorite.option.blueprint", value: 'checklist', checked: true, isEnabled: !isGuest },
        { name: 'process', translate: "favorite.option.process", value: 'run', checked: true, isEnabled: true },
        { name: 'task', translate: "favorite.option.task", value: 'task', checked: true, isEnabled: true }
      ];

      return _.filter(options, { isEnabled: true });
    };
    
    this.getCurrentUserWatcher = function (data) {
       var watcher;
       if (isGuest) {
         watcher = _.filter(data, function(r) {
           return _.get(r.watcher, 'data.email') === _.get($rootScope.identity, 'guest.email');
         });
       } else {
         watcher =  _.filter(data, function(r) { return r.created_by == _.get($rootScope.identity, 'id'); });
       }
       return watcher;
     };

     this.getAnotherUserWatcher = function (data) {
       var watcher;
       if (isGuest) {
         watcher = _.filter(data, function(r) {
           return _.get(r.watcher, 'data.email') !== _.get($rootScope.identity, 'guest.email');
         });
       } else {
         watcher = _.filter(data, function(r) { return r.created_by !== _.get($rootScope.identity, 'id'); });
       }
       return watcher;
     };

     this.addFavorite = function (arg) {
        return FavoriteRepository.addFavorite({
          user: arg.userId
        },{
          object_type: arg.objectType,
          object_id: arg.objId,
          frequency: "chilled",
          webhook: "https://example.com/",
          notification_type: "email",
          is_system_watch: true
        }).$promise;
    };

    this.addGuestFavorite = function (guestCode, arg) {
        return FavoriteRepository.addGuestFavorite({
          org: _.get($rootScope.identity, 'guest.organization.id'),
          guest_code: guestCode
        }, {
          object_type: arg.objectType,
          object_id: arg.objId,
          frequency: "chilled",
          webhook: "https://example.com/",
          notification_type: "email",
          is_system_watch: true
        }).$promise;
    };

    this.removeFavorite = function (id) {
      return FavoriteRepository.deleteFavorite({
        watcher_id: id
      });
    };

    this.removeGuestFavorite = function (guestCode, id) {
      return FavoriteRepository.deleteGuestFavorite({
        org: _.get($rootScope.identity, 'guest.organization.id'),
        guest_code: guestCode,
        watch_id: id
      });
    };

    this.updateFavorite = function (arg) {
      return FavoriteRepository.updateFavorite({
          user: arg.user_id,
          watcher_id: parseInt(_.get(arg, 'id'))
        }, {
          frequency: arg.frequency,
          notification_type: arg.notification_type,
          webhook: arg.webhook
        }).$promise;
    };

    this.updateGuestFavorite = function (guestCode, arg) {
        FavoriteRepository.updateGuestFavorite({
          org: _.get($rootScope.identity, 'guest.organization.id'),
          guest_code: guestCode,
          watch_id: parseInt(_.get(arg, 'id'))
        }, {
          frequency: arg.frequency,
          notification_type: arg.notification_type,
          webhook: arg.webhook
        });
    };
  }
})();
