/**
 * @ngdoc Component
 * @name tallyfy.steps.component.description
 * @module tallyfy.steps
 *
 * @description
 * A component to manage describe tab
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.steps')
    .component('stepDescribe', {
      bindings: {
        process: '<',
        step: '<',
        form: '=',
        isPublic: '<'
      },
      templateUrl: 'app/modules/steps/description/description.html',
      controller:
        /*@ngInject*/
        function (_, $rootScope, $scope, $filter, Helper, PLANS, AuthPlan, FroalaService, ProcessService, StepService, CompactStepService, $timeout, USER_STATE, ENV_CONFIG, openAIService) {
          var $ctrl = this,
            oldStep,
            updateFroalaViaCapturesEventHandler,
            toolbarButtons = CompactStepService.getBlueprintFroalaToolbar(),
            autoUpdateStepTimeout;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = onInit;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          $ctrl.generateStepDescriptionFromAi = generateStepDescriptionFromAi;

          /**
           * public methods
           */
          $ctrl.updateStep = updateStep;
          $ctrl.onDiscard = onDiscard;

          /**
           * public properties
           */
          $ctrl.insertVariablesForm = [];
          $ctrl.stepTypes = StepService.getStepTypes();

          /**
           * @function
           * @name onInit
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function onInit() {
            prepareInsertVariables();
            $ctrl.isRestricted = AuthPlan.isRestricted(PLANS.RESTRICATED_PLANS);
            angular.extend(toolbarButtons, {
              moreMisc: {
                buttons: [
                  ($ctrl.isRestricted) ? 'insertVariableDropdownStarter' : 'insertVariableDropdown'
                ],
                align: 'right',
                buttonsVisible: 2
              }
            });
            $ctrl.haveAuthority = Helper.checkAccessAuthority(false);
            ProcessService.getTags(_.get($ctrl.process, 'tags.data', []));
            $ctrl.tags = ProcessService.getTags(_.get($ctrl.process, 'tags.data', []));
            $ctrl.froalaOptions = Helper.getFroalaOptions({
              allowEdit: $ctrl.haveAuthority,
              placeholder: $filter('translate')('steps.describes.describe_placeholder'),
              currentUser: $rootScope.identity,
              enableFullscreen: true,
              toolbar: toolbarButtons,
              type: 'blueprint',
              entityType: 'template',
              qAutoSave: {
                callback: autoUpdateStep,
                debounce: 100
              }
            }, true, toolbarButtons);
            $ctrl.froalaOptions.autofocus = false;
            $ctrl.froalaOptions.entities = '';
            $ctrl.froalaOptions.variableValue = $ctrl.insertVariablesForm;
            $ctrl.froalaOptions.isFreePlan = AuthPlan.getCurrentPlanCode() === PLANS.FREE;
            $ctrl.froalaOptions.upgradeModal = upgradeModal;
            $ctrl.froalaOptions.enter = FroalaEditor.ENTER_BR;
            $ctrl.froalaOptions.transitionOnShow = true;
            $ctrl.froalaOptions.skipBlueprintId = $ctrl.process.id;
            $ctrl.froalaOptions.resizeable = true;
            oldStep = angular.copy($ctrl.step);
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() {
          }
          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            updateFroalaViaCapturesEventHandler();
            onKOFieldAddedHandler();
            if (autoUpdateStepTimeout) {
              $timeout.cancel(autoUpdateStepTimeout);
            }
          }

          /**
           * @ngdoc method
           * @name prepareInsertVariables
           * @description find step form fields and prerun form fields
           * @private
           */
          function prepareInsertVariables() {
            var preruns = $ctrl.process.prerun || [];
            if (!_.isEmpty(preruns)) {
              $ctrl.prerun = truncateFields(preruns);
              $ctrl.insertVariablesForm.push({
                name: $filter('translate')('steps.logic.label.pre_run'),
                type: 'Prerun',
                capture: $ctrl.prerun
              });
            }
            var steps = $ctrl.process.steps.data;
            _.map(steps, function (step, key) {
              if (step.captures.length > 0) {
                $ctrl.captures = truncateFields(step.captures);
                $ctrl.insertVariablesForm.push({
                  id: step.id,
                  name: $filter('translate')('steps.describes.insertVariable.step', { stepNumber: key + 1, stepName: truncateName(step.title, 30) }),
                  type: 'Step',
                  capture: $ctrl.captures
                });
              }
            });
            var systemCaptures = [
              { id: 'current-task-id', alias: 'current-task-id', full_label: $filter('translate')('steps.logic.label.taskID'), label: $filter('translate')('steps.logic.label.taskID'), type_field: '' },
              { id: 'current-process-id', alias: 'current-process-id', full_label: $filter('translate')('steps.logic.label.processID'), label: $filter('translate')('steps.logic.label.processID'), type_field: '' }
            ];
            $ctrl.insertVariablesForm.push({
              name: $filter('translate')('steps.logic.label.system'),
              type: 'System',
              capture: systemCaptures
            });
          }

          /**
           * @function
           * @name truncateFields
           * @description an helper function that truncates [capture|prerun]' label
           * @private
           * @param {*} fields
           *
           * @returns Array
           */
          function truncateFields(fields) {
            if (!_.isArray(fields) || _.isEmpty(fields)) {
              return [];
            }
            var copyFields = angular.copy(fields);
            copyFields = _.filter(copyFields, function (cpField) {
              return !cpField.isNew;
            });
            return _.map(copyFields, function (field) {
              return angular.extend(field, {
                full_label: field.label,
                label: truncateName(field.label, 25),
                type_field: prepareFieldsType(field.field_type) + " " + (field.alias || '').split('-').pop()
              });
            });
          }

          /**
           * @ngdoc method
           * @name upgradeModal
           * @description Show upgrade modal
           * @returns {Object}
           */
          function upgradeModal() {
            AuthPlan.hasAnyAuthority(PLANS.RESTRICATED_PLANS, 'insertVariable');
          }

          /**
           * @ngdoc method
           * @name truncateName
           * @description truncate name
           * @private
           * @param {String} name
           * @param {Integer} length
           * @returns {String}
           */
          function truncateName(name, length) {
            if (_.size(name) > length) {
              return name.substring(0, length) + '...';
            }
            return name;
          }

          /**
           * @ngdoc method
           * @name prepareFieldsType
           * @description set form fields type
           * @private
           * @param {String} fieldType
           * @returns {String}
           */
          function prepareFieldsType(fieldType) {
            return $filter('translate')('steps.describes.insertVariable.' + fieldType);
          }

          /**
           * event handler when any step capture is updated
           * @type {*|(function())}
           */
          updateFroalaViaCapturesEventHandler = $rootScope.$on('STEP:UPDATED', function (e, data) {
            var stepData = data.step || data;
            if (stepData.id === $ctrl.step.id) {
              reInitFroalaEditorVariables();
            }
          });

          var onKOFieldAddedHandler = $rootScope.$on('KO_FIELD:UPDATED', function (e, data) {
            reInitFroalaEditorVariables();
          });

          /**
           * @function
           * @name reInitFroalaEditorVariables
           * @description Resets Froala Editor with insert variables update
           */
          function reInitFroalaEditorVariables() {
            //Reset froala insert-variables
            $ctrl.insertVariablesForm = [];
            prepareInsertVariables();
            if ($ctrl.froalaOptions) {
              $ctrl.froalaOptions.variableValue = $ctrl.insertVariablesForm;
              var editor = _.get($ctrl.froalaOptions, 'froalaEditor', {});
              if (editor.insertVariable) {
                editor.insertVariable.updateVariableValues(editor, $ctrl.insertVariablesForm);
                $ctrl.froalaOptions.updateVariableValues($ctrl.insertVariablesForm);
              }
            }
          }

          function autoUpdateStep() {
            if ($ctrl.form && !$ctrl.form.$pristine) {
              StepService.isDescriptionSaveInQueue = true;
              if (autoUpdateStepTimeout) {
                $timeout.cancel(autoUpdateStepTimeout);
              }
              autoUpdateStepTimeout = $timeout(function () {
                updateStep(true);
                StepService.isDescriptionSaveInQueue = false;
              }, 500);
            }
          }

          /**
           * @ngdoc method
           * @name updateStep
           * @description Update the step
           */
          function updateStep(autoSaved) {
            $ctrl.onSaving = true;
            var _step = angular.copy($ctrl.step);
            _step.summary = FroalaService.getRawContent(_step.summary);
            StepService.isDescriptionSaveInProgress = true;
            StepService.updateStep({
              id: $ctrl.step.id,
              checklist_id: $ctrl.process.id,
              skipNotFound: true
            }, _step).then(function (response) {
              oldStep = angular.copy($ctrl.step);
              if ($ctrl.form && !autoSaved) {
                $ctrl.form.$setPristine();
              }
              $ctrl.step.last_updated = _.get(response, 'data.last_updated');
              StepService.isDescriptionSaveInProgress = false;
              $rootScope.$emit('STEP:UPDATED', response.data);
              $ctrl.onSaving = false;
              Helper.showChangesSavedGrowl();
            }, function () {
              $ctrl.onSaving = false;
            });
          }

          /**
           * @ngdoc method
           * @name onDiscard
           * @description Helper function to be used to discard changes in step.
           */
          function onDiscard() {
            angular.extend($ctrl.step, oldStep);
            $ctrl.form.$setPristine();
            StepService.isDescriptionSaveInQueue = false;
            StepService.isDescriptionSaveInProgress = false;
          }

          function generateStepDescriptionFromAi() {
            $ctrl.isSaving = true;
            $ctrl.froalaOptions.froalaEditor.edit.off();
            openAIService.generateTextCompletion({
              org_id: $rootScope.userState === USER_STATE.MEMBER ? $rootScope.identity.default_organization.id : $rootScope.identity.guest.organization.id,
              email: $rootScope.userState === USER_STATE.MEMBER ? $rootScope.identity.email : $rootScope.identity.guest.email,
              token: $rootScope.userState === USER_STATE.MEMBER ? void 0 : $rootScope.identity.guest.guest_code
            }, {
              text: ENV_CONFIG.BPE_DESCRIPTION_AI_PROMPT_TEXT.replace('{{PROCESS_NAME}}', $ctrl.process.title).replace('{{STEP_TITLE}}', $ctrl.step.title)
            }).then(function (res) {
              $ctrl.froalaOptions.froalaEditor.html.insert(_.get(res.data, 'content[0].text', '').replace(/\n/g, "<br />").trim());
              $scope.$evalAsync($ctrl.froalaOptions.ctrl.updateModelView);
              $ctrl.isSaving = false;
              $ctrl.froalaOptions.froalaEditor.edit.on();
            }, function () {
              $ctrl.isSaving = false;
              $ctrl.froalaOptions.froalaEditor.edit.on();
            });
          }

          //controller ends
        }
    });
})();
