/**
 * @ngdoc Component
 * @name tallyfy.account.component.changePassword
 * @module tallyfy.account
 *
 * @description
 * A component to manage change list page
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.account')
    .component('changePassword', {
      templateUrl: 'app/modules/account/changePassword/change-password.html',
      controller:
        /*@ngInject*/
        function ChangePasswordController(AccountService, Growl, $filter, blockUI, $rootScope, Helper) {
          var $ctrl = this,
            growl = new Growl(),
            blockUI = blockUI.instances.get('changePassword'),
            PASSWORD = {
              old_password: '',
              new_password: '',
              new_password_confirmation: ''
            };

          /**
           * @access public
           * public properties
           */
          $ctrl.form = {
            changePassword: {}
          };
          $ctrl.passwordModel = angular.copy(PASSWORD);

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.changePassword = changePassword;
          //$ctrl.deactivateAccount = deactivateAccount; // this can be un commented in future

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.haveAuthority = Helper.checkAccessAuthority(false);
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(bindings) { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @ngMethod
           * @name changePassword
           * @description Change user account password
           */
          function changePassword() {
            if (!Helper.checkAccessAuthority())
              return;
            if ($ctrl.form.changePassword.$invalid) {
              return;
            }
            blockUI.start();
            AccountService.updatePassword($ctrl.passwordModel.new_password, $ctrl.passwordModel.new_password_confirmation, $ctrl.passwordModel.old_password).then(function (account) {
              blockUI.stop();
              if (account.error) {
                growl.error($filter('translate')('password.messages.PASSWORD_CHANGED_FAILED'), { referenceId: 'growlNotification', disableIcons: true, disableCloseButton: true });
                return;
              }
              growl.success($filter('translate')('password.messages.PASSWORD_CHANGED_SUCCESS'), { referenceId: 'growlNotification', disableIcons: true, disableCloseButton: true });
              resetForm();
            }, function (error) {
              $ctrl.invalidPassword = true;
              blockUI.stop();
            });
          }

          /**
           * resetForm
           * helper function to reset form
           * @return void
           */
          function resetForm() {
            $ctrl.passwordModel = angular.copy(PASSWORD);
            $ctrl.form.changePassword.$setPristine();
            $ctrl.form.changePassword.$setUntouched();
          }
          /*
           <!--TODO It can be unhide in future version-->
          function deactivateAccount() {
            $confirm({
             'header': $filter('translate')('myAccount.confirmModal.header'),
             'body': '',
             'buttons': {
               'accept': $filter('translate')('myAccount.confirmModal.buttons.accept', { actionName: $filter('translate')('myAccount.form.buttons.removedUser') }),
               'cancel': $filter('translate')('myAccount.confirmModal.buttons.cancel')
             },
             modalType: 'modal-danger'
            }).then(function() {
             blockUI.start();
             UsersService.delete($rootScope.identity.id).then( function (response) {
               blockUI.stop();
               console.log('Your account removed successfully.')
             },
             function (error) {
               blockUI.stop();
             });
            });
          } */
        }
    });
})();