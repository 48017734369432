/**
 * @ngdoc Component
 * @name tallyfy.run.component.runDetail
 * @module tallyfy.run
 *
 * @description
 * A component to show the details of a run
 *
 * @author Kiran Kumar ( gmail::k.kiran305@gmail.com, skype :: kiran946kumar )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.run')
    .component('runDetail', {
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      require: {},
      templateUrl: 'app/modules/runs/detail/run-detail.html',
      controller:
        /*@ngInject*/
        function (_, blockUI, Helper, Growl, $rootScope, $scope, $confirm, $filter, $state, ProcessService, Principal, RunDetailService, RunRepository,
                  AuthPlan, RunsService, TasksService, moment, UsersService, $localStorage, TagsService, $q, $stateParams, PLANS, $timeout, DESCRIPTIONSIZE,
                  $window, $document, FieldService, $log, FolderService, store, OrganizationsService, $uibModal) {
          var $ctrl = this,
            growl = new Growl(),
            blockUI = blockUI.instances.get('runDetail'),
            tempRun = {},
            currentUser = $rootScope.identity,
            unregisterUpdateRunListener,
            unregisterRunDetailListener,
            unregisterCloseRunDetailListener,
            runDetailSaved = false,
            unregisterLaunchRunTimeout,
            runChangesHandler,
            tasksChangesHandler,
            runTagChangesHandler,
            processChangesHandler,
            modalInstance,
            ownerTasksReassign = false;

          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * Expose bindable methods
           * these methods are accessible in view
           */
          $ctrl.updatePrerunField = updatePrerunField;
          $ctrl.saveProcessDetail = saveProcessDetail;
          $ctrl.discardChanges = discardChanges;
          $ctrl.closeModal = closeModal;
          $ctrl.cancelStartProcess = cancelStartProcess;
          $ctrl.archiveRun = archiveRun;
          $ctrl.saveRun = saveRun;
          $ctrl.defaultAvatar = defaultAvatar;
          $ctrl.defaultAvatarText = defaultAvatarText;
          $ctrl.loadTags = loadTags;
          $ctrl.onRemovingUser = onRemovingUser;
          $ctrl.onUserSelect = onUserSelect;
          $ctrl.onUserAdded = onUserAdded;
          $ctrl.hasAtLeastOneField = hasAtLeastOneField;
          $ctrl.unarchiveRun = unarchiveRun;
          $ctrl.openViewMoreModel = openViewMoreModel;
          $ctrl.getSaveText = getSaveText;
          $ctrl.getDueDeadline = getDueDeadline;
          $ctrl.exportCSV = exportCSV;
          $ctrl.upgradeModal = upgradeModal;
          $ctrl.setTitleEditable = setTitleEditable;
          $ctrl.onChangeProcessOwner = onChangeProcessOwner;
          $ctrl.isActiveContent = isActiveContent;
          $ctrl.changeTab = changeTab;
          $ctrl.confirmPermanentDeleteRun = confirmPermanentDeleteRun;
          $ctrl.addProcessToFolders = addProcessToFolders;
          $ctrl.onFolderRemoved = onFolderRemoved;
          $ctrl.openBuilder = openBuilder;

          /**
           * public properties
           */
          $ctrl.processCoworkers = [];
          $ctrl.processGuests = [];
          $ctrl.processOwnersList = [];
          $ctrl.runDetail = {};
          $ctrl.froalaTextShortenConfig = {};
          $ctrl.showFroalaEditor = true;
          $ctrl.isSaved = false;
          $ctrl.ownerChangeBP = false;
          $ctrl.koFormUpdated = false;
          $ctrl.absoluteDateFormat = OrganizationsService.getDateFormat();

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.processText = $filter('translate')('process.process');
            $ctrl.haveAuthority = Helper.checkAccessAuthority(false);
            watchRunData();
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            $ctrl.isLightMember = _.isEqual(_.get($rootScope, 'identity.role'), "light");
            $ctrl.screenWidth = angular.element($window).width();
            $ctrl.maxTitleLength = DESCRIPTIONSIZE.maxTitle;
            $ctrl.runDetail.run = $ctrl.resolve.run;
            var tags;
            if (_.get($ctrl.runDetail, 'run.tags.data')) {
              tags = _.filter($ctrl.runDetail.run.tags.data, function (tag) {
                return !(_.startsWith(tag.title, 'task:') || _.startsWith(tag.title, 'from:') || _.startsWith(tag.title, 'step:'));
              });
              angular.copy(tags, $ctrl.runDetail.run.tags.data);
            }
            $ctrl.resolve.users = _.sortBy($ctrl.resolve.users, 'text');
            if ($ctrl.resolve.run.started_by) {
              $ctrl.processStarter = _.find($ctrl.resolve.users, {
                id: $ctrl.resolve.run.started_by
              });
            } else {
              $ctrl.processStarter = _.assign($rootScope.identity, {
                text: $rootScope.identity.full_name
              });
            }

            if (!$ctrl.resolve.run.owner_id) {
              $ctrl.resolve.run.owner_id = _.head(_.get($ctrl.resolve, 'run.user'));
            }
            $ctrl.processType = $ctrl.resolve.processView !== 'execution' ? 'EditProcess' : 'ActiveProcess';
            $ctrl.processOwnersList = setProcessOwnersList();
            $ctrl.organization = $rootScope.identity.default_organization;
            $ctrl.org_access_token = Principal.getOrganizationToken();
            $ctrl.froalaTextShortenConfig = ProcessService.froalaTextShortenConfig($ctrl.maxTitleLength);
            unregisterRunDetailListener = $rootScope.$on('RUN:UPDATE_RUN_DETAIL', function (event, task) {
              var tasks = _.get($ctrl.resolve, 'tasks.data', []);
              var taskIndex = _.findIndex(tasks, {
                id: task.id
              });
              var tempStep = tasks[taskIndex].step;
              tasks[taskIndex] = task;
              tasks[taskIndex].step = tempStep;
            });
            isTemplateTitle();
            $ctrl.isProPlan = [PLANS.PRO, PLANS.PROMONTHLY, PLANS.PROANNUAL].indexOf(AuthPlan.getCurrentPlanCode()) > -1;
            prepareSummaryAndGuidance();
            backupRun();
            $ctrl.resolve.processView !== 'execution' ? $ctrl.prerunValues = $ctrl.resolve.prerunValues : angular.noop();
            var owner = _.get($ctrl.runDetail, 'run.users') && $ctrl.runDetail.run.users.length ? $ctrl.runDetail.run.users : $ctrl.runDetail.run.owner_id,
             havePermissionTab = ($ctrl.isAdminMember || _.includes(owner, currentUser.id) || _.isEqual($ctrl.processStarter.id, currentUser.id)) ? true : false;
            $ctrl.tabsOptions = ProcessService.getTabOptions(havePermissionTab, $ctrl.resolve.processView === 'execution', false);
            $ctrl.drawerTab = $ctrl.resolve.activeSettingsTab ?
              _.find($ctrl.tabsOptions, { value: $ctrl.resolve.activeSettingsTab.value }) :
              _.first(_.filter($ctrl.tabsOptions, { isEnable: true }));
            $ctrl.drawerTab.class = 'active';
            setUpFroalaEditors();
            $ctrl.ownerChangeBP = $ctrl.isAdminMember || (_.get(_.head($ctrl.processOwnersList), 'id') === _.get($rootScope.identity, 'id'));
            $ctrl.openPowerBuilder = $ctrl.resolve.openPowerBuilder;
            $ctrl.toggleHiddenTasksView = $ctrl.resolve.toggleHiddenTasksView;
            $ctrl.run = $ctrl.resolve.run;
            if (_.get($ctrl.run, 'is_public') === "1") {
              $ctrl.isPublic = true;
            } else {
              $ctrl.isShowPermission = true;
            }
            $ctrl.activeTab = $ctrl.resolve.activeTab;
            $ctrl.togglePrintOptionViews = $ctrl.resolve.togglePrintOptionViews;
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            unregisterUpdateRunListener();
            unregisterRunDetailListener();
            unregisterCloseRunDetailListener();
            runChangesHandler();
            if (typeof tasksChangesHandler === 'function') {
              tasksChangesHandler();
            }
            if (typeof runTagChangesHandler === 'function') {
              runTagChangesHandler();
            }
            if (typeof processChangesHandler === 'function') {
              processChangesHandler();
            }
            if (unregisterLaunchRunTimeout) {
              $timeout.cancel(unregisterLaunchRunTimeout);
            }
          }

          /**
           * @ngdoc method
           * @name setUpFroalaEditors
           * @description setup froala editors options
           */
          function setUpFroalaEditors() {
            var toolbarsButtons = [];
            $ctrl.froalaOptions = Helper.getFroalaOptions({
              placeholder: $filter('translate')('runs.placeholder.process_notes'),
              currentUser: currentUser,
              enableFullscreen: true
            }, false, {
              moreText: {
                buttons: _.concat(
                  toolbarsButtons, [
                  "bold",
                  "italic",
                  "underline",
                  "align",
                  "formatOL",
                  "formatUL",
                  "fontSize",
                  "paragraphFormat",
                  "quote",
                  "textColor",
                  "codeTag",
                  "fullscreen",
                  "emoticons",
                  "insertImage",
                  "insertFile",
                  "insertVideo",
                  "insertLink",
                  "insertTable",
                  "embedly",
                  "pageBreak"
                ]),
                align: 'left',
                buttonsVisible: 12 + toolbarsButtons.length
              }
            });
            $ctrl.froalaOptions.autofocus = false;
            $ctrl.froalaOptions.isFreePlan = AuthPlan.getCurrentPlanCode() === PLANS.FREE;
            $ctrl.froalaOptions.upgradeModal = $ctrl.upgradeModal;
            $ctrl.froalaOptions.enter = FroalaEditor.ENTER_BR;
            $ctrl.froalaOptions.resizeable = true;
            $ctrl.froalaOptions.allowEdit = $ctrl.haveAuthority ? $ctrl.runDetail.run.status !== 'archived' : false;
          }

          /**
           * @ngdoc method
           * @name getProcessCoworkers
           * @public
           *
           * @description returns cowerkers assign to a process
           * returns a list of users with all properties of user
           * @returns {array} A list of users
           */
          function getProcessCoworkers() {
            var tasks = getRunTasks();
            $ctrl.processCoworkers = ProcessService.getProcessCoworkers(tasks, $ctrl.resolve.users);
            if ($ctrl.processCoworkers.length > 1) {
              $ctrl.processCoworkers = UsersService.moveCurrentUserIndex($ctrl.processCoworkers);
            } else {
              $ctrl.processCoworkers.length === 0 ? $ctrl.processCoworkers.push(_.head($ctrl.processOwnersList)) : angular.noop();
            }
          }

          /**
           * @ngdoc method
           * @name getProcessGuests
           * @description returns list of guests
           * @returns {array} A list of guests
           */
          function getProcessGuests() {
            var tasks = getRunTasks();
            $ctrl.processGuests = ProcessService.getProcessGuest(tasks);
          }

          /**
           * @ngdoc method
           * @name getRunTasks
           * @description returns list of tasks from the run
           * @returns {array} A list of tasks
           */

          function getRunTasks() {
            return ($ctrl.resolve.processView && $ctrl.resolve.processView === 'execution') ? $ctrl.resolve.tasks : $ctrl.resolve.tasks;
          }

          /**
           * @ngdoc method
           * @name watchRunData
           * @private
           *
           * @description watch for changes made in resolved data the update the run details
           *
           * @returns void
           */
          function watchRunData() {
            runChangesHandler = $scope.$watch('$ctrl.resolve.run', function () {
              if ($ctrl.resolve.processView === 'execution') {
                $ctrl.isAttach = true;
                $ctrl.taskCompleted = _.get($ctrl.resolve, 'run.progress.complete', 0);
                $ctrl.processDeadline = ProcessService.getProcessDeadline(_.get($ctrl.tasks, 'data', []), 'deadline');
                $ctrl.dealineFormatted = moment($ctrl.processDeadline).format('MMM D, YYYY  h:mm a');
              }
              setEmptySummaryOfRun();
            }, true);

            if ($ctrl.resolve.process) {
              processChangesHandler = $scope.$watch('$ctrl.resolve.process', function () {
                $ctrl.runDetail.process = $ctrl.resolve.process;
                $ctrl.processDeadline = ProcessService.getProcessDeadline($ctrl.runDetail.process.steps.data, 'deadline_time');
                $ctrl.dealineFormatted = moment($ctrl.processDeadline).format('MMM D, YYYY  h:mm a');
              }, true);
            }

            if ($ctrl.resolve.tasks) {
              tasksChangesHandler = $scope.$watch('$ctrl.resolve.tasks', function () {
                getProcessCoworkers();
                getProcessGuests();
              }, true);
            }

            if ($ctrl.resolve.processView === 'execution') {
              runTagChangesHandler = $scope.$watch('$ctrl.runDetail.run.tags.data', function () {
                $ctrl.resolve.run.tags.data = $ctrl.runDetail.run.tags.data;
              }, true);
            }
          }

          /**
           * @ngdoc method
           * @name updatePrerunField
           * @public
           *
           * @description updates value in prerun field
           * this function is called everytime when user enter value in prerun field
           * @param {object} field
           * @returns void
           */
          function updatePrerunField(field) {
            if (_.isArray($ctrl.runDetail.run.prerun)) {
              $ctrl.runDetail.run.prerun = {};
            } else if (!_.isObject($ctrl.runDetail.run.prerun)) {
              $ctrl.runDetail.run.prerun = {};
            }
            $ctrl.runDetail.run.prerun[field.id] = FieldService.getFieldValue(field);
            field.field_type === 'file' ? $ctrl.saveRun(false) : $ctrl.koFormUpdated = true;
          }

          /**
           * @ngdoc method
           * @name saveProcessDetail
           * @public
           *
           * @description save the process details
           *
           * @returns void
           */

          function saveProcessDetail() {
            resetForm(true);
            unregisterLaunchRunTimeout = $timeout(function () {
              showSuccessContextAlert();
            }, 100);
          }

          /**
           * @ngdoc method
           * @name discardChanges
           * @public
           *
           * @description discard the changes made
           * @param {*} close
           * @returns void
           */
          function discardChanges(close) {
            resetForm();
            isTemplateTitle();
            if (close) closeDrawer();
          }

          /**
           * @ngdoc method
           * @name closeModal
           * @public
           *
           * @description close more detail madal window
           * @param {event} $event
           * @returns void
           */
          function closeModal($event) {
            Growl.clearAllMessages('blueprintContextAlert');
            if ($ctrl.form.processPermissionForm.$dirty) {
              if ($ctrl.form.processPermissionForm.processAssignee.$dirty) {
                if ($event) {
                  $event.stopPropagation();
                }
                growl.error($filter('translate')('permissions.errorMessage.unsaveChanges'), {
                  referenceId: 'blueprintPermissionAlert',
                  disableIcons: true,
                  disableCloseButton: true
                });
                return;
              } else {
                discardChanges(true);
              }
            }
            ($ctrl.form.processDetail.$pristine && !$ctrl.koFormUpdated) ? closeDrawer() : showConfirmForm();
          }

          /**
           * @ngdoc method
           * @name closeDrawer
           * @returns void
           *
           * @description
           * to close the drawer
           */
          function closeDrawer() {
            RunDetailService.closeDrawer();
            $ctrl.close({
              $value: {
                run: $ctrl.runDetail.run
              }
            });
          }

          /**
           * @ngdoc method
           * @name cancelStartProcess
           * @public
           *
           * @description cancel start process model
           *
           * @returns void
           */
          function cancelStartProcess() {
            $confirm({
              'header': 'runs.confirmModal.header',
              'body': '',
              'buttons': {
                'accept': 'runs.confirmModal.buttons.accept',
                'cancel': 'runs.confirmModal.buttons.cancel'
              },
              modalType: 'modal-danger'
            }).then(function () {
              $ctrl.closeModal();
              redirectToPreviousPage();
            });
          }

          /**
           * @ngdoc method
           * @name redirectToPreviousPage
           * @description Redirect to previous page
           */
          function redirectToPreviousPage() {
            var previousState = $localStorage.previous_state_name;
            delete $localStorage.previous_state_name;
            if (_.toLower(previousState) === 'process.edit') {
              $state.go(previousState, {
                slug: _.get($ctrl.resolve.process, 'id')
              });
              return;
            }
            $state.go(previousState);
          }

          /**
           * @ngdoc method
           * @name archiveRun
           * @public
           *
           * @description archive Run
           * @param {*} run
           * @returns void
           */
          function archiveRun(run) {
            if (!Helper.checkAccessAuthority()) {
              return;
            }
            blockUI.start();
            $ctrl.onSaving = true;
            RunRepository.deleteRunsData({
              id: run.id
            }).then(function (response) {
              angular.extend($ctrl.runDetail.run, response.data);
              blockUI.stop();
              $rootScope.$emit('RUN_DETAILS:UPDATED', angular.copy($ctrl.runDetail.run));
              growl.success($filter('translate')('runs.dashboard.runAction.archived'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true,
                variables: {
                  method: unarchiveRun,
                  param: run,
                  linkText: $filter('translate')('runs.dashboard.undo')
                }
              });
              $ctrl.onSaving = false;
              $ctrl.isSaved = true;
              closeDrawer();
            }, function () {
              $ctrl.onSaving = false;
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name saveRun
           * @description update the run details
           * @param {*} close
           */
          function saveRun(close) {
            var users = [], groups = [];
            _.forEach($ctrl.processOwnersList, function(user) {
              if (_.get(user, 'name')) {
                groups.push(user.id);
              } else {
                users.push(user.id);
              }
            });
            $ctrl.onSaving = true;
            blockUI.start();
            RunsService.update({
              id: $ctrl.runDetail.run.id
            }, {
              name: ProcessService.capitalizeFirstLetterOfEachWord($ctrl.runDetail.run.name),
              summary: $ctrl.runDetail.run.summary,
              prerun: $ctrl.runDetail.run.prerun,
              groups: groups,
              users: users,
              owner_tasks_reassign: ownerTasksReassign
            }).then(function (response) {
              blockUI.stop();
              $ctrl.onSaving = false;
              showSuccessContextAlert();
              angular.extend($ctrl.resolve.run, response.data);
              if (ownerTasksReassign) {
                $ctrl.resolve.run.ownerTasksReassign = ownerTasksReassign;
              }
              $ctrl.isSaved = true;
              $rootScope.$emit('RUN_DETAILS:UPDATED', angular.copy($ctrl.resolve.run));
              resetForm(true);
              if (close) closeDrawer();
            }, function () {
              $ctrl.onSaving = false;
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name resetForm
           * @param {*} onSaved
           * @returns {void}
           *
           * @description
           * reset form
           */
          function resetForm(onSaved) {
            isTemplateTitle();
            if (!onSaved) {
              if ($stateParams.id) resetTagsValue();
              angular.extend($ctrl.resolve.run, tempRun);
              runDetailSaved = false;
            } else {
              $ctrl.runDetail.run = $ctrl.resolve.run;
              runDetailSaved = true;
            }
            backupRun();
            setProcessOwner();
            $ctrl.form.processDetail.$setPristine(false);
            $ctrl.koFormUpdated = false;
          }

          /**
           * @ngdoc method
           * @name isTemplateTitle
           * @description Assign value based on process title
           * @returns {Boolean} true/false
           */
          function isTemplateTitle() {
            $ctrl.editTitle = !$ctrl.runDetail.run.name;
          }

          /**
           * @ngdoc method
           * @name showConfirmForm
           * @returns void
           *
           * @description
           * show unsaved confirmation modal
           */
          function showConfirmForm() {
            $confirm({
              'body': 'global.unsaved.body',
              'header': 'global.unsaved.header',
              'buttons': {
                'accept': 'global.unsaved.buttons.accept',
                'cancel': 'global.unsaved.buttons.cancel'
              },
              modalType: 'modal-success'
            }).then(function () {
              if ($stateParams.id) {
                saveRun(true);
              } else {
                $ctrl.form.processDetail.$setPristine();
                $ctrl.koFormUpdated = false;
                closeDrawer();
              }
            }, function () {
              discardChanges(true);
            });
          }

          /**
           * @ngdoc method
           * @name defaultAvatar
           * @public
           * @description set default avatar
           * @param {string} avatar
           * @return {boolean}
           */
          function defaultAvatar(avatar) {
            return TasksService.setDefaultAvatar(avatar);
          }

          /**
           * @ngdoc method
           * @name defaultAvatarText
           * @public
           * @description set default avatar Text
           * @param {string} firstname
           * @param {string} lastname
           * @return {string} first character of name
           */
          function defaultAvatarText(firstname, lastname) {
            return TasksService.setDefaultAvatarText(firstname, lastname);
          }

          /**
           * loadTags
           * filters tags based on search text
           * @param  {string} $query A search string
           * @return {array} An array of filtered tags
           */
          function loadTags($query) {
            return _.filter(_.concat($ctrl.resolve.users, $ctrl.resolve.orgGroups), function (tag) {
              return tag.text.toLowerCase().indexOf($query.toLowerCase()) !== -1;
            });
          }

          /**
           * @function
           * @name isUserExist
           * @param user
           * @description Check a user already exist
           */
          function isUserExist(user) {
            return _.some(_.concat($ctrl.resolve.users, $ctrl.resolve.orgGroups), function (owner) {
              return (owner.email === user.text || user.email) || owner.text === user.text;
            });
          }

          /**
           * @function
           * @name onUserSelect
           * @param user
           * @description On select user in the tags input
           */
          function onUserSelect(user) {
            return checkUserStatus(user);
          }

          /**
           * @function
           * @name checkUserStatus
           * @param user
           * @description Return true/false based on the conditions
           */
          function checkUserStatus(user) {
            return isUserExist(user);
          }

          /**
           * @function
           * @name onRemovingUser
           * @param {Object} $owner
           * @description check before removing user. if only one coworker then it won't delete the user
           */
          function onRemovingUser($owner) {
            return checkUserStatus($owner) && $ctrl.processOwnersList.length > 1;
          }

          /**
           * @function
           * @name onUserAdded
           * @param $owner
           * @description Callback function onTagAdded in ngTagsInput
           */
          function onUserAdded($owner) {
            if (_.get($ctrl.resolve, 'processView') === 'execution') {
              return;
            }
            onOwnerChanged($owner);
          }

          /**
           * @function
           * @name onOwnerChanged
           * @param ownerDetails
           * @description Callback function onTagAdded in ngTagsInput
           */
          function onOwnerChanged(ownerDetails) {
            setProcessOwner();
          }

          /**
           * @function
           * @name setProcessOwner
           * @description Set process starter details
           */
          function setProcessOwner() {
            $ctrl.processOwnersList.length = 0;
            $ctrl.processOwnersList = setProcessOwnersList();
            $ctrl.changeProcessOwner = false;
          }

          /**
           * @ngdoc method
           * @name hasAtLeastOneField
           * @description return the boolean about child fields
           */

          function hasAtLeastOneField() {
            if ($ctrl.resolve.processView === 'execution') {
              var filtered_tasks = _.get($ctrl.resolve, 'tasks.data', []).filter(function (task) {
                return (_.get(task.step, 'data.captures', [])).length > 0;
              });
              return filtered_tasks.length;
            } else {
              var filtered_steps = $ctrl.runDetail.process.steps.data.filter(function (step) {
                return step.captures.length > 0;
              });
              return filtered_steps.length;
            }
          }

          /**
           * @name resetTagsValue
           * @returns void
           *
           * @description
           * revert tags value from API
           */
          function resetTagsValue() {
            var tags = $ctrl.runDetail.run.tags;
            var originTags = tempRun.tags.data;
            if (_.isEqual(tags, originTags)) {
              return;
            }
            var tagPromises = [];

            /**
             * get value should be attachs
             */
            var attachTags = _.filter(originTags, function (tag) {
              return !_.find(tags, {
                id: tag.id
              });
            });

            if (attachTags && attachTags.length > 0) {
              _.forEach(attachTags, function (tag) {
                var attachTag = {
                  id: tag.tag_id,
                  title: tag.title
                };
                tagPromises.push(TagsService.attachTag(attachTag, {
                  type: 'Run',
                  id: $ctrl.runDetail.run.id
                }));
              });
            }

            /**
             * get value should be detach/removes
             */
            var detachTags = _.filter(tags, function (tag) {
              return !_.find(originTags, {
                id: tag.id
              });
            });

            if (detachTags && detachTags.length > 0) {
              _.forEach(detachTags, function (tag) {
                var tagId = _.isNumber(tag.id) ? tag.id : tag.tag_id;
                if (tagId) {
                  tagPromises.push(TagsService.detachTag(tagId, {
                    type: 'Run',
                    id: $ctrl.runDetail.run.id
                  }));
                }
              });
            }

            $q.all([tagPromises])
              .then(function () {
                angular.noop();
              }, function () {
                angular.noop();
              });
          }

          /**
           * @function
           * @name unarchiveRun
           * @description unarchived run
           * @param run
           */
          function unarchiveRun(run) {
            if (!run) {
              return;
            }
            if (!Helper.checkAccessAuthority()) {
              return;
            }
            $ctrl.onSaving = true;
            blockUI.start();
            RunRepository.activeRunsData({
              id: run.id
            }).then(function (response) {
              angular.extend($ctrl.runDetail.run, response.data);
              blockUI.stop();
              $rootScope.$emit('RUN_DETAILS:UPDATED', angular.copy($ctrl.runDetail.run));
              growl.success($filter('translate')('runs.dashboard.process.status'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
              $ctrl.onSaving = false;
              $ctrl.isSaved = true;
              closeDrawer();
            }, function (error) {
              $ctrl.onSaving = false;
              growl.error(error.message, {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name openViewMoreModel
           * @description open view more modal for viewmore details of summary
           * @param {String} titleTranslationStr
           * @param {String} summary
           */
          function openViewMoreModel(titleTranslationStr, summary) {
            ProcessService.openViewMoreModal({ title: $filter('translate')(titleTranslationStr), summary: summary });
          }

          /**
           * @name getSaveText
           * @description return text based on condition
           * @returns button text
           */
          function getSaveText() {
            if (($ctrl.form.processDetail.$dirty && $ctrl.koFormUpdated) || !runDetailSaved) {
              return $filter('translate')('steps.buttons.save_step');
            }
            return $filter('translate')('steps.buttons.saved');
          }

          /**
           * @name getDueDeadline
           * @description return process deadline
           * @param {*} deadline
           * @returns process deadline
           */
          function getDueDeadline(deadline) {
            return _.replace(deadline, 'in', '');
          }

          /**
           * event handler when run status is change
           * @type {*|(function())}
           */
          unregisterUpdateRunListener = $rootScope.$on('RUN:UPDATED', function (event, run) {
            angular.extend($ctrl.runDetail.run, run);
            prepareSummaryAndGuidance();
          });

          /**
           * event handler when run details sidebar needs to close
           * @type {*|(function())}
           */
          unregisterCloseRunDetailListener = $rootScope.$on('RUN_DETAIL:CLOSE', function (event) {
            closeModal(event);
          });

          /**
           * @ngdoc method
           * @name showSuccessContextAlert
           * @returns void
           *
           * @description
           * show success growl context messages
           */
          function showSuccessContextAlert() {
            growl.success($filter('translate')('runs.messages.runDetailUpdated'), {
              referenceId: 'runDetailsDrawer',
              disableIcons: true,
              disableCloseButton: true
            });
          }

          /**
           * @function
           * @name setEmptySummaryOfRun
           * @description Handle empty summary case
           */
          function setEmptySummaryOfRun() {
            $ctrl.resolve.run.summary = RunsService.setEmptySummaryOfRun($ctrl.resolve.run);
          }

          /**
           * @public
           * @ngdoc method
           *
           * @name exportCSV
           * @param run
           *
           * @description
           * Export csv
           */
          function exportCSV(run) {
            if (!Helper.checkAccessAuthority()) {
              return;
            }
            blockUI.start();
            RunRepository.exportCSV({
              id: run.id
            }).then(function (response) {
              var disposition = response.headers['content-disposition'];
              var matches = /"([^"]*)"/.exec(disposition);
              var filename = (matches !== null && matches[1] ? matches[1] : run.id + '.csv');
              var csvBlob = new Blob([response.data], { type: 'application/csv' });
              var tempLink = $document[0].createElement('a');
              tempLink.href = $window.URL.createObjectURL(csvBlob);
              tempLink.download = filename;
              $document[0].body.appendChild(tempLink);
              tempLink.click();
              $document[0].body.removeChild(tempLink);
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name upgradeModal
           *
           * @description
           * show upgrade modal
           * @returns {void}
           */
          function upgradeModal() {
            if (!Helper.checkAccessAuthority()) {
              return;
            }
            AuthPlan.setModalContent(AuthPlan.getCurrentPlanCode(), 'exportCSV');
          }

          /**
           * @ngdoc method
           * @name prepareSummaryAndGuidance
           * @description
           * prepare summary and guidance details
           */
          function prepareSummaryAndGuidance() {
            $ctrl.descriptions = _.get($ctrl.resolve, 'process.summary') || _.get($ctrl.runDetail, 'run.checklist.data.summary');
            $ctrl.processSummary = _.get($ctrl.resolve, 'process.guidance') || _.get($ctrl.runDetail, 'run.checklist.data.guidance');
          }

          /**
           * @ngdoc method
           * @name backupRun
           *
           * @description
           * backup run object async in current scope
           */
          function backupRun() {
            $scope.$applyAsync(function () {
              tempRun = angular.copy($ctrl.resolve.run);
            });
          }

          /**
           * @ngdoc method
           * @name setTitleEditable
           * @description
           * set title editable when click
           */
          function setTitleEditable() {
            if (!Helper.checkAccessAuthority()) {
              return;
            }
            modalInstance = $uibModal.open({
              component: 'processRenameModal',
              windowClass: 'process-edit-modal',
              backdrop: 'static',
              resolve: {
                titleName: function () {
                  return $ctrl.runDetail.run.name;
                },
                activeProcess: function () {
                  return 'activeProcess';
                }
              }
            });
            modalInstance.result.then(function (res) {
              $ctrl.runDetail.run.name = res;
              saveRun(true);
            }, function () {
              $log.info('modal is cancelled');
            });
          }

          function onChangeProcessOwner() {
            if (!Helper.checkAccessAuthority()) {
              return;
            }
            $ctrl.changeProcessOwner = true;
          }

          /**
           * @function
           * @name isActiveContent
           * @description Set active content as selected tab.
           * @param {String} tabValue
           * @returns {Boolean}
           */
          function isActiveContent(tabValue) {
            return (tabValue === _.get($ctrl.drawerTab, 'value'));
          }

          /**
           * @function
           * @name changeTab
           * @description Execute when change tab in Mobile view to check if it has something need to be saved.
           * @param {integer} selectedIndex
           * @param {object} tab
           * @param {event} $event
           */
          function changeTab(selectedIndex, tab, $event) {
            if (selectedIndex !== 3 && $ctrl.form.processPermissionForm.$dirty) {
              $event.stopPropagation();
              growl.error($filter('translate')('permissions.errorMessage.unsaveChanges'), {
                referenceId: 'blueprintPermissionAlert',
                disableIcons: true,
                disableCloseButton: true
              });
              return;
            }
            if ($ctrl.drawerTab.value === $ctrl.tabsOptions[selectedIndex].value) {
              return;
            }
            tab.class = '';
            $ctrl.drawerTab = $ctrl.tabsOptions[selectedIndex];
            $ctrl.drawerTab.class = 'active';
          }

          /**
           * @ngdoc method
           * @name permanentlyDeleteRun
           * @description
           * Permanently delete Run
           * @param {*} run
           */
          function permanentlyDeleteRun(run) {
            blockUI.start();
            RunRepository.permanentlyDeleteRun({
              id: run.id
            }).then(function () {
              blockUI.stop();
              growl.success($filter('translate')('template.permanentlyDeleteComponent.successMessage', {
                componentName: $filter('translate')('process.process')
              }), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
              $timeout(function () {
                Growl.clearAllMessages('global');
              }, 5000);
              $state.go('run.dashboard', { org_id: $ctrl.organization.id });
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name confirmPermanentDeleteRun
           * @description
           * open Run deletion confirmation modal
           * @param {*} run
           */
          function confirmPermanentDeleteRun(run) {
            if (!Helper.checkAccessAuthority()) {
              return;
            }
            Growl.clearAllMessages('global');
            RunsService.openConfirmRunDeletion(run).then(function () {
              permanentlyDeleteRun(run);
            }, function () {
              $log.info('Delete Run is cancelled');
            });
          }

          function addProcessToFolders() {
            var openProcessFoldersModal = FolderService.openObjectFoldersModal($ctrl.resolve.foldersList, $ctrl.runDetail.run.id, 'Run', $ctrl.runDetail.run.folders.data, $ctrl.runDetail.run.name);
            openProcessFoldersModal.result.then(function (data) {
              foldersDataUpdateSuccess(data);
            }, function (data) {
              foldersDataUpdateSuccess(data);
            });
          }

          function foldersDataUpdateSuccess(data) {
            $ctrl.runDetail.run.folders.data = data;
            $rootScope.$emit('RUN_DETAILS:UPDATED', angular.copy($ctrl.runDetail.run));
            tempRun = angular.copy($ctrl.runDetail.run);
            $ctrl.form.processDetail.$setPristine();
          }

          /**
           * @function
           * @name onFolderRemoved
           * @param {*} $folder
           * @description Callback function onFolderRemoved
           */
          function onFolderRemoved($folder) {
            blockUI.start();
            FolderService.deleteObjectFromFolder({ id: $folder.id })
              .then(function () {
                var index = _.findIndex($ctrl.runDetail.run.folders.data, { 'folder_id': $folder.folder_id });
                if (index > -1) {
                  $ctrl.runDetail.run.folders.data.splice(index, 1);
                }
                foldersDataUpdateSuccess(angular.copy($ctrl.runDetail.run.folders.data));
                growl.success($filter('translate')('runs.messages.foldersForRunsAndTasks.removedFromFolder', { objectName: $ctrl.runDetail.run.name, folderName: $folder.folder_name }),
                  { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                blockUI.stop();
              }, function () {
                $ctrl.runDetail.run.folders.data = tempRun.folders.data;
                $ctrl.form.processDetail.$setPristine();
                blockUI.stop();
              });
          }

          function openBuilder() {
            $ctrl.openPowerBuilder();
          }

          function setProcessOwnersList() {
            var processOwner = [];
            if ($ctrl.runDetail.run.users && $ctrl.runDetail.run.users.length > 0) {
              _.forEach($ctrl.runDetail.run.users, function(user) {
                processOwner.push(_.find($ctrl.resolve.users, function(u) {
                  return u.id === user;
                }));
              });
            }
            if ($ctrl.runDetail.run.groups && $ctrl.runDetail.run.groups.length > 0) {
              _.forEach($ctrl.runDetail.run.groups, function(user) {
                processOwner.push(_.find($ctrl.resolve.orgGroups, function(u) {
                  return u.id === user;
                }));
              });
            }
            if (processOwner.length === 0 && $ctrl.runDetail.run.owner_id) {
              processOwner.push(_.find($ctrl.resolve.users, function(u) {
                return u.id === $ctrl.runDetail.run.owner_id;
              }));
            }
            return processOwner;
          }
        }
    });
})();
