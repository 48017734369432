(function () {
  'use strict';
  angular.module('tallyfy')
    .component('tallyfyGridTable', {
      bindings: {
        delayInit: '<?',
        initConfig: '&?',
        options: '<?',
        pagination: '<',
        perPageData: '<',
        qTableFetchCallback: '&?',
        qTableAfterFetchCallback: '&?',
        preInitData: '<',
        source: '<',
        afterValidatedCallback: '&',
        isBusy: '=?',
        exportDisplayedDataOnly: '<?',
        autoSize: '<'
      },
      transclude: {
        topContent: '?tableTopContent'
      },
      templateUrl: 'app/components/kendo/tallyfy-grid-table/tallyfy-grid-table.component.html',
      controller:
        /*@ngInject*/
        function (_, $rootScope, $scope, $timeout, $interval, KendoUIService, Helper, CONST) {
          var $ctrl = this,
            scrollContainer,
            scrollNavWatcher,
            tableTimeoutHandler,
            interval = -1,
            events = [],
            body = angular.element('body');

          $ctrl.headerBoundingRect = {};
          $ctrl.tableActiveElements = {};
          $ctrl.rendered = false;
          $ctrl.allowedImportFile = CONST.TABLE_ALLOWED_IMPORT.join(',');

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.exportToExcel = exportToExcel;
          $ctrl.exportToPDF = exportToPDF;
          $ctrl.exportToCsv = exportToCsv;
          $ctrl.goToNextPage = goToNextPage;
          $ctrl.defaultMenuClick = defaultMenuClick;
          $ctrl.additionalMenuMenuClick = additionalMenuMenuClick;
          $ctrl.checkIsTableOverflow = checkIsTableOverflow;
          $ctrl.tableResizeHandler = tableResizeHandler;
          $ctrl.uploadExcelFile = uploadExcelFile;
          $ctrl.openFileUpload = openFileUpload;

          $ctrl.mouseDown = mouseDown;
          $ctrl.mouseUp = mouseUp;
          $ctrl.slideLeft = slideLeft;
          $ctrl.slideRight = slideRight;
          $ctrl.slideUp = slideUp;
          $ctrl.slideDown = slideDown;
          $ctrl.mouseLeave = mouseLeave;

          function onInit() {
            if ($ctrl.delayInit) {
              $timeout(function () {
                $ctrl.initConfig();
              }, 10);
            } else {
              $ctrl.initConfig();
            }
            body.on('click', bodyClickHandler);
          }

          function onChanges(changes) {
            if (changes.options && changes.options.currentValue && changes.options.currentValue.gridConfig) {
              if (!$ctrl.options.tableId) {
                $ctrl.options.tableId = Helper.guid();
              }
              if (!$ctrl.options.templateScope.callbacks.sortTable) {
                $ctrl.options.templateScope.callbacks.sortTable = sortTable;
              }
              if (!$ctrl.options.templateScope.callbacks.onTableMenuSelect) {
                $ctrl.options.templateScope.callbacks.onTableMenuSelect = onTableMenuSelect;
              }
              if (!$ctrl.options.templateScope.callbacks.onColumnFilter) {
                $ctrl.options.templateScope.callbacks.onColumnFilter = onColumnFilter;
              }
              if (!$ctrl.options.templateScope.callbacks.addGridRow) {
                $ctrl.options.templateScope.callbacks.addGridRow = addGridRow;
              }
              if (!$ctrl.options.templateScope.callbacks.deleteRow) {
                $ctrl.options.templateScope.callbacks.deleteRow = deleteRow;
              }
              if ($ctrl.options.gridConfig.dataSource) {
                $ctrl.options.gridConfig.dataSource.data([]);
              }
              if (!$ctrl.rendered) {
                initializeDefaultKendoGridUI();
              }
            }
          }

          function onDestroy() {
            if ($ctrl.options && $ctrl.options.tableElement) {
              var grid = $ctrl.options.tableElement.data('kendoGrid');
              if (grid) {
                grid.destroy();
                $ctrl.options.tableElement.empty();
                $ctrl.options.tableElement.remove();
              }
            }
            if (tableTimeoutHandler) {
              $timeout.cancel(tableTimeoutHandler);
            }
            scrollContainer = $('.k-grid-content.k-auto-scrollable');
            scrollContainer.off('scroll', tableResizeHandler);
            scrollNavWatcher();
            detachEventHandlers();
            body.off('click', bodyClickHandler);
          }

          function initializeDefaultKendoGridUI() {
            $timeout(function () {
              KendoUIService.attachWidgetEventHandler(
                $ctrl.options.tableElement, {
                init: function () {
                  if (!$ctrl.options.gridConfig) {
                    return;
                  }
                  $ctrl.options.tableElement = angular.element('#' + $ctrl.options.tableId);
                  attachListeners();
                  $ctrl.isBusy = true;
                  setTableActiveElements();
                  if ($ctrl.source === 'local' && $ctrl.pagination) {
                    $ctrl.pagination.current_page = 1;
                  }
                  kendo.ui.progress($ctrl.options.tableElement, true);
                  $timeout(function () {
                    if (!$ctrl.preInitData) {
                      if ($ctrl.qTableFetchCallback && typeof $ctrl.qTableFetchCallback === 'function') {
                        $ctrl.qTableFetchCallback()
                          .then(function (res) {
                            if ($ctrl.source === 'local' && $ctrl.pagination) {
                              $ctrl.pagination.total = res.length;
                            }
                            $ctrl.options.gridConfig.dataSource.data(res);
                            $ctrl.isBusy = false;
                            if ($ctrl.qTableFetchCallback && typeof $ctrl.qTableAfterFetchCallback === 'function') {
                              $ctrl.qTableAfterFetchCallback({ options: $ctrl.options });
                            }
                            if ($ctrl.autoSize) {
                              setColumnFit();
                            }
                          }, function () {
                            $ctrl.isBusy = false;
                          });
                      }
                    } else {
                      $ctrl.options.gridConfig.dataSource.data($ctrl.preInitData);
                      $ctrl.isBusy = false;
                      if ($ctrl.qTableFetchCallback && typeof $ctrl.qTableAfterFetchCallback === 'function') {
                        $ctrl.qTableAfterFetchCallback({ options: $ctrl.options });
                      }
                    }
                    if ($ctrl.options.infiniteScrollDataLoad) {
                      scrollContainer = $ctrl.options.tableElement.find('.k-grid-content');
                      scrollContainer.on('scroll', fetchScrollData());
                    }
                  }, 350).then(function () {
                    for (var i = 0; i < _.get($ctrl.options, 'tableMenus.hideAndShowColumns', []).length; i++) {
                      $ctrl.options.templateScope.callbacks.onTableMenuSelect(void 0, $ctrl.options.tableMenus.hideAndShowColumns[i].field);
                    }
                    kendo.ui.progress($ctrl.options.tableElement, false);
                    $ctrl.rendered = true;
                    scrollContainer = $('.k-grid-content.k-auto-scrollable');
                    scrollContainer.on('scroll', tableResizeHandler);
                    if ($ctrl.tableActiveElements.gridHeader && $ctrl.tableActiveElements.gridHeader.length) {
                      $ctrl.headerBoundingRect = $ctrl.tableActiveElements.gridHeader[0].getBoundingClientRect();
                    }
                    if ($ctrl.autoSize) {
                      setColumnFit();
                    }
                  });
                }
              });
            }, 0);
          }

          function setColumnFit() {
            var tableWidth, totalColumnWidth = 0, isReadOnly;
            tableTimeoutHandler = $timeout(function () {
              var grid = $ctrl.options.tableElement.data('kendoGrid');
              if (!grid && tableTimeoutHandler) {
                $timeout.cancel(tableTimeoutHandler);
                return;
              }
              tableWidth = $ctrl.options.tableElement[0].getBoundingClientRect().width;
              grid.columns = grid.columns || [];
              if (grid.columns.length > 0) {
                for (var i = 0; i < grid.columns.length - 1; i++) {
                  grid.autoFitColumn(i);
                }
                isReadOnly = !!grid.columns[grid.columns.length - 1].command;
              }
            }, 750);
            tableTimeoutHandler.then(function () {
              $timeout(function () {
                var table = $ctrl.options.tableElement[0];
                if (!table) {
                  return;
                }
                var headerColumns = table.querySelectorAll('.k-grid-header table colgroup col'), contentColumns = table.querySelectorAll('.k-grid-content table colgroup col');
                for (var i = 0; i < headerColumns.length; i++) {
                  totalColumnWidth += headerColumns[i].getBoundingClientRect().width;
                }
                if (totalColumnWidth <= tableWidth) {
                  var width = (tableWidth - 58) / (headerColumns.length - (!isReadOnly ? 0 : 1));
                  for (var i = 0; i < headerColumns.length - (!isReadOnly ? 0 : 1); i++) {
                    headerColumns[i].style.width = width + 'px';
                    contentColumns[i].style.width = width + 'px';
                  }
                }
              });
            });
          }

          function setTableActiveElements() {
            $ctrl.tableActiveElements.gridHeader = $('.k-grid-header');
            $ctrl.tableActiveElements.gridRightBar = $('.tallyfy-grid-table-right');
            $ctrl.tableActiveElements.gridLeftBar = $('.tallyfy-grid-table-left');
            $ctrl.tableActiveElements.gridTableElement = $('.tallyfy-grid-table-element');
            $ctrl.tableActiveElements.gridRightBar.css('width', '28px');
            $ctrl.tableActiveElements.gridLeftBar.css('width', '28px');
            $ctrl.tableActiveElements.gridTableElement.css('width', 'calc(100% - 56px)');
          }

          function defaultMenuClick(e, id) {
            e.stopImmediatePropagation();
            $ctrl.defaultMenuOpen = !$ctrl.defaultMenuOpen;
            $ctrl.defaultMenuOpen ? $('#defaultMenuControl-' + id).collapse('show') : $('#defaultMenuControl-' + id).collapse('hide');
          }

          function additionalMenuMenuClick(e, id) {
            e.stopImmediatePropagation();
            if (!$ctrl.additionalMenuMenuOpen) {
              $ctrl.additionalMenuMenuOpen = {};
            }
            $ctrl.additionalMenuMenuOpen[id] = !$ctrl.additionalMenuMenuOpen[id];
            $ctrl.additionalMenuMenuOpen[id] ? $('#additional-menu-control-' + id).collapse('show') : $('#additional-menu-control-' + id).collapse('hide');
          }

          function fetchScrollData() {
            return _throttle(function () {
              scrollContainer = $ctrl.options.tableElement.find('.k-grid-content');
              var pagination = $ctrl.options.infiniteScrollDataLoad.pagination;
              var canLoad = (scrollContainer[0].scrollHeight - scrollContainer.scrollTop() < scrollContainer.height() + 120) && ((pagination.current_page <= pagination.total_pages) || pagination.next) && !$ctrl.isBusy;
              if (canLoad) {
                $ctrl.isBusy = true;
                kendo.ui.progress($ctrl.options.tableElement, true);
                $ctrl.qTableFetchCallback({ args: $ctrl.options.infiniteScrollDataLoad })
                  .then(
                    function (res) {
                      $ctrl.options.gridConfig.dataSource.data(res);
                      $ctrl.isBusy = false;
                      if ($ctrl.qTableFetchCallback && typeof $ctrl.qTableAfterFetchCallback === 'function') {
                        $ctrl.qTableAfterFetchCallback({ options: $ctrl.options });
                      }
                      kendo.ui.progress($ctrl.options.tableElement, false);
                    }, function () {
                      $ctrl.isBusy = false;
                      kendo.ui.progress($ctrl.options.tableElement, false);
                    }
                  );
              }

            }, 10);
          }

          function _throttle(fn, wait) {
            var time = Date.now();
            return function () {
              if ((time + wait - Date.now()) < 0) {
                fn();
                time = Date.now();
              }
            };
          }

          function addGridRow(e) {
            e.preventDefault();
            if (!$ctrl.options.tableState) {
              $ctrl.options.tableState = {};
            }
            var grid = $ctrl.options.tableElement.data('kendoGrid');
            validateRow();
            if (!$ctrl.options.tableState.editMode) {
              grid.addRow();
            } else {
              grid.dataSource.sync();
            }
            $ctrl.options.tableState.editMode = !$ctrl.options.tableState.editMode;
          }

          function deleteRow(e) {
            var grid = $ctrl.options.tableElement.data('kendoGrid');
            grid.removeRow($(e.target || e).closest('tr'));
            grid.dataSource.sync();
            $ctrl.options.tableElement.data('kendoGrid').refresh();
          }

          function onColumnFilter(column) {
            $timeout(function () {
              var dataSource = $ctrl.options.gridConfig.dataSource;
              kendo.ui.progress($ctrl.options.tableElement, true);
              if (!dataSource.filter()) {
                dataSource.filter({
                  field: column,
                  operator: $ctrl.options.tableFilterModel[column].operator,
                  value: $ctrl.options.tableFilterModel[column].value
                });
              } else {
                var filter = _.find(dataSource.filter().filters, { field: column });
                if (filter) {
                  filter.value = $ctrl.options.tableFilterModel[column].value;
                } else {
                  dataSource.filter().filters.push({
                    field: column,
                    operator: $ctrl.options.tableFilterModel[column].operator,
                    value: $ctrl.options.tableFilterModel[column].value
                  });
                }
              }
              goToNextPage($ctrl.pagination.page, $ctrl.pagination.perPage);
            }, 10);
          }

          function sortTable(column) {
            $timeout(function () {
              if ($ctrl.options.tableState.sort.field === column) {
                $ctrl.options.tableState.sort.dir =
                  $ctrl.options.tableState.sort.dir === 'asc'
                    ? 'desc' : $ctrl.options.tableState.sort.dir === 'desc' ? '' : 'asc';
              } else {
                $ctrl.options.tableState.sort.field = column;
                $ctrl.options.tableState.sort.dir = 'asc';
              }
              $ctrl.options.tableState.sort.dir
                ? $ctrl.options.gridConfig.dataSource.sort($ctrl.options.tableState.sort)
                : $ctrl.options.gridConfig.dataSource.sort([]);
            }, 10);
          }

          function onTableMenuSelect(e, column) {
            if (e) {
              e.stopPropagation();
              e.preventDefault();
              e.stopImmediatePropagation();
            }
            $timeout(function () {
              if (e) {
                if (!$ctrl.options.tableState.columns[column]) {
                  $ctrl.options.tableState.columns[column] = { isShown: false };
                }
                $ctrl.options.tableState.columns[column].isShown = !$ctrl.options.tableState.columns[column].isShown;
              }
              var grid = $ctrl.options.tableElement.data('kendoGrid');
              if (!grid) {
                return;
              }
              if (!$ctrl.options.tableState.columns[column]) {
                var col = _.find($ctrl.options.tableMenus.hideAndShowColumns, { field: column });
                $ctrl.options.tableState.columns[column] = { isShown: col.initState };
              }
              if ($ctrl.options.tableState.columns[column].isShown) {
                grid.showColumn(column);
              } else {
                grid.hideColumn(column);
              }
            }, 10);
          }

          function goToNextPage(page, perPage) {
            $ctrl.isBusy = true;
            kendo.ui.progress($ctrl.options.tableElement, true);
            if ($ctrl.source !== 'local') {
              $ctrl.options.gridConfig.dataSource.data([]);
              angular.extend($ctrl.pagination, { page: page, per_page: perPage || $ctrl.pagination.per_page });
              $ctrl.qTableFetchCallback({ args: $ctrl.pagination })
                .then(
                  function (res) {
                    $ctrl.options.gridConfig.dataSource.data(res);
                    $ctrl.options.gridConfig.dataSource.query({ pageSize: perPage });
                    $ctrl.isBusy = false;
                    if ($ctrl.qTableFetchCallback && typeof $ctrl.qTableAfterFetchCallback === 'function') {
                      $ctrl.qTableAfterFetchCallback({ options: $ctrl.options });
                    }
                    kendo.ui.progress($ctrl.options.tableElement, false);
                  }, function () {
                    $ctrl.isBusy = false;
                    kendo.ui.progress($ctrl.options.tableElement, false);
                  }
                );
            } else {
              $timeout(function () {
                $ctrl.pagination.current_page = page;
                $ctrl.pagination.per_page = perPage || $ctrl.pagination.per_page;
                var query = {
                  page: $ctrl.pagination.current_page,
                  pageSize: $ctrl.pagination.per_page
                };
                if ($ctrl.options.tableState.sort.dir) {
                  query.sort = $ctrl.options.tableState.sort;
                }
                var dataSource = $ctrl.options.gridConfig.dataSource;
                if (dataSource.filter()) {
                  query.filter = dataSource.filter().filters;
                }
                dataSource.query(query);
                if ($ctrl.pagination) {
                  $ctrl.pagination.total = dataSource.total();
                  var totalPage = Math.ceil($ctrl.pagination.total / $ctrl.pagination.per_page);
                  if (totalPage < $ctrl.pagination.current_page) {
                    $ctrl.pagination.current_page = totalPage;
                  } else {
                    $ctrl.pagination.current_page = $ctrl.pagination.current_page || 1;
                  }
                }
              }, 350).then(function () {
                kendo.ui.progress($ctrl.options.tableElement, false);
              });
            }
          }

          function exportToExcel() {
            if ($ctrl.exportDisplayedDataOnly) {
              var grid = $ctrl.options.tableElement.data("kendoGrid"), fileName = ($ctrl.options.exportFileName || 'download') + '.xlsx', table = document.createElement('table');
              $timeout(function () {
                var th = grid.thead.find('th');
                var tableHtml = '<thead><tr>';
                for (var i = 0; i < th.length; i++) {
                  if (th[i].style.getPropertyValue('display') != 'none') {
                    var headerLabels = th[i].getElementsByClassName('header-label');
                    if (headerLabels.length) {
                      tableHtml += '<th>' + headerLabels[0].innerText + '</th>';
                    } else {
                      tableHtml += '<th>' + th[i].innerText + '</th>';
                    }
                  }
                }
                tableHtml += '</tr></thead>';
                var tr = grid.tbody.find(' > tr');
                tableHtml += '<tbody>';
                for (var i = 0; i < tr.length; i++) {
                  tableHtml += '<tr>';
                  var td = $(tr[i]).find(' > td');
                  for (var j = 0; j < td.length; j++) {
                    if (td[j].style.getPropertyValue('display') != 'none') {
                      var cellValue = $(td[j]).find('.table-field-value').text() || td[j].innerText;
                      tableHtml += '<td>' + cellValue + '</td>';
                    }
                  }
                  tableHtml += '</tr>';
                }
                tableHtml += '</tbody>';
                table.innerHTML = tableHtml;
                var wb = XLSX.utils.table_to_book(table, { sheet: "sheet1", raw: true });
                XLSX.writeFile(wb, fileName);
              }, 0);
            } else {
              var tableHtml = '<thead><tr>', fileName = ($ctrl.options.exportFileName || 'download') + '.xlsx', grid = $ctrl.options.tableElement.data("kendoGrid"), data = grid.dataSource.data(), columns, table = document.createElement('table');
              columns = _.filter(grid.columns, function (column) {
                return !column.hidden && column.exportKeyMap && !_.find(_.get($ctrl.options, 'tableMenus.hiddenColumnsOnExport', []), function (c) {
                  return c === column.field;
                });
              });
              for (var i = 0; i < columns.length; i++) {
                tableHtml += '<th>' + columns[i].exportKeyMap.label + '</th>';
              }
              tableHtml += '</tr></thead>';
              tableHtml += '<tbody>';

              for (var i = 0; i < data.length; i++) {
                tableHtml += '<tr>';
                for (var j = 0; j < columns.length; j++) {
                  var cellValue = '';
                  if (typeof _.get(data[i], columns[j].exportKeyMap.key) === 'object') {
                    var value = _.get(data[i], columns[j].exportKeyMap.key);
                    cellValue = value ? JSON.stringify(value) : '';
                  } else {
                    cellValue = _.get(data[i], columns[j].exportKeyMap.key);
                  }
                  tableHtml += '<td>' + (cellValue || '') + '</td>';
                }
                tableHtml += '</tr>';
              }
              tableHtml += '</tbody>';
              table.innerHTML = tableHtml;
              var wb = XLSX.utils.table_to_book(table, { sheet: "sheet1", raw: true });
              XLSX.writeFile(wb, fileName);
            }
          }

          function exportToPDF() {
            var grid = $ctrl.options.tableElement.data("kendoGrid");
            grid.saveAsPDF();
          }

          function exportToCsv(e, fileName) {
            if ($ctrl.exportDisplayedDataOnly) {
              var grid = $ctrl.options.tableElement.data("kendoGrid"), csv = '', grid = $ctrl.options.tableElement.data("kendoGrid");
              $timeout(function () {
                var th = grid.thead.find('th'), headerTitles = [];
                for (var i = 0; i < th.length; i++) {
                  if (th[i].style.getPropertyValue('display') != 'none') {
                    var headerLabels = th[i].getElementsByClassName('header-label');
                    if (headerLabels.length) {
                      headerTitles.push('"' + headerLabels[0].innerText + '"');
                    } else {
                      headerTitles.push('"' + th[i].innerText + '"');
                    }
                  }
                }
                csv += headerTitles.join(',');
                var tr = grid.tbody.find(' > tr');
                for (var i = 0; i < tr.length; i++) {
                  var td = $(tr[i]).find(' > td'), rowLabel = [];
                  for (var j = 0; j < td.length; j++) {
                    if (td[j].style.getPropertyValue('display') != 'none') {
                      var skipFormatElements = $(td[j]).find('.table-field-value.keep-format'), cellValue = $(td[j]).find('.table-field-value').text() || td[j].innerText;
                      rowLabel.push(skipFormatElements.length ? '="' + cellValue + '"' : '"' + cellValue + '"');
                    }
                  }
                  csv += '\n' + rowLabel.join(',');
                }

                kendo.saveAs({ dataURI: new Blob([csv], { type: 'text/csv;charset=utf-8' }), fileName: (fileName || $ctrl.options.exportFileName || 'download') + '.csv' });
              }, 0);
            } else {
              var grid, csv, data, columns, headerTitles;
              headerTitles = [];

              grid = $ctrl.options.tableElement.data("kendoGrid");
              columns = _.filter(grid.columns, function (column) {
                return !column.hidden && column.exportKeyMap && !_.find(_.get($ctrl.options, 'tableMenus.hiddenColumnsOnExport', []), function (c) {
                  return c === column.field;
                });
              });
              for (var i = 0; i < columns.length; i++) {
                headerTitles.push('"' + columns[i].exportKeyMap.label + '"');
              }
              csv = headerTitles.join(',');
              data = grid.dataSource.data();
              for (var i = 0; i < data.length; i++) {
                var rowLabel = [];
                for (var j = 0; j < columns.length; j++) {
                  var cellValue = '';
                  if (typeof _.get(data[i], columns[j].exportKeyMap.key) === 'object') {
                    var value = _.get(data[i], columns[j].exportKeyMap.key);
                    cellValue = value ? JSON.stringify(value) : '';
                  } else {
                    cellValue = _.get(data[i], columns[j].exportKeyMap.key);
                  }
                  rowLabel.push('"' + (cellValue === null ? ' ' : cellValue) + '"');
                }
                csv += '\n' + rowLabel.join(',');
              }

              kendo.saveAs({ dataURI: new Blob([csv], { type: 'text/csv;charset=utf-8' }), fileName: (fileName || $ctrl.options.exportFileName || 'download') + '.csv' });
            }
          }

          function slideLeft() {
            scrollContainer = $('.k-grid-content.k-auto-scrollable');
            if (scrollContainer && scrollContainer.length) {
              scrollContainer[0].scrollLeft -= (scrollContainer[0].scrollLeft > 0 ? 40 : 0);
              $ctrl.canScrollLeft = scrollContainer[0].scrollLeft > 0;
            }
          }

          function slideRight() {
            scrollContainer = $('.k-grid-content.k-auto-scrollable');
            if (scrollContainer && scrollContainer.length) {
              var table = scrollContainer.find('table');
              if (table && table.length) {
                scrollContainer[0].scrollLeft += (scrollContainer[0].scrollLeft < table[0].getBoundingClientRect().width ? 40 : 0);
                $ctrl.canScrollRight = scrollContainer[0].scrollLeft < (table[0].getBoundingClientRect().width - scrollContainer[0].getBoundingClientRect().width);
              }
            }
          }

          function slideUp() {
            scrollContainer = $('.k-grid-content.k-auto-scrollable');
            if (scrollContainer && scrollContainer.length) {
              scrollContainer[0].scrollTop -= (scrollContainer[0].scrollTop > 0 ? 40 : 0);
              $ctrl.canScrollUp = scrollContainer[0].scrollTop > 0;
            }
          }

          function slideDown() {
            scrollContainer = $('.k-grid-content.k-auto-scrollable');
            if (scrollContainer && scrollContainer.length) {
              var table = scrollContainer.find('table');
              if (table && table.length) {
                scrollContainer[0].scrollTop += (scrollContainer[0].scrollTop < table[0].getBoundingClientRect().height ? 40 : 0);
                $ctrl.canScrollDown = scrollContainer[0].scrollTop < (table[0].getBoundingClientRect().height - scrollContainer[0].getBoundingClientRect().height);
              }
            }
          }

          function checkIsTableOverflow() {
            scrollContainer = $('.k-grid-content.k-auto-scrollable');
            if (scrollContainer && scrollContainer.length) {
              var table = scrollContainer.find('table');
              if (table && table.length) {
                return table[0].getBoundingClientRect().width > scrollContainer[0].getBoundingClientRect().width;
              }
            }
            return false;
          }

          function tableResizeHandler(e) {
            scrollContainer = $('.k-grid-content.k-auto-scrollable');
            if (scrollContainer && scrollContainer.length) {
              var table = scrollContainer.find('table');
              if (table && table.length) {
                $ctrl.canScrollUp = scrollContainer[0].scrollTop > 0;
                $ctrl.canScrollDown = scrollContainer[0].scrollTop < (table[0].getBoundingClientRect().height - scrollContainer[0].getBoundingClientRect().height);
                $ctrl.canScrollLeft = scrollContainer[0].scrollLeft > 0;
                $ctrl.canScrollRight = scrollContainer[0].scrollLeft < (table[0].getBoundingClientRect().width - scrollContainer[0].getBoundingClientRect().width);
              }
            }
            $rootScope.$emit('RELATIVE_PARENT_ELEMENT::REPOSITION');
          }

          function mouseDown(cb) {
            if ($ctrl.isBusy) {
              return;
            }
            if (interval == -1) {
              interval = $interval(function () {
                cb();
                tableResizeHandler();
              }, 200);
            }
          }

          function mouseUp(cb) {
            if (interval != -1) {
              $interval.cancel(interval);
            }
            interval = -1;
            cb();
            tableResizeHandler();
          }

          function mouseLeave() {
            if (interval != -1) {
              $interval.cancel(interval);
              interval = -1;
            }
          }

          function detachEventHandlers() {
            if (!_.get($ctrl.options, 'tableElement', []).length) {
              return;
            }
            for (var i = 0; i < events.length; i++) {
              $ctrl.options.tableElement[0].removeEventListener(events[i].name, events[i].handler);
            }
          }

          function bodyClickHandler(e) {
            if ($ctrl.options.tableState && !$ctrl.options.tableState.editMode) {
              return;
            }
            e.preventDefault();
            e.stopImmediatePropagation();
            if (!$ctrl.options.tableState) {
              $ctrl.options.tableState = {};
            }
            if (_.get($ctrl.options, 'tableState.editMode')) {
              return;
            }
            var el = e.target.closest('.ty-kendo-grid-parent');
            if (!el) {
              if ($ctrl.options.tableState.touched) {
                validateRow();
              }
              $ctrl.options.tableState.editMode = false;
            } else {
              $ctrl.options.tableState.touched = true;
              if (el && ($ctrl.options.tableElement.attr('id') !== $(el).attr('id'))) {
                validateRow();
                $ctrl.options.tableState.editMode = false;
              }
            }
          }

          function attachListeners() {
            if (!$ctrl.options.tableElement.length) {
              return;
            }
            for (var i = 0; i < events.length; i++) {
              $ctrl.options.tableElement[0].addEventListener(events[i].name, events[i].handler);
            }
          }

          function validateRow() {
            if (!$ctrl.options || !$ctrl.options.tableElement) {
              return;
            }
            var grid = $ctrl.options.tableElement.data('kendoGrid');
            var rows = grid.dataSource.data(), columns = grid.columns, emptyRowIndex = [];
            for (var i = 0; i < rows.length; i++) {
              var isRowEmpty = true;
              for (var j = 0; j < columns.length; j++) {
                var value = _.get(rows, '[' + i + '][' + columns[j].field + ']');
                if (value) {
                  isRowEmpty = false;
                  break;
                }
              }
              if (isRowEmpty) {
                emptyRowIndex.push(rows[i]);
              }
            }
            if (emptyRowIndex.length) {
              for (var i = 0; i < emptyRowIndex.length; i++) {
                var el = $ctrl.options.tableElement.find('[data-uid=\'' + emptyRowIndex[i].uid + '\']');
                deleteRow(el);
              }
            }
            if (typeof $ctrl.afterValidatedCallback === 'function') {
              $timeout(function () {
                $ctrl.afterValidatedCallback();
              }, 0);
            }
          }

          function openFileUpload() {
            $('#file-' + $ctrl.options.tableId).trigger('click');
          }

          function getSortedExcelTableRefNumber(sheetValues) {
            var sheetKeys = Object.keys(sheetValues).filter(
              function (key) {
                return key != '!ref' && key != '!margins';
              }).sort(function (a, b) {
                return a.localeCompare(b);
              });
            var totalRows = _.reduce(_.map(sheetKeys, function (key) { return parseInt(key.replace(/\D/g, '')); }), function (a, b) {
              return Math.max(a, b);
            }), alphaCol = _.uniq(_.map(sheetKeys, function (key) { return key.replace(/\d+/g, ''); }));
            var keys = [];
            for (var i = 0; i < alphaCol.length; i++) {
              for (var j = 1; j <= totalRows; j++) {
                keys.push(alphaCol[i] + j.toString());
              }
            }
            return keys;
          }

          function uploadExcelFile(files) {
            var file = files[0], reader = new FileReader(), grid = $ctrl.options.tableElement.data("kendoGrid");
            reader.onload = function (e) {
              var workbook = XLSX.read(e.target.result), sheetNames = workbook.SheetNames;
              if (!sheetNames.length) {

                return;
              }
              var sheetName = workbook.SheetNames[0],
                sheetValues = workbook.Sheets[sheetName],
                rows = [], rowIndex = 0,
                keys = getSortedExcelTableRefNumber(sheetValues);
              delete sheetValues['!ref'];
              delete sheetValues['!margins'];
              for (var i = 0; i < keys.length; i++) {
                if (keys[i].replace(/\D/g, '') === '1') {
                  rowIndex = rowIndex !== -1 ? 0 : rowIndex++;
                } else {
                  if (!rows[rowIndex]) {
                    rows[rowIndex] = [];
                  }
                  rows[rowIndex].push(sheetValues[keys[i]] ? sheetValues[keys[i]].w : '');
                  rowIndex++;
                }
              }
              for (var i = 0; i < rows.length; i++) {
                var newRow = {};
                for (var j = 0; j < rows[i].length; j++) {
                  if (grid.columns[j]) {
                    newRow[grid.columns[j].field] = rows[i][j];
                  }
                }
                grid.dataSource.add(newRow);
              }
              grid.dataSource.sync();
              $ctrl.options.tableElement.data('kendoGrid').refresh();
            };
            reader.readAsArrayBuffer(file);

          }

          scrollNavWatcher = $scope.$watchGroup(['$ctrl.canScrollUp', '$ctrl.canScrollDown', '$ctrl.canScrollLeft', '$ctrl.canScrollRight'], function (newValue, oldValue) {
            if (newValue === oldValue) {
              return;
            }
            $ctrl.hideScrollNav = (!newValue[0] && !newValue[1] && !newValue[2] && !newValue[3]);
          });

        }
    });
})();
