/**
 * @ngdoc Component
 * @name tallyfy.steps.component.powerTools.powerRole
 * @module tallyfy.steps
 *
 * @description
 * A component to manage bunch of steps to assign selected user
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.steps')
    .component('powerRole', {
      templateUrl: 'app/modules/steps/powerTools/powerRole/powerRole.html',
      bindings: {
        type: '<',
        process: '<',
        cancel: '&',
        activeTab: '<',
        userAssignment: '='
      },
      controller:
         /*@ngInject*/
        function (_, StepService, $filter, $rootScope, UsersService, store, $timeout, $q, CompactTaskService, DATEFORMAT, moment, DateUtils, Growl, TasksService, Helper) {
          var $ctrl = this,
            availableSteps,
            isError = false,
            growl = new Growl();

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.assignTypeList = StepService.getAssignTypes();
          $ctrl.setAssignmentType = setAssignmentType;
          $ctrl.setSearchPlaceholder = setSearchPlaceholder;
          $ctrl.setAssignmentUser = setAssignmentUser;
          $ctrl.isUserAlreadyAssigned = isUserAlreadyAssigned;
          $ctrl.userAssignStep = userAssignStep;
          $ctrl.undoChanges = undoChanges;
          $ctrl.saveStepsAssignees = saveStepsAssignees;
          $ctrl.allStepUserAssign = allStepUserAssign;
          $ctrl.isUserAssignedToAll = isUserAssignedToAll;
          $ctrl.defaultAvatar = defaultAvatar;
          $ctrl.defaultAvatarText = defaultAvatarText;
          $ctrl.uiSelectOpenClose = uiSelectOpenClose;
          $ctrl.searchRecords = searchRecords;
          $ctrl.loadMore = loadMore;
          $ctrl.assignRoleUser = assignRoleUser;

          /**
           * public properties
           */
          $ctrl.onSaving = false;
          $ctrl.responseData = [];
          $ctrl.activeUsersUiSelectLimit = 10;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() { }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() {
            if($ctrl.activeTab.value === 'roles') {
              $ctrl.stepsToUpdate = angular.copy($ctrl.process.steps);
              $ctrl.userAssignment = false;
              getAllRoles();
              setAssignmentType(_.first($ctrl.assignTypeList));
              setUsersList();
              $ctrl.stepsToUpdated = [];
            }
          }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @ngdoc method
           * @name setAssignmentType
           * @description Set Assignment Type
           * @param {*} stepAssignType
           */
          function setAssignmentType(stepAssignType) {
            if($ctrl.selectedAssignType === stepAssignType)
              return;
            
            $ctrl.selectedAssignType = stepAssignType;
          }

          /**
           * @ngdoc method
           * @name setSearchPlaceholder
           * @description Set placeholder message from json file
           * @param {Object} $select
           */
          function setSearchPlaceholder($select) {
            if (!_.isUndefined($select.searchInput)) {
              _.head($select.searchInput).placeholder = $filter('translate')("global.task.messages.searchMemberGuest");
            }
          }

          function getAllRoles() {
            StepService.getAllRoles()
              .then(function (response) {
                $ctrl.roles = _.get(response, 'data');
                _.forEach($ctrl.roles, function (roleValue, roleKey) {
                  var step = [], userAlreadyAssigned;
                  _.forEach($ctrl.process.steps, function (stepValue) {
                    var stepRole = _.find(stepValue.roles.data, { org_role_id: roleValue.id });
                    if(stepRole) {
                      step.push(stepValue);
                      userAlreadyAssigned = isUserAlreadyAssigned(stepValue);
                    }
                  });
                  angular.extend($ctrl.roles[roleKey], { step: step });
                  angular.extend($ctrl.roles[roleKey], { assigneesUpdated: userAlreadyAssigned });
                });
              }, function () { });
          }

          /**
            * @ngdoc method
            * @name setUsersList
            * @description Set active users list in dropdown
          */
          function setUsersList() {
            $q.all([
              store.getLightweightGuests(),
              store.getUsers(),
              store.getLightweightGroups()
            ]).then(function (response) {
              var activeUsers = UsersService.getBilledUsers(_.get(response, '[1]', [])), activeGuests = _.get(response, '[0]', []), activeGroups = _.get(response, '[2]', []);
              $ctrl.activeUsers = _.concat(activeUsers, activeGroups, activeGuests);
              $ctrl.selectedUser = _.find($ctrl.activeUsers, { id: $rootScope.identity.id });
              $ctrl.activeUsersCache = angular.copy($ctrl.activeUsers);
              $ctrl.usersInOrg = activeUsers;
            });
          }

          /**
            * @ngdoc method
            * @name setAssignmentUser
            * @param {Object} selected
            * @description Reset steps assignment 
          */
          function setAssignmentUser(selected) {
            if ($ctrl.selectedUser === selected) {
              return;
            }
            if (_.get(selected.selected, 'addNewGuest')) {
              selected.selected = _.omit(selected.selected, ['addNewGuest']);
            }
            getAllRoles();
          }

          /**
            * @ngdoc method
            * @name isUserAlreadyAssigned
            * @description Step already assign user or not 
            * @param {Object} stepAssign
          */
          function isUserAlreadyAssigned(stepAssign) {
            var assignees;
            if ($ctrl.process.type === 'task') {
               assignees = _.get($ctrl.selectedUser, 'id') ? (_.get($ctrl.selectedUser, 'type') === 'member' ? stepAssign.owners.users : stepAssign.owners.groups) : stepAssign.owners.guests;
            } else {
               assignees = _.get($ctrl.selectedUser, 'id') ? (_.get($ctrl.selectedUser, 'type') === 'member' ? stepAssign.assignees : stepAssign.groups)
                  : stepAssign.guests;
            }
            var isAssigned = _.get($ctrl.selectedUser, 'id') ? _.includes(assignees, $ctrl.selectedUser.id) : _.includes(assignees, $ctrl.selectedUser.email);
            return $ctrl.selectedAssignType.value === 'assign' ? isAssigned : !isAssigned;
          }

          /**
           * @ngdoc method
           * @name userAssignStep
           * @description Edit selected steps data
           * @param {Object} step
           */
          function userAssignStep(step) {
            $ctrl.userAssignment = true;
            var editStepData = angular.copy(step);
            if ($ctrl.selectedAssignType.value === 'assign') {
              if ($ctrl.process.type === 'task') {
                _.get($ctrl.selectedUser, 'id') ? (_.get($ctrl.selectedUser, 'type') === 'member' ? step.owners.users.push($ctrl.selectedUser.id) : step.owners.groups.push($ctrl.selectedUser.id)) : step.owners.guests.push($ctrl.selectedUser.email);
              } else {
                _.get($ctrl.selectedUser, 'id') ? (_.get($ctrl.selectedUser, 'type') === 'member' ? step.assignees.push($ctrl.selectedUser.id) : step.groups.push($ctrl.selectedUser.id))
                  : step.guests.push($ctrl.selectedUser.email);
              }
              $ctrl.stepsToUpdated.push(step);
              step['assigneesUpdated'] = true;
              step['unassigneesUpdated'] = false;
            }
            if ($ctrl.selectedAssignType.value === 'unassign') {
              if ($ctrl.process.type === 'task' && _.get($ctrl.selectedUser, 'id') && (_.get(editStepData, 'owners.users.length', 0) + _.get(editStepData, 'owners.groups.length', 0)) === 1) {
                $timeout(function () {
                  growl.warning($filter('translate')('tasks.messages.taskUnassignees'), {
                    referenceId: 'growPowerTools',
                    disableIcons: true,
                    disableCloseButton: true
                  });
                }, 0);
                return;
              }
              var index;
              if ($ctrl.process.type === 'task') {
                if (_.get($ctrl.selectedUser, 'id')) {
                  if (_.get($ctrl.selectedUser, 'type') === 'member') {
                    index = _.indexOf(editStepData.owners.users, $ctrl.selectedUser.id);
                    (index !== -1) ? step.owners.users.splice(index, 1) : angular.noop();
                  } else {
                    index = _.indexOf(editStepData.owners.groups, $ctrl.selectedUser.id);
                    (index !== -1) ? step.owners.groups.splice(index, 1) : angular.noop();
                  }
                } else {
                  index = _.indexOf(editStepData.owners.guests, $ctrl.selectedUser.email);
                  (index !== -1) ? step.owners.guests.splice(index, 1) : angular.noop();
                }
              }
              else {
                if (_.get($ctrl.selectedUser, 'id')) {
                  if (_.get($ctrl.selectedUser, 'type') === 'member') {
                    index = _.indexOf(editStepData.assignees, $ctrl.selectedUser.id);
                    (index !== -1) ? step.assignees.splice(index, 1) : angular.noop();
                  } else {
                    index = _.indexOf(editStepData.groups, $ctrl.selectedUser.id);
                    (index !== -1) ? step.groups.splice(index, 1) : angular.noop();
                  }
                } else {
                  index = _.indexOf(editStepData.guests, $ctrl.selectedUser.email);
                  (index !== -1) ? step.guests.splice(index, 1) : angular.noop();
                }
              }
              $ctrl.stepsToUpdated.push(step);
              step['assigneesUpdated'] = false;
              step['unassigneesUpdated'] = true;
            }
          }

          /**
           * @ngdoc method
           * @name removeUser
           * @description Remove selected step
           * @param {Integer} index
           */
          function undoChanges(role, index) {
            angular.extend($ctrl.roles[index], { roleAssigned: false });
            _.forEach(role.step, function (stepValue) {
              _.remove($ctrl.stepsToUpdated, { id: stepValue.id });
            });
          }

          /**
           * @ngdoc method
           * @name saveStepsAssignees
           * @description Update Step Assigner
           */
          function saveStepsAssignees() {
            $ctrl.userAssignment = false;
            var updatedSteps = _.filter($ctrl.stepsToUpdated, function(step) {
              return step.assigneesUpdated || step.unassigneesUpdated;
            });
            if (updatedSteps.length > 0) {
              if ($ctrl.type === 'launchBlueprint') {
                $ctrl.cancel({ value: updatedSteps, type: 'power-assigner' });
              } else {
                if ($ctrl.process.type === 'task') {
                  saveBulkAssign(updatedSteps);
                } else {
                  saveSteps(updatedSteps);
                }
              }
            }
          }

          /**
           * @ngdoc method
           * @name saveSteps
           * @description save steps
           * @private 
           * @param {*} allUpdatedSteps
           */
          function saveSteps(allUpdatedSteps) {
            var firstStep = _.omit(_.head(allUpdatedSteps), [ 'summary', 'original_summary' ]);
            availableSteps = allUpdatedSteps;
            $ctrl.onSaving = true;
            updateStep(firstStep).then(successStepResponse, failureStepResponse);
          }

          /**
           * @ngdoc method
           * @name successStepResponse
           * @description 
           * Calls after getting successful response from API
           * Used recursion to call step API to create bunch of steps
           * @param {*} response
           */
          function successStepResponse(response) {
            var step = $ctrl.process.type === 'task' ? response.task : response.data,
            remainSteps = availableSteps.slice(1);
            $ctrl.responseData.push(step);
            if (remainSteps.length) {
              saveSteps(remainSteps);
            }
            if (!remainSteps.length && !isError) {
              $ctrl.isSaved = true;
              $ctrl.onSaving = false;
              $ctrl.cancel({ value: $ctrl.responseData, type: 'power-assigner' });
            }
            if (isError) {
              resetVariables();
            }
          }

          /**
           * @function
           * @name resetVariables
           * @description Helper method to set falsy value in some variable's
           * @returns {void}
           */
          function resetVariables() {
            $ctrl.onSaving = false;
            $ctrl.isTimeOut = false;
          }

          /**
           * @ngdoc method
           * @name failureStepResponse
           * @description It will call when step API gets failed due to some reason
           */
          function failureStepResponse() {
            isError = true;
            resetVariables();
            $ctrl.isSaved = false;
            var resetSteps = availableSteps.slice(1);
            if (resetSteps.length) {
              saveSteps(resetSteps);
            }
            $ctrl.steps.length = 0;
          }

          /**
           * @ngdoc method
           * @name updateStep
           * @description Update Step Assigner
           * @param {Object} value
           */
          function updateStep(value) {
            var step = _.omit(value, ['assigneesUpdated', 'unassigneesUpdated']);
            if ($ctrl.process.type === 'task') {
              var params = { id: step.id, action_id: step.run_id, update_dependent_deadlines: true, with: 'threads' },
                deadline = DateUtils.toUTC(moment(step.deadline_unformatted)).format(DATEFORMAT.DEFAULT),
                savedTask = angular.extend({}, step, { deadline: deadline });
              return CompactTaskService.saveTask(params, savedTask);
            }
            else {
              return StepService.updateSteps({
                id: step.id,
                checklist_id: $ctrl.process.id
              }, step);
            }
          }

          /**
           * @ngdoc method
           * @name allStepUserAssign
           * @description All steps Selected User Assign
           */
          function allStepUserAssign() {
            _.forEach($ctrl.roles, function (role) {
              angular.extend(role, { roleAssigned: true });
              _.forEach(role.step, function (step) {
                !isUserAlreadyAssigned(step) ? userAssignStep(step) : angular.noop();
              });
            });
          }

          function isUserAssignedToAll() {
            var users = _.filter(angular.copy($ctrl.stepsToUpdate), function (step) {
              var assignees;
              if ($ctrl.process.type === 'task') {
                assignees = _.get($ctrl.selectedUser, 'id') ? (_.get($ctrl.selectedUser, 'type') === 'member' ? step.owners.users : step.owners.groups) : step.owners.guests;
              } else {
                assignees = _.get($ctrl.selectedUser, 'id') ? (_.get($ctrl.selectedUser, 'type') === 'member' ? step.assignees : step.groups) : step.guests;
              }
              var selectedUser = _.get($ctrl.selectedUser, 'id') ? $ctrl.selectedUser.id : $ctrl.selectedUser.email;
              return _.includes(assignees, selectedUser);
            });
            if ($ctrl.selectedAssignType.value === 'assign') {
              return angular.copy($ctrl.stepsToUpdate || []).length === users.length;
            } else {
              return !users.length;
            }
          }

          function assignRoleUser(role, index) {
            angular.extend($ctrl.roles[index], { roleAssigned: true });
            _.forEach(role.step, function (step) {
              userAssignStep(step);
            });
          }

          /**
           * @ngdoc method
           * @name defaultAvatar
           * @public
           * @description set default avatar
           * @param {string} avatar
           * @return {boolean}
           */
          function defaultAvatar(avatar) {
            return TasksService.setDefaultAvatar(avatar);
          }

          /**
           * @ngdoc method
           * @name defaultAvatarText
           * @public
           * @description set default avatar Text
           * @param {object} user
           * @return {string} first character of name
           */
          function defaultAvatarText(user) {
            if (!user) {
              return '';
            }
            if (_.get(user, 'id')) {
              return (user.type === 'member') ? TasksService.setDefaultAvatarText(user.first_name, user.last_name) : TasksService.setDefaultAvatarText(user.name);
            } else {
              return TasksService.setDefaultAvatarText(user.email);
            }
          }

           /**
           * @ngdoc method
           * @name uiSelectOpenClose
           * @public
           * @description reset data on ui-select
           * @param {object} $select
           * open and close
           */
          function uiSelectOpenClose($select) {
            if (!$select.open) {
              return;
            }
            $ctrl.searchPreviousValue = '';
            $ctrl.activeUsersUiSelectLimit = 10;
            $ctrl.activeUsers = $ctrl.activeUsersCache;
          }

           /**
            * @ngdoc method
            * @name searchRecords
            * @private
            * @description search record locally
            * wher user search for record. 
            * @param {object} $select
            * @param {object} search
            */
          function searchRecords($select, search) {
            if (!$select.open && (!search && !$ctrl.searchPreviousValue)) {
              return;
            }
            $ctrl.activeUsersUiSelectLimit = 10;
            if (search) {
              $ctrl.searchPreviousValue = search;
              var searchedRecords = _.filter($ctrl.activeUsersCache, function (value) {
                return _.toLower(value.email).indexOf(_.toLower(search)) !== -1 || _.toLower(value.text).indexOf(_.toLower(search)) !== -1;
              });
              $ctrl.activeUsers = searchedRecords;
            }
            if(!search && $ctrl.searchPreviousValue) {
              $ctrl.activeUsers = $ctrl.activeUsersCache;
            }
            if($ctrl.activeUsers.length === 0 && Helper.isValidEmail(search)) {
              $ctrl.activeUsers.push({ addNewGuest: true, email: search });
            }
          }

           /**
           * @ngdoc method
           * @name loadMore
           * @public
           * @description triggers when scrolled down
           */
          function loadMore() {
            $ctrl.activeUsersUiSelectLimit += 10;
          }

          /**
           * @ngdoc method
           * @name saveBulkAssign
           * @description Update Step Bulk Assigner
           */
          function saveBulkAssign(updatedSteps){
            $ctrl.updateAssignee = [];
            var run_id = _.get($ctrl.process, 'id');
            _.forEach(updatedSteps, function (step) {
              var assignees = {
                "id": step.id,
                "owners": _.omit(step.owners, ['taskUrls'])
              };
              if (assignees.owners.users) {
                assignees.owners.users = Helper.getFilteredUsers($ctrl.usersInOrg, assignees.owners.users);
              }
              $ctrl.updateAssignee.push(assignees);
            });
            var savedTask = { tasks: $ctrl.updateAssignee },
            params = { org: _.get($rootScope, 'identity.default_organization.id'), run_id: run_id};
            TasksService.updateBulkTasks(params, savedTask).then(function (response) {
              var responseData = _.get(response, 'data');
              $ctrl.userAssignment = true;
              $ctrl.cancel({ value: responseData, type: 'power-assigner' });
            }, function () { });
          }
          //controller ends
        }
    });
})();