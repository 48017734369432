(function () {
  'use strict';
  angular.module('tallyfy')
    .component('froalaBpView', {
      templateUrl: 'app/components/froala-bp-view/froala-bp-view.component.html',
      bindings: {
        blueprintId: '<',
        blueprintAlias: '<',
        activeProcess: '<',
        previewMode: '<',
        expandAll: '<?'
      },
      controller:
        /*@ngInject()*/
        function (_, $rootScope, $scope, $q, $element, $timeout, ProcessService, UsersService, store, USER_STATE, UtilsService, $stateParams, StepService, PublicLibraryService) {
          var $ctrl = this;

          $ctrl.process = void 0;
          $ctrl.availableUsers = [];
          $ctrl.havePermissionToRead = false;
          $ctrl.expanded = false;
          $ctrl.isBusy = false;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.onPreviewButtonClick = onPreviewButtonClick;

          function onInit() {
            var defer = $q.defer();
            $ctrl.userState = $rootScope.userState;
            if ($ctrl.userState === USER_STATE.MEMBER) {
              var parent = $element.closest('.insert-blueprint-tag');
              parent.addClass('rendering');
              $ctrl.isBusy = true;
              $rootScope.$emit('BLUEPRINT_READ_MODE:RENDERING');
              var resources = [
                ProcessService.get({
                  id: $ctrl.blueprintId,
                  with: 'steps,steps.captures,tags,folder,steps.roles,steps.threads,stages',
                  skipNotFound: true
                }),
                store.getUsers(),
                ProcessService.getPermission({
                  id: $ctrl.blueprintId,
                  skipNotFound: true
                }),
                store.getLightweightGroups()
              ];

              if ($rootScope.userState === USER_STATE.MEMBER) {
                resources.push(StepService.getAllRoles());
              }

              $q.all(resources).then(function (response) {
                $ctrl.process = response[0].data;
                $ctrl.orgGroups = response[3];
                if ($rootScope.userState === USER_STATE.MEMBER) {
                  $ctrl.roles = response[4].data;
                }
                $ctrl.process.steps.data = _.orderBy($ctrl.process.steps.data, 'position');
                $ctrl.process.can_edit_perms = response[2].data;
                $ctrl.process.guidance = ($ctrl.process.guidance) ? $ctrl.process.guidance : null;
                $ctrl.availableUsers = UsersService.getBilledUsers(response[1]);
                $ctrl.havePermissionToRead = isHavePermission();
                parent.addClass('rendered').removeClass('rendering');
                $ctrl.isBusy = false;
                $rootScope.$emit('BLUEPRINT_READ_MODE:RENDERED', { blueprintId: $ctrl.blueprintId });
                defer.resolve();
              }, function (err) {
                if (err.status === 403) {
                  $ctrl.forbiddenAccess = true;
                }
                $ctrl.isBusy = false;
                $rootScope.$emit('BLUEPRINT_READ_MODE:RENDERED', { blueprintId: $ctrl.blueprintId, error: true });
              });
            } else if ($ctrl.userState === USER_STATE.GUEST) {
              $ctrl.isBusy = true;
              PublicLibraryService.getPublicLibraryById({
                org: _.get($rootScope.identity, 'guest.organization.id'),
                guest_code: $stateParams.guest_code,
                checklist_id: $ctrl.blueprintId,
                with: 'steps',
                skipNotFound: true
              }).then(function (res) {
                $ctrl.havePermissionToRead = true;
                $ctrl.process = res.data;
                $ctrl.isBusy = false;
                defer.resolve();
              }, function (err) {
                $ctrl.havePermissionToRead = false;
                $ctrl.isBusy = false;
                defer.reject();
              });
            }
            if ($scope.$parent.froalaTextShorten) {
              defer.promise.then(function () {
                $timeout(function () {
                  $scope.$parent.froalaTextShorten = $element[0].outerHTML;
                }, 0);
              });
            }
            if ($ctrl.expandAll) {
              $ctrl.expanded = true;
            }
          }

          function onChanges() { }

          function onDestroy() { }

          function isHavePermission() {
            var isAdmin = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin"), isLightMember = _.isEqual(_.get($rootScope, 'identity.role'), "light"), havePermissionToEdit, havePermissionToRead;
            if ($ctrl.process.can_edit_perms && !isAdmin) {
              var currentUserID = $rootScope.identity.id;
              havePermissionToEdit = !isLightMember && UtilsService.hasSpecificPermissions(isAdmin, $ctrl.process.created_by, currentUserID, _.get($ctrl.process, 'can_edit_perms.checklist_edit', []), _.get($ctrl.process, 'can_edit_perms.checklist_edit_group', []), $ctrl.orgGroups);
              havePermissionToRead = UtilsService.hasSpecificPermissions(isAdmin, $ctrl.process.created_by, currentUserID, _.get($ctrl.process, 'can_edit_perms.checklist_read', []), _.get($ctrl.process, 'can_edit_perms.checklist_read_group', []), $ctrl.orgGroups);
            }
            return havePermissionToEdit || havePermissionToRead || isAdmin;
          }

          function onPreviewButtonClick() {
            if ($ctrl.expanded) {
              $timeout(function () {
                var el = $element.find('.process-timeline');
                el[0].scrollTo(0, 0);
              }, 10).then(function () {
                $ctrl.expanded = !$ctrl.expanded;
              });
            } else {
              $ctrl.expanded = !$ctrl.expanded;
            }
          }
        }
    });
})();