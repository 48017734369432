
/**
 * @ngdoc Component
 * @name tallyfy.support.component.SupportSingleUserComponent
 * @module tallyfy.support
 *
 * @description
 * A component to support a single user
 *
 * @author Kodeplusdev ( gmail::kodeplusdev@gmail.com, skype::dkhoa81 )
 */

(function () {
  'use strict';

  angular
    .module('tallyfy.support')
    .component('supportSingleUser', {
      bindings: {},
      templateUrl: 'app/modules/support/components/users/single.html',
      controller:
        /*@ngInject*/
        function (_, $stateParams, SupportService, $scope, Auth, $state, blockUI, $uibModal, filterFilter, $translate, $log, TasksService, $confirm, ENV_CONFIG, MOESIF_URL, $filter, growl) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('supportUserDetails'),
            modalInstance;


          /**
           * component callback
           * invoked when component is mounted
           */
          $ctrl.$onInit = initialization;

          /**
           * Bind methods to use in view
           */
          $ctrl.switchUser = switchUser;
          $ctrl.masqueradeUser = masqueradeUser;
          $ctrl.resetPassword = resetPassword;
          $ctrl.openOrganizationModal = openOrganizationModal;
          $ctrl.closeModal = closeModal;
          $ctrl.getOrganizations = getOrganizations;
          $ctrl.addOrganization = addOrganization;
          $ctrl.removeOrganization = removeOrganization;
          $ctrl.unsuspend = unsuspend;
          $ctrl.defaultAvatar = defaultAvatar;
          $ctrl.defaultAvatarText = defaultAvatarText;
          $ctrl.deleteUser = deleteUser;

          /**
           * public properties
           */
          $ctrl.organizations = [];
          $ctrl.selectedOrganization = 0;
          $ctrl.selectedRole = 0;
          $ctrl.userOrganizations = [];
          $ctrl.onOrganizationsLoading = false;

          $ctrl.rolesTypes = [
            { name: $filter('translate')('support.roles.types.admin'), value: 1 },
            { name: $filter('translate')('support.roles.types.standard'), value: 2 },
            { name: $filter('translate')('support.roles.types.light'), value: 4 }
          ];

          $ctrl.orgsParams = {
            page: 1,
            per_page: 100,
            q: '',
            current_page: 1,
            allDataLoaded: false,
            admin: true,
            sort: '-created_at',
            with: 'subscription'
          };

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.moesifURL = _.toLower(ENV_CONFIG.APP_ENV) === 'prod' ? MOESIF_URL.production : MOESIF_URL.staging;
            $ctrl.selectedRole = $ctrl.rolesTypes[0];
            getUserDetails();
            getOrganizations();
          }

          /**
           * @ngdoc ngdoc
           * @name getUserDetails
           * @private
           * @description get user details
           * @returns void
           */
          function getUserDetails() {
            var id = $stateParams.user_id;
            blockUI.start();
            SupportService.getUser({ sub_action_id: id, with: 'organizations' }).then(function (response) {
              $ctrl.user = response.data;
              $ctrl.userOrganizations = _.get(response, 'data.organizations', []);
              blockUI.stop();
            });
          }

          /**
           * @ngdoc ngdoc
           * @name switchUser
           * @private
           * @description disable or enable user in a specific organization
           * @param user
           * @param org
           * @returns void
           */
          function switchUser(user, org) {
            $confirm({
              'header': org.disabled_at ? $filter('translate')('support.modal.userEnable.header') : $filter('translate')('support.modal.userDisable.header'),
              'body': '',
              'buttons': {
                'accept': org.disabled_at ? $filter('translate')('support.users.buttons.enable') : $filter('translate')('support.users.buttons.disable'),
                'cancel': $filter('translate')('support.buttons.cancel')
              },
              'modalType': !org.disabled_at ? 'modal-danger' : angular.noop()
            }).then(function () {
              var requestParams = {
                action: org.id,
                sub_action_id: user.id,
                flag: org.disabled_at ? 1 : 0
              };
              blockUI.start();
              SupportService.switchUser(requestParams).then(function (response) {
                if (response.data.result) {
                  getUserDetails();
                }
                blockUI.stop();
              }, function (error) {
                $log.error(error);
                blockUI.stop();
              });
            });
          }

          /**
           * @ngdoc ngdoc
           * @name masqueradeUser
           * @private
           * @description masquerade a specific user
           * @param user User object
           * @returns void
           */
          function masqueradeUser(user) {
            blockUI.start();
            Auth.masqueradeUser(user).then(function (organization) {
              $state.go('home', { org_id: organization.id }, { reload: true });
            }, function (error) {
              blockUI.stop();
              $log.error(error);
            });
          }

          /**
           * @ngdoc ngdoc
           * @name resetPassword
           * @private
           * @description Reset a user's password
           * @param user
           */
          function resetPassword(user) {
            $confirm({
              'header': 'support.modal.resetPassword.header',
              'body': '',
              'buttons': {
                'accept': 'support.users.buttons.reset_password',
                'cancel': 'global.confirmModal.buttons.cancel'
              },
              modalType: 'modal-confirm-delete-user'
            }).then(function () {
              blockUI.start();
              SupportService.resetPassword({ sub_action_id: user.id }).then(function (resp) {
                if (resp.data) {
                  var info = 'New user password: <b>' + resp.data.new_password + '</b>';
                  growl.success(info, { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                }
                blockUI.stop();
              });
            });
          }

          /**
           * @ngdoc ngdoc
           * @name getOrganizations
           * @private
           * @description get all organizations
           */
          function getOrganizations(arg) {
            var search = _.get(arg, 'query', '');
            if ($ctrl.orgsParams.allDataLoaded && search === $ctrl.orgsParams.q) {
              $ctrl.onOrganizationsLoading = false;
              return;
            }
            if (search){
              $ctrl.orgsParams.page = 1;
              $ctrl.orgsParams.q = search;
            }
            SupportService.getOrganization($ctrl.orgsParams).then(function (result) {
              $ctrl.onOrganizationsLoading = false;
              $ctrl.orgsParams.q = search;
              result.meta.pagination.current_page < result.meta.pagination.total_pages ? $ctrl.orgsParams.page++ : $ctrl.orgsParams.allDataLoaded = true;
              $ctrl.organizations = _.uniqBy(_.concat($ctrl.organizations, result.data), 'id');
              $ctrl.selectedOrganization = $ctrl.organizations[0];
            }, function () {
              $ctrl.onOrganizationsLoading = false;
            });
          }

          /**
           * @ngdoc ngdoc
           * @name openOrganizationModal
           * @private
           * @description open modal to add an organization
           */
          function openOrganizationModal() {
            modalInstance = $uibModal.open({
              templateUrl: 'app/modules/support/components/users/add_organization_modal.html',
              windowClass: 'modal-fade fade add-user-organization',
              backdrop: 'static',
              scope: $scope
            });
            modalInstance.result.then(function (result) {

            });

            modalInstance.opened.then(function () {
              if (!$ctrl.organizations.length) {
                getOrganizations();
              }
            });
          }

          /**
           * @ngdoc ngdoc
           * @name addOrganization
           * @private
           * @description Add a user to selected organization
           * @param user
           */
          function addOrganization(user) {
            if ($ctrl.selectedOrganization) {
              blockUI.start();
              SupportService.addUserToOrganization({
                'org_id': $ctrl.selectedOrganization.id
              }, { 'user_id': user.id, 'role_id': $ctrl.selectedRole.value }).then(function () {
                getUserDetails();
                closeModal();
                blockUI.stop();
              }, function (error) {
                var errorMessage = error.data.message;
                growl.error(errorMessage, { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                blockUI.stop();
              });
            }
          }

          /**
           * @ngdoc ngdoc
           * @name closeModal
           * @private
           * @description Close organization modal
           */
          function closeModal() {
            modalInstance.dismiss();
          }

          /**
           * @ngdoc ngdoc
           * @name removeOrganization
           * @private
           * @param {Object} user 
           * @param {Object} org 
           * @description Remove an user from an organization
           */
          function removeOrganization(user, org) {
            $confirm({
              'header': 'support.modal.deleteUserOrganization.header',
              'body': 'support.modal.deleteUserOrganization.body',
              'buttons': {
                'accept': 'support.users.buttons.deleteUserFromOrg',
                'cancel': 'global.confirmModal.buttons.cancel'
              },
              modalType: 'modal-confirm-delete-user'
            }).then(function () {
              blockUI.start();
              SupportService.removeUserFromOrganization({
                'user_id': user.id,
                'org_id': org.id
              }).then(function () {
                getUserDetails();
                blockUI.stop();
              });
            });
          }

          function unsuspend(user) {
            blockUI.start();
            SupportService.unsuspend(user).then(function (response) {
              blockUI.stop();
              $ctrl.user = response.data;
              growl.success($filter('translate')('support.messages.unsuspend_success'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
            });
          }

          /**
           * @ngdoc method
           * @name defaultAvatar 
           * @public
           * @description set default avatar
           * @param {string} avatar 
           * @return {boolean}
           */
          function defaultAvatar(avatar) {
            return TasksService.setDefaultAvatar(avatar);
          }

          /**
           * @ngdoc method
           * @name defaultAvatarText 
           * @public
           * @description set default avatar Text
           * @param {string} firstname 
           * @param {string} lastname 
           * @return {string} first character of name
           */
          function defaultAvatarText(firstname, lastname) {
            return TasksService.setDefaultAvatarText(firstname, lastname);
          }

          /**
           * @ngdoc method
           * @name deleteUser 
           * @public
           * @description delete user
           * @param {Object} user 
           */
           function deleteUser(user) {
            $confirm({
              'header': 'support.modal.deleteUser.header',
              'body': 'support.modal.deleteUser.body',
              'buttons': {
                'accept': 'support.users.buttons.remove',
                'cancel': 'global.confirmModal.buttons.cancel'
              },
              modalType: 'modal-confirm-delete-user'
            }).then(function () {
              blockUI.start();
              SupportService.deleteUser(user.id).then(function () {
                growl.success($filter('translate')('support.messages.delete_success'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                blockUI.stop();
                $state.go('support');
              }).catch(function (err) {
                growl.error(err.data.message, { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                blockUI.stop();
              });
            });
          }
          //controller ends
        }
    });
})();