/**
 * @ngdoc Directive
 * @name tallyfy.triggerPrint
 * @description 
 * Trigger page print on click
 * @author Rehan Mahmood ( gmail::go4mahmood@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .directive('triggerPrint', triggerPrint);

  /*@ngInject*/
  function triggerPrint($window, $timeout, $rootScope) {
    return {
      restrict: 'A',
      link: function ($scope, $element, attrs) {
        var isTrue = attrs.triggerPrint;
        if (!isTrue) {
          return;
        }
        var $body = angular.element('body'), 
          isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
          mediaQueryList,
          printEventHandler,
          subscribeEventTimeout;
        
        $element.bind('click', function() {
            $body.addClass('print-initiated');
            
            if (isSafari) {
              // Pause the Pubnub subscribe event as it prevents the print dialog from being opened
              $rootScope.$emit('PUBNUB:UNSUBSCRIBE');

              // Print initialized inside of timeout to accommodate any other composite click on the directive / element before the actual print happens.
              $timeout(function () {
                $window.print();
                if ($window.matchMedia) {
                  mediaQueryList = $window.matchMedia('print');
                  printEventHandler = function(e) {
                    if (!e.matches) {
                      $body.removeClass('print-initiated');
                      // Restart the Pubnub subscribe event
                      if (subscribeEventTimeout) {
                        $timeout.cancel(subscribeEventTimeout);
                      }
                      subscribeEventTimeout = $timeout(function () {
                        $rootScope.$emit('PUBNUB:SUBSCRIBE');
                      }, 2000);
                    }
                  };
                  mediaQueryList.addListener(printEventHandler);
                }
              }, 3000);
            } else {
              // Print initialized inside of timeout to accommodate any other composite click on the directive / element before the actual print happens.
              $timeout(function () {
                $window.print();
                $body.removeClass('print-initiated');
              }, 2000);
            }
        });

        $scope.$on('$destroy', function () {
          $element.unbind('click');
          $body.removeClass('print-initiated');
          
          if (mediaQueryList) {
            mediaQueryList.removeListener(printEventHandler);
          }
        });
      }
    };
  }
})();