(function () {
	'use strict';
	angular.module('tallyfy')
		.component('accountDisabled', {
			bindings: {},
			templateUrl: 'app/modules/error/account-disabled/account-disabled.component.html',
			controller:
				/*@ngInject*/
				function ($stateParams, Helper, OrganizationsService, $timeout, $window) {
					var $ctrl = this,
					  windowElem = angular.element($window);

					$ctrl.$onInit = onInit;
					$ctrl.$onChanges = onChanges;
					$ctrl.$onDestroy = onDestroy;

					$ctrl.createNewOrg = Helper.createNewOrg;
					$ctrl.dateFormat = OrganizationsService.getDateFormat();

					// windows resize event handler
					windowElem.on('resize', onWindowResize);

					function onInit() {
						$ctrl.stateParams = $stateParams;
						$timeout(function () {
							onWindowResize();
						}, 1000);
					}

					function onChanges() { }

					function onDestroy() {
						windowElem.off('resize', onWindowResize);
					}

					/**
					 * @ngdoc method
					 * @name onWindowResize
					 * 
					 * @description
					 * on window resize event handler
					 */
					function onWindowResize() {
					    $timeout(function () {
					        var getElement = document.getElementById("accessDisabled");
					        $ctrl.fullScreenClass = windowElem.height() < getElement.clientHeight+200;
					    }, 0);
					}
				}
		});
})();