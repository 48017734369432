/**
 * @ngdoc Component
 * @name tallyfy.support.component.supportView
 * @module tallyfy.support
 *
 * @description
 * A component to manage support view
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */

(function () {
  'use strict';
  angular
    .module('tallyfy.support')
    .component('supportView', {
      templateUrl: 'app/modules/support/components/view/support-view.html',
      controller:
        /*@ngInject*/
        function (_, $stateParams, $state, SupportService, $confirm, CONFIG, growl, blockUI, $filter, $uibModal, ENV_CONFIG, PLANS, Auth, $log, Helper, $rootScope, OrganizationsService) {
          // private properties
          var $ctrl = this,
            blockUI = blockUI.instances.get('supportView'),
            samlConfigPickValues = OrganizationsService.samlConfigPickList();

          // angular controller method lifecycle
          $ctrl.$onInit = initialization;

          // public properties          
          $ctrl.support = {};
          $ctrl.config = CONFIG;
          $ctrl.isLiveSupportEnabled = 0;
          $ctrl.plans = [];
          $ctrl.selectedPlans = [];
          $ctrl.billingInfo = null;
          $ctrl.planFree = PLANS.FREE;
          $ctrl.totalUsers = 0;
          $ctrl.capsuleCRMUrl = ENV_CONFIG.TALLYFY_CAPSULECRM_URL;
          $ctrl.user_org = $stateParams.user_org;
          $ctrl.showEditView = false;
          $ctrl.trialStatusMessage = '';
          $ctrl.cadenceDays = SupportService.getCadenceDays();

          // public methods
          $ctrl.changePlan = changePlan;
          $ctrl.openAddBillingInfoModal = openAddBillingInfoModal;
          $ctrl.extendSubscription = extendSubscription;
          $ctrl.openDatePicker = openDatePicker;
          $ctrl.cancelSubscription = cancelSubscription;
          $ctrl.liveSupport = liveSupport;
          $ctrl.syncRecurlySupport = syncRecurlySupport;
          $ctrl.archiveOrg = archiveOrg;
          $ctrl.updateOrgInfo = updateOrgInfo;
          $ctrl.cancel = cancel;
          $ctrl.updateOrgSetting = updateOrgSetting;
          $ctrl.onTabChanges = onTabChanges;
          $ctrl.openSAMLConfigModal = openSAMLConfigModal;
          $ctrl.toggleSAMLSetting = toggleSAMLSetting;
          $ctrl.onDaySelectedChanged = onDaySelectedChanged;
          $ctrl.updateAutoJoin = updateAutoJoin;
          $ctrl.updateSSOOnly = updateSSOOnly;

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.orgUserTypes = SupportService.getOrgUserTypes();
            $ctrl.activeTab = _.head($ctrl.orgUserTypes);
            getSupport();
            getPlans();
            getSAMLConfig();
            $ctrl.billingSettingOptions = OrganizationsService.billingSettingOptions();
          }

          /**
           * @ngdoc method
           * @name onTabChanged
           * @description Execute when change tab value.
           * @param {object} option
           */
          function onTabChanges(option) {
            $ctrl.activeTab = _.find($ctrl.orgUserTypes, { value: option.value });
            $ctrl.activeTab.class = 'active';
          }

          /**
           * @ngdoc method
           * @name setTrialStatusMsg
           * @description Set trial message and color
           * @returns {void}
           */
          function setTrialStatusMsg() {
            $ctrl.inTrial = ($ctrl.timeDiffInDay <= $ctrl.support.free_trial_days);
            var localKey = $ctrl.timeDiffInDay <= $ctrl.support.free_trial_days ? 'support.recurly.statusInTrial' : 'support.recurly.statusNotInTrial';
            $ctrl.trialStatusMessage = $filter('translate')(localKey, { trialDay: $ctrl.trialDay });
          }

          function getSupport() {
            blockUI.start();
            SupportService.getOrganization({
              org: $stateParams.user_org,
              with: 'subscription,billing_info',
              admin: true
            }).then(function (response) {
              $ctrl.support = _.get(response, 'data', {});
              $ctrl.billingSetting = _.find($ctrl.billingSettingOptions, { value: _.get($ctrl.support,'disable_min_members_billing') });
              calculateFreeTrialDays();
              $ctrl.subscription = _.get($ctrl.support, 'subscription.data', {});
              $ctrl.isLiveSupportEnabled = _.get($ctrl.support, 'live_support_enabled', 0);
              $ctrl.selectedPlans = _.get($ctrl.subscription, 'plan_code');
              $ctrl.isFreePlanCode = $ctrl.selectedPlans === PLANS.FREE;
              $ctrl.support.billing_info ? $ctrl.billingInfo = _.get($ctrl.support, 'billing_info.data') : angular.noop();
              $ctrl.samlEnabled = _.get($ctrl.support, 'saml_enabled', false);
              getDomainList();
              blockUI.stop();
            }, function (error) {
              $log.error(error);
              blockUI.stop();
            });
          }

          function calculateFreeTrialDays() {
            $ctrl.freeTrialDays = angular.copy($ctrl.support.free_trial_days);
            $ctrl.timeDiffInDay = Helper.getDiffInDays($ctrl.support.created_on);
            $ctrl.trialDay = $ctrl.timeDiffInDay > $ctrl.freeTrialDays ? $ctrl.timeDiffInDay - $ctrl.freeTrialDays : $ctrl.freeTrialDays - $ctrl.timeDiffInDay;
            $ctrl.trialEndOn = Helper.getTrialEndDate($ctrl.support.created_on, $ctrl.support.free_trial_days);
            setTrialStatusMsg();
          }

          /**
           * @ngdoc method
           * @param {*} type
           *
           * @description
           * used to archive organization
           */
          function archiveOrg(type) {
            $confirm({
              'header': type === true ? $filter('translate')('support.organization.messages.delete_confirm') : $filter('translate')('support.organization.messages.archive_confirm'),
              'body': $filter('translate')('support.organization.messages.confirmBody', { orgName: $ctrl.support.name, planName: _.get(_.find($ctrl.plans, { code: $ctrl.selectedPlans }), 'name'), totalUsers: $ctrl.totalUsers, activeUsers: $ctrl.support.users_count }),
              'buttons': {
                'accept': type === true ? $filter('translate')('support.buttons.delete') : $filter('translate')('support.buttons.archive'),
                'cancel': $filter('translate')('support.buttons.cancel')
              },
              'modalType': 'modal-danger'
            }).then(function () {
              blockUI.start();
              var params = {
                action: $ctrl.support.id
              };
              type ? angular.extend(params, { sub_action: 'hard' }) : angular.noop();
              SupportService.archiveOrg(params).then(function (response) {
                growl.success(response.message, { referenceId: 'growlNotification', disableIcons: true, disableCloseButton: true });
                blockUI.stop();
                $state.go('support');
              }, function (error) {
                $log.error(error);
                blockUI.stop();
              });
            }, function () {
              $log.info("Archive is cancelled.");
            });
          }

          /**
           * @function
           * @name updateOrgInfo
           * @description update organization info - free trial days.
           * @returns {void}
           */
          function updateOrgInfo() {
            if ($ctrl.support.free_trial_days === $ctrl.freeTrialDays) {
              cancel();
              return;
            }
            blockUI.start();
            SupportService.updateOrgInfo($stateParams.user_org, { name: $ctrl.support.name, free_trial_days: $ctrl.freeTrialDays }).then(function (response) {
              $ctrl.support.free_trial_days = response.data.free_trial_days;
              $ctrl.showEditView = false;
              calculateFreeTrialDays();
              if (_.get($rootScope.identity, 'default_organization.id') === response.data.id) {
                angular.extend($rootScope.identity.default_organization, {
                  free_trial_days: $ctrl.freeTrialDays,
                  in_trial: response.data.in_trial
                });
                $rootScope.$broadcast('UPDATE_FREE_TRIAL_DAYS');
              }
              blockUI.stop();
            }, function (error) {
              $log.error(error);
              blockUI.stop();
            });
          }

          /**
           * @function
           * @name cancel
           * @description cancel changes of free trial days and hide edit mode
           * @returns {void}
           */
          function cancel() {
            $ctrl.showEditView = false;
            $ctrl.freeTrialDays = angular.copy($ctrl.support.free_trial_days);
          }

          /**
           * @ngdoc method
           * @public
           *
           * @description
           * to check live support status of user
           */
          function liveSupport() {
            SupportService.liveSupport($ctrl.support.id, {
              name: $ctrl.support.name,
              live_support_enabled: $ctrl.isLiveSupportEnabled
            }).then(function () {
              growl.success($filter('translate')('support.labels.live_status'), {
                referenceId: 'supportViewController',
                disableIcons: true,
                disableCloseButton: true
              });
            }, function (error) {
              $log.error(error);
            });
          }
          
          /**
           * @ngdoc method
           * @public
           *
           * @description
           * to check sync recurly support status of user
           */
          function syncRecurlySupport() {
            SupportService.syncSubscription($ctrl.support.id, {
              name: $ctrl.support.name,
              sync_recurly_enabled: true
            }).then(function () {
              growl.success($filter('translate')('support.sync_recurly.successMessage'), {
                referenceId: 'supportViewController',
                disableIcons: true,
                disableCloseButton: true
              });
            }, function (error) {
              $log.error(error);
            });
          }

          /**
           * @ngdoc method
           * @name getPlans
           * @description Get all plans
           * @returns {Object} plans
           */
          function getPlans() {
            Auth.getPlans()
              .then(function (response) {
                if (!_.isUndefined(response) && !_.isUndefined(response.PUBLIC_PRICING_PLANS)) {
                  $ctrl.plans = response.PUBLIC_PRICING_PLANS;
                  var currentPlan = _.find($ctrl.plans, { code: $ctrl.selectedPlans });
                  $ctrl.nextAmount = currentPlan ? currentPlan.cost * $ctrl.subscription.quantity : 0;
                }
              }, function (error) {
                $log.info(error);
              });
          }

          /**
           * @ngdoc method
           * @public
           *
           * @name changePlan
           * @param plan_code
           *
           * @description
           * Change to another plan
           */
          function changePlan(plan_code) {
            var data = {
              plan_code: plan_code
            };
            blockUI.start();
            SupportService.changePlan({
              org: $stateParams.user_org
            }, data).then(function () {
              getSupport();
              blockUI.stop();
            }, function (error) {
              $log.info(error);
              growl.error(error.data.message ? error.data.message : $filter('translate')('global.error.500'));
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @public
           *
           * @name openAddBillingInfoModal
           *
           * @description
           * Open Billing info form
           */
          function openAddBillingInfoModal() {
            var modalInstance = $uibModal.open({
              templateUrl: 'app/modules/support/components/view/billing/modal.html',
              windowClass: 'modal-fade fade',
              backdrop: 'static',
              controller: 'RecurlyController as $ctrl',
              resolve: {
                billingInfo: function () {
                  return $ctrl.billingInfo;
                }
              }
            });
            modalInstance.result.then(function (result) {
              uiLoad.unloadScript('https://js.recurly.com/v4/recurly.js');
              result ? getSupport() : angular.noop();
            });
            modalInstance.rendered.then(function () {
              uiLoad.unloadScript('https://js.recurly.com/v4/recurly.js');
              uiLoad
                .loadScript('https://js.recurly.com/v4/recurly.js')
                .then(function () {
                  recurly.configure({
                    publicKey: CONFIG.RECURLY_PUBLICKEY,
                    style: {
                      all: {
                        fontFamily: 'Verdana',
                        fontSize: '14px',
                        fontWeight: 'normal',
                        padding: '8px'
                      }
                    }
                  });
                });
            });
          }

          /**
           * @ngdoc method
           * @public
           *
           * @name extendSubscription
           *
           * @description
           * Extend subscription to a specific date
           */
          function extendSubscription(uuid) {
            var date = $ctrl.subscriptionExtendDate;
            if (!date) {
              return;
            }

            var data = {
              uuid: uuid,
              next_renewal_date: date
            };
            blockUI.start();
            SupportService.extendSubscription($stateParams.user_org, data)
              .then(function () {
                getSupport();
                blockUI.stop();
              }, function (error) {
                $log.info(error);
                blockUI.stop();
              });
          }

          /**
           * @ngdoc method
           * @public
           *
           * @name openDatePicker
           *
           * @description
           * open date picket component
           */
          function openDatePicker() {
            $ctrl.datePicker.isOpened = true;
          }

          /**
           * @ngdoc method
           * @public
           *
           * @name cancelSubscription
           * @param {*} uuid
           *
           * @description
           * cancel subscription
           */
          function cancelSubscription(uuid) {
            $confirm().then(function () {
              blockUI.start();
              SupportService.cancelSubscription($stateParams.user_org, uuid)
                .then(function () {
                  getSupport();
                  blockUI.stop();
                }, function (error) {
                  $log.info(error);
                  blockUI.stop();
                });
            });
          }

          /**
           * @ngdoc method
           * @public
           *
           * @name updateOrgSetting
           *
           * @description
           * update organizations setting
           */
          function updateOrgSetting() {
            $ctrl.support.disable_min_members_billing = _.get($ctrl.billingSetting, 'value');
            blockUI.start();
            SupportService.updateOrgInfo($stateParams.user_org, {
              name: $ctrl.support.name,
              analytics_enabled: _.get($ctrl.support, 'analytics_enabled', false),
              disable_min_members_billing: _.get($ctrl.support, 'disable_min_members_billing', false),
              enable_light_users_billing: _.get($ctrl.support, 'enable_light_users_billing', false),
              limit_trial_features: _.get($ctrl.support, 'limit_trial_features', false),
              use_generative_ai: _.get($ctrl.support, 'use_generative_ai', false),
              real_time_billing: _.get($ctrl.support, 'real_time_billing', true)
            }).then(function () {
              blockUI.stop();
              if (_.get($ctrl.support, 'id') === _.get($rootScope.identity, 'organizations.id')) {
                _.set($rootScope.identity, 'organizations.analytics_enabled', _.get($ctrl.support,'analytics_enabled', false));
                _.set($rootScope.identity, 'organizations.disable_min_members_billing', _.get($ctrl.support, 'disable_min_members_billing', false));
                _.set($rootScope.identity, 'organizations.analytics_enabled', _.get($ctrl.support,'analytics_enabled', false));
              }
              growl.success($filter('translate')('support.orgSettings.updateStatus'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            }, function (error) {
              blockUI.stop();
              $log.error(error);
            });
          }

          /**
           * @ngdoc method
           * @name getSAMLConfig
           * @description Get SAML Config of the Org
           */
          function getSAMLConfig() {
            OrganizationsService.getSAMLConfig({ org: $stateParams.user_org })
              .then(function (response) {
                $ctrl.samlConfig = response.data || {};
                $ctrl.ssoEnabled = !!_.get($ctrl.samlConfig, 'sso_auth_only');
              }, function (error) {
                $log.info(error);
              });
          }

          function updateSSOOnly() {
            if (!$ctrl.samlConfig.id || $ctrl.samlToggleUpdating) {
              return;
            }
            $ctrl.samlConfig.sso_auth_only = $ctrl.ssoEnabled;
            blockUI.start();
            OrganizationsService.createSAMLConfig({ org: $stateParams.user_org }, _.pick($ctrl.samlConfig, samlConfigPickValues))
              .then(function () {
                blockUI.stop();
              }, function (error) {
                blockUI.stop();
                $log.info(error);
              });
          }

          /**
           * @ngdoc method
           * @name openSAMLConfigModal
           * @description To open SAML Config modal
           * @returns void
           */
          function openSAMLConfigModal() {
            $uibModal.open({
              component: 'samlConfigModal',
              windowClass: 'saml-config-modal',
              backdrop: 'static',
              resolve: {
                samlConfig: function () {
                  return $ctrl.samlConfig;
                },
                orgID: function () {
                  return $stateParams.user_org;
                }
              }
            }).result.then(function (newSAMLConfig) {
              if (newSAMLConfig.id) {
                $ctrl.samlConfig = newSAMLConfig;
                growl.success($filter('translate')('support.orgSettings.updateStatus'), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
              }
            }, function () {
              $log.info('Modal is cancelled');
            });
          }

          /**
           * @ngdoc method
           * @public
           *
           * @name toggleSAMLSetting
           *
           * @description
           * Toggle SAML enabled / disabled setting
           */
          function toggleSAMLSetting() {
            var samlEnabled = angular.copy($ctrl.samlEnabled);
            $ctrl.samlToggleUpdating = true;
            SupportService.toggleSAMLSetting({ org: $stateParams.user_org }, { samlEnabled: samlEnabled }).then(function () {
              $ctrl.samlToggleUpdating = false;
              growl.success($filter('translate')('support.orgSettings.updateStatus'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
              $ctrl.support.saml_enabled = samlEnabled;
            }, function (error) {
              $ctrl.samlToggleUpdating = false;
              $log.error(error);
            });
          }

          function onDaySelectedChanged() {
            if (!$ctrl.support.cadence_days.length) {
              $ctrl.support.cadence_days.push('Monday');
            }
            SupportService.setSupportEmailCadence($ctrl.support.id, $ctrl.support.cadence_days)
              .then(function (res) { });
          }

          /**
           * @function
           * @name updateAutoJoin
           * @description update organization info - auto join domain.
           * @returns {void}
           */
          function updateAutoJoin(autoJoinSignupsValue) {
            SupportService.updateOrgInfo($stateParams.user_org, {
              name: $ctrl.support.name,
              auto_join_signups: autoJoinSignupsValue,
            }).then(function () {
              $rootScope.identity.organizations.auto_join_signups = autoJoinSignupsValue;
              growl.success($filter('translate')('support.orgSettings.updateStatus'), {
                referenceId: 'supportViewController',
                disableIcons: true,
                disableCloseButton: true
              });
            }, function (error) {
              $log.error(error);
            });
          }

          /**
           * @function
           * @name getDomainList
           * @description get domain info 
           * @returns {void}
           */
          function getDomainList() {
            var params = {
              org: $ctrl.support.id
            };
            OrganizationsService.domainsData(params).then(function (response) {
              $ctrl.domains = _.get(response, 'data');
            });
          }
        }
    });
})();