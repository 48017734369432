/**
 * @ngdoc Service
 * @name tallyfy.integration.DeveloperApiService
 * @module tallyfy.integration
 * @description DeveloperApiService
 * @author kiran sompura ( gmail::kiranv.sompura@gmail.com )  
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.integration')
    .service('DeveloperApiService', DeveloperApiService);

  /*@ngInject*/
  function DeveloperApiService($filter, $rootScope, _) {
    var self = this;
    /**
     * @ngdoc method
     * @name getIntegrationContent
     * @description Returns integration data
     * @returns {Object}
     */
    self.getIntegrationContent = function () {
      return [{
        id: "native-plugins",
        title: $filter('translate')('integration.nativePlugins.title'),
        subTitle: $filter('translate')('integration.nativePlugins.nativePluginsSubTitle'),
        key: [{
          name: $filter('translate')('integration.nativePlugins.card.slack.name'),
          logo: "assets/images/integrations/slack/slack@2x.png",
          description: $filter('translate')('integration.nativePlugins.card.slack.description'),
          button: $filter('translate')('integration.installButton'),
          link: "https://slack.com/apps/AF15436JJ-tallyfy",
          height: 39
        }, {
          name: $filter('translate')('integration.nativePlugins.card.gmail.name'),
          logo: "assets/images/integrations/gmail/gmail@2x.png",
          description: $filter('translate')('integration.nativePlugins.card.gmail.description'),
          button: $filter('translate')('integration.installButton'),
          link: "https://gsuite.google.com/marketplace/app/tallyfy/671710262095",
          height: 39
        }, {
          name: $filter('translate')('integration.nativePlugins.card.outlook.name'),
          logo: "assets/images/integrations/office365/office365@2x.png",
          description: $filter('translate')('integration.nativePlugins.card.outlook.description'),
          button: $filter('translate')('integration.contactUsButton'),
          height: 40
        }]
      }, {
        id: "ai",
        title: $filter('translate')('integration.ai.title'),
        subTitle: $filter('translate')('integration.ai.aiSubTitle'),
        key: [{
          name: $filter('translate')('integration.ai.card.formsAi.name'),
          description: $filter('translate')('integration.ai.card.formsAi.description'),
          button: $filter('translate')('integration.contactUsButton')
        }, {
          name: $filter('translate')('integration.ai.card.contractsAi.name'),
          description: $filter('translate')('integration.ai.card.contractsAi.description'),
          button: $filter('translate')('integration.contactUsButton')
        }, {
          name: $filter('translate')('integration.ai.card.flowchartsAi.name'),
          description: $filter('translate')('integration.ai.card.flowchartsAi.description'),
          button: $filter('translate')('integration.contactUsButton')
        }, {
          name: $filter('translate')('integration.ai.card.invoicesAi.name'),
          description: $filter('translate')('integration.ai.card.invoicesAi.description'),
          button: $filter('translate')('integration.contactUsButton')
        }, {
          name: $filter('translate')('integration.ai.card.receiptsAi.name'),
          description: $filter('translate')('integration.ai.card.receiptsAi.description'),
          button: $filter('translate')('integration.contactUsButton')
        }, {
          name: $filter('translate')('integration.ai.card.backgroundChecks.name'),
          description: $filter('translate')('integration.ai.card.backgroundChecks.description'),
          button: $filter('translate')('integration.contactUsButton')
        }, {
          name: $filter('translate')('integration.ai.card.lendingAi.name'),
          description: $filter('translate')('integration.ai.card.lendingAi.description'),
          button: $filter('translate')('integration.contactUsButton')
        }, {
          name: $filter('translate')('integration.ai.card.leadAi.name'),
          description: $filter('translate')('integration.ai.card.leadAi.description'),
          button: $filter('translate')('integration.contactUsButton')
        }]
      }, {
        id: "identity-auth",
        title: $filter('translate')('integration.identityAuth.title'),
        subTitle: $filter('translate')('integration.identityAuth.identityAuthSubTitle'),
        key: [{
          name: $filter('translate')('integration.identityAuth.card.saml.name'),
          description: $filter('translate')('integration.identityAuth.card.saml.description'),
          button: $filter('translate')('integration.contactUsButton')
        }, {
          name: $filter('translate')('integration.identityAuth.card.azuread.name'),
          logo: "assets/images/integrations/azure/azure.png",
          description: $filter('translate')('integration.identityAuth.card.azuread.description'),
          button: $filter('translate')('integration.seeMoreButton'),
          link: "https://tallyfy.com/products/pro/integrations/authentication/how-to-integrate-azure-ad-samlsso-with-tallyfy/",
          height: 65
        }, {
          name: $filter('translate')('integration.identityAuth.card.okta.name'),
          logo: "assets/images/integrations/okta/okta@2x.png",
          description: $filter('translate')('integration.identityAuth.card.okta.description'),
          button: $filter('translate')('integration.seeMoreButton'),
          link: "https://tallyfy.com/products/pro/integrations/authentication/how-to-integrate-okta-samlsso-with-tallyfy/",
          height: 38
        }, {
          name: $filter('translate')('integration.identityAuth.card.oneLogin.name'),
          logo: "assets/images/integrations/one-login/oneLogin@2x.png",
          description: $filter('translate')('integration.identityAuth.card.oneLogin.description'),
          button: $filter('translate')('integration.seeMoreButton'),
          link: "https://tallyfy.com/products/pro/integrations/authentication/how-to-integrate-onelogin-samlsso-with-tallyfy/",
          height: 60
        }, {
          name: $filter('translate')('integration.identityAuth.card.gSuite.name'),
          logo: "assets/images/integrations/g-suite/gSuite@2x.png",
          description: $filter('translate')('integration.identityAuth.card.gSuite.description'),
          button: $filter('translate')('integration.seeMoreButton'),
          link: "https://tallyfy.com/products/pro/integrations/authentication/how-to-integrate-google-suite-samlsso-with-tallyfy/",
          height: 50
        }]
      }, {
        id: "rest-api",
        title: $filter('translate')('integration.restApi.title'),
        subTitle: $filter('translate')('integration.restApi.restApiSubTitle'),
        key: [{
          name: $filter('translate')('integration.restApi.card.apikeys.name'),
          description: $filter('translate')('integration.restApi.card.apikeys.description'),
          link: "https://go.tallyfy.com/api/"
        }]
      }, {
        id: "webhooks",
        title: $filter('translate')('integration.webhooks.title'),
        subTitle: $filter('translate')('integration.webhooks.webhooksSubTitle'),
        key: [{
          name: $filter('translate')('integration.webhooks.card.webhooks.name'),
          description: $filter('translate')('integration.webhooks.card.webhooks.description'),
          link: "https://tallyfy.com/products/pro/integrations/webhooks/"
        }]
      }, {
        id: "scheduling",
        title: $filter('translate')('integration.scheduling.title'),
        subTitle: $filter('translate')('integration.scheduling.schedulingSubTitle'),
        key: [{
          name: $filter('translate')('integration.scheduling.card.scheduling.name'),
          logo: "assets/images/integrations/calendly/calendly.png",
          description: $filter('translate')('integration.scheduling.card.scheduling.description'),
          button: $filter('translate')('integration.contactUsButton')
        }]
      }, {
        id: "middleware",
        subTitle: $filter('translate')('integration.middleware.middlewareSubTitle'),
        title: $filter('translate')('integration.middleware.title'),
        key: [{
          name: $filter('translate')('integration.middleware.card.microsoft.name'),
          logo: "assets/images/integrations/microsoft-flow/microsoft-flow@2x.png",
          description: $filter('translate')('integration.middleware.card.microsoft.description'),
          link: "https://tallyfy.com/products/pro/integrations/middleware/power-automate/",
          height: 50
        }, {
          name: $filter('translate')('integration.middleware.card.zapier.name'),
          logo: "assets/images/integrations/zapier/zapier@2x.png",
          description: $filter('translate')('integration.middleware.card.zapier.description'),
          link: "https://tallyfy.com/products/pro/integrations/middleware/zapier/",
          height: 50
        }, {
          id: "salesforce",
          name: $filter('translate')('integration.middleware.card.salesforce.name'),
          logo: "assets/images/integrations/salesforce/salesforce@3x.png",
          description: $filter('translate')('integration.middleware.card.salesforce.description'),
          button: $filter('translate')('integration.middleware.card.salesforce.button'),
          height: 62
        }, {
          name: $filter('translate')('integration.middleware.card.make.name'),
          logo: "assets/images/integrations/integromat/make.png",
          description: $filter('translate')('integration.middleware.card.make.description'),
          link: "https://www.make.com/en/integrations/tallyfy?fromImt=1",
          height: 28
        }, {
          name: $filter('translate')('integration.middleware.card.celigo.name'),
          logo: "assets/images/integrations/integromat/celigo.png",
          description: $filter('translate')('integration.middleware.card.celigo.description'),
          link: "https://docs.celigo.com/hc/en-us/articles/15109930461979",
          height: 50
        },{
          name: $filter('translate')('integration.middleware.card.rpaAi.name'),
          description: $filter('translate')('integration.middleware.card.rpaAi.description'),
          link: "https://tallyfy.com/products/pro/integrations/open-api/how-does-tallyfy-combine-rpa-systems-and-human-operators/"
        }]
      }, {
        id: "analytics",
        title: $filter('translate')('integration.analytics.title'),
        subTitle: $filter('translate')('integration.analytics.analyticsSubTitle'),
        key: [{
          name: $filter('translate')('integration.analytics.card.generalBI.name'),
          description: $filter('translate')('integration.analytics.card.generalBI.description', { 'isAnalyticsEnabled': _.get($rootScope.identity, 'organizations.analytics_enabled', false) }),
          button: $filter('translate')('integration.analytics.card.generalBI.button')
          //          link: "https://tallyfy.com/products/pro/integrations/analytics/"  // TODO May be unhide in future version
        }, {
          name: $filter('translate')('integration.analytics.card.tableau.name'),
          logo: "assets/images/integrations/tableau/tableau@2x.png",
          description: $filter('translate')('integration.analytics.card.tableau.description'),
          link: "https://tallyfy.com/products/pro/integrations/analytics/",
          height: 52
        }, {
          name: $filter('translate')('integration.analytics.card.powerBI.name'),
          logo: "assets/images/integrations/power-BI/power-bi@2x.png",
          description: $filter('translate')('integration.analytics.card.powerBI.description'),
          link: "https://tallyfy.com/products/pro/integrations/analytics/",
          height: 51
        }, {
          id: "ga_id",
          name: $filter('translate')('integration.analytics.card.googleAnalytics.name'),
          logo: "assets/images/integrations/google-analytics/ga-logo@2x.png",
          description: $filter('translate')('integration.analytics.card.googleAnalytics.description'),
          height: 30
        }, {
          id: "mixpanel_token",
          name: $filter('translate')('integration.analytics.card.mixpanel.name'),
          logo: "assets/images/mixpanel.png",
          description: $filter('translate')('integration.analytics.card.mixpanel.description'),
          height: 42
        }]
      }, {
        id: "translation",
        title: $filter('translate')('integration.translation.title'),
        subTitle: $filter('translate')('integration.translation.translationSubTitle'),
        key: [{
          id: 'azure-cognitive-service',
          name: 'Azure',
          description: 'Translate languages in real-time.',
          logo: "assets/images/cognitive-service.png",
          height: 42
        }]
      }, {
        id: "open-source",
        title: $filter('translate')('integration.openSource.title'),
        subTitle: $filter('translate')('integration.openSource.openSourceSubTitle'),
        key: [{
          name: $filter('translate')('integration.openSource.card.powershell.name'),
          description: $filter('translate')('integration.openSource.card.powershell.description'),
          link: "https://github.com/mattgrif/TallyFy-API-PowerShell-Module"
        }, {
          name: $filter('translate')('integration.openSource.card.tallyfySupport.name'),
          description: $filter('translate')('integration.openSource.card.tallyfySupport.description'),
          link: "https://github.com/tallyfy/api-support"
        }]
      }, {
        id: "magic-link",
        title: $filter('translate')('integration.magicLink.title'),
        subTitle: $filter('translate')('integration.magicLink.magicLinkSubTitle'),
        key: [{
          id: "magic-link",
          name: $filter('translate')('integration.magicLink.card.title'),
          description: $filter('translate')('integration.magicLink.card.description')
        }]
      }, {
        id: "import-export",
        title: $filter('translate')('integration.importExport.title'),
        subTitle: $filter('translate')('integration.importExport.importExportSubTitle'),
        key: [{
          id: "import-export",
          name: $filter('translate')('integration.importExport.card.title'),
          description: $filter('translate')('integration.importExport.card.description')
        }]
      }];
    };

    /**
     * @ngdoc method
     * @name getIntegrations
     * @description Get integrations
     * @returns {Object}
     */
    self.getIntegrations = function () {
      return [{
        id: '#native-plugins', title: $filter('translate')('integration.nativePlugins.title')
      }, {
        id: '#ai', title: $filter('translate')('integration.ai.title')
      }, {
        id: '#identity-auth', title: $filter('translate')('integration.identityAuth.title')
      }, {
        id: '#rest-api', title: $filter('translate')('integration.restApi.title')
      }, {
        id: '#webhooks', title: $filter('translate')('integration.webhooks.title')
      }, {
        id: '#scheduling', title: $filter('translate')('integration.scheduling.title')
      }, {
        id: '#middleware', title: $filter('translate')('integration.middleware.title')
      }, {
        id: '#analytics', title: $filter('translate')('integration.analytics.title')
      }, {
        id: '#translation', title: $filter('translate')('integration.translation.title')
      }, {
        id: '#open-source', title: $filter('translate')('integration.openSource.title')
      }, {
        id: '#magic-link', title: $filter('translate')('integration.magicLink.title')
      }, {
        id: '#import-export', title: $filter('translate')('integration.importExport.title')
      }];
    };

    /**
     * @ngdoc method
     * @name getCompliancesContent
     * @description Returns compliance data
     * @returns {Object}
     */
    self.getCompliancesContent = function () {
      return [{
        id: "privacy",
        title: $filter('translate')('compliance.privacy.title'),
        key: [{
          name: $filter('translate')('compliance.privacy.card.dpaEU.title'),
          description: $filter('translate')('compliance.privacy.card.dpaEU.description'),
          button: $filter('translate')('integration.contactUsButton')

        }, {
          name: $filter('translate')('compliance.privacy.card.dpaUK.title'),
          description: $filter('translate')('compliance.privacy.card.dpaUK.description'),
          button: $filter('translate')('integration.contactUsButton')
        }]
      }, {
        id: "enterprise",
        title: $filter('translate')('compliance.enterprise.title'),
        key: [{
          name: $filter('translate')('compliance.enterprise.card.dataResidency.title'),
          description: $filter('translate')('compliance.enterprise.card.dataResidency.description'),
          button: $filter('translate')('integration.contactUsButton')
        }, {
          name: $filter('translate')('compliance.enterprise.card.SOC2.title'),
          description: $filter('translate')('compliance.enterprise.card.SOC2.description'),
          button: $filter('translate')('integration.contactUsButton')
        }, {
          name: $filter('translate')('compliance.enterprise.card.logging.title'),
          description: $filter('translate')('compliance.enterprise.card.logging.description'),
          button: $filter('translate')('integration.contactUsButton')
        }, {
          name: $filter('translate')('compliance.enterprise.card.customValidation.title'),
          description: $filter('translate')('compliance.enterprise.card.customValidation.description'),
          button: $filter('translate')('integration.contactUsButton')
        }, {
          name: $filter('translate')('compliance.enterprise.card.immutableStreams.title'),
          description: $filter('translate')('compliance.enterprise.card.immutableStreams.description'),
          button: $filter('translate')('integration.contactUsButton')
        }, {
          name: $filter('translate')('compliance.enterprise.card.cfr.title'),
          description: $filter('translate')('compliance.enterprise.card.cfr.description'),
          button: $filter('translate')('integration.contactUsButton')
        }, {
          name: $filter('translate')('compliance.enterprise.card.vpat.title'),
          description: $filter('translate')('compliance.enterprise.card.vpat.description'),
          button: $filter('translate')('integration.contactUsButton')
        }, {
          name: $filter('translate')('compliance.enterprise.card.bimi.title'),
          description: $filter('translate')('compliance.enterprise.card.bimi.description'),
          button: $filter('translate')('integration.viewStatus'),
          link: "https://www.mailhardener.com/tools/bimi-validator?domain=tallyfy.com",
        }, {
          name: $filter('translate')('compliance.enterprise.card.insurance.title'),
          description: $filter('translate')('compliance.enterprise.card.insurance.description'),
          button: $filter('translate')('integration.contactUsButton')
        }, {
          name: $filter('translate')('compliance.enterprise.card.slaAPI.title'),
          description: $filter('translate')('compliance.enterprise.card.slaAPI.description'),
          button: $filter('translate')('integration.viewAPIStatus'),
          link: "https://api-status.tallyfy.com/",
        }, {
          name: $filter('translate')('compliance.enterprise.card.slaClient.title'),
          description: $filter('translate')('compliance.enterprise.card.slaClient.description'),
          button: $filter('translate')('integration.viewClientStatus'),
          link: "https://client-status.tallyfy.com/",
        }]
      }];
    };

    /**
     * @ngdoc method
     * @name getCompliances
     * @description Get compliances
     * @returns {Object}
     */
    self.getCompliances = function () {
      return [{
        id: '#privacy', title: $filter('translate')('compliance.privacy.title')
      }, {
        id: '#enterprise', title: $filter('translate')('compliance.enterprise.title')
      }];
    };
  }
})();