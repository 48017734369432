(function () {
  'use strict';
  angular.module('tallyfy.process')
    .component('automationComponent', {
      bindings: {
        resolve: '<',
        asModal: '<',
        process: '<',
        close: '&'
      },
      templateUrl: 'app/modules/process/components/edit/automation/automation.component.html',
      controller:
        /*@ngInject*/
        function (_, $q, $filter, $confirm, $state, $stateParams, UsersService, ProcessService, store, Helper, COMMON, ENV_CONFIG, $uibModal) {
          var $ctrl = this;

          $ctrl.automationTypes = ProcessService.getAutomationTypes();

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.addNewAutomation = addNewAutomation;
          $ctrl.automationTabClick = automationTabClick;
          $ctrl.onAutomationDeleted = onAutomationDeleted;
          $ctrl.copyAutomationLink = copyAutomationLink;
          $ctrl.onWatchDiscoverAutomationsModal = onWatchDiscoverAutomationsModal;
          $ctrl.closeModal = closeModal;

          $ctrl.selectedIndex = 0;

          $ctrl.automationActionForm = {};

          $ctrl.formValid = true;

          function onInit() {
            if ($ctrl.resolve) {
              $ctrl.editId = $ctrl.resolve.editId;
              $ctrl.process = $ctrl.resolve.process;
              $ctrl.singleEdit = true;
            }
            $ctrl.maxTitleLength = COMMON.MAX_AUTOMATION_NAME;
            $ctrl.tabView = 'automations';
            $ctrl.steps = $ctrl.process.steps.data;
            $ctrl.automations = $ctrl.editId ? _.filter($ctrl.process.automated_actions, { id: $ctrl.editId }) : $ctrl.process.automated_actions;
            $q.all([
              store.getUsers(),
              store.getLightweightGroups(),
              store.getLightweightGuests()
            ]).then(function (res) {
              $ctrl.usersInOrg = UsersService.getBilledUsers(res[0]);
              $ctrl.groupsInOrg = res[1];
              _.forEach(res[2], function (guest, key) {
                angular.extend(guest, { 'id': guest.email, 'text': guest.email, 'type': 'guest' });
              });
              $ctrl.guestsInOrg = res[2];
              if ($stateParams.automationId) {
                var selectedIndex = _.findIndex(_.orderBy($ctrl.automations, 'created_at'), { id: $stateParams.automationId });
                if (selectedIndex >= 0) {
                  $ctrl.selectedIndex = selectedIndex;
                }
              }
            })
          }

          function onChanges() { }

          function onDestroy() { }

          function addNewAutomation() {
            $ctrl.automations.push({
              localId: Helper.getId(),
              conditions: [{
                localId: Helper.getId(),
                conditionable_id: '',
                conditionable_type: '',
                operation: '',
                statement: '',
                logic: 'or',
                position: 0,
                column_contains_name: ''
              }],
              then_actions: [{
                localId: Helper.getId(),
                action_type: '',
                action_verb: '',
                target_step_id: '',
                actionable_id: '',
                actionable_type: ''
              }]
            });
            $ctrl.selectedIndex = $ctrl.automations.length - 1;
          }

          function automationTabClick() {
            $ctrl.tabView = 'automations';
          }

          function onAutomationDeleted(automation) {
            $confirm({
              'header': $filter('translate')('process.automatedAction.deleteAutomationModal.header'),
              'body': $filter('translate')('process.automatedAction.deleteAutomationModal.body'),
              'buttons': {
                'accept': $filter('translate')('global.confirmAction.actionDelete'),
                'cancel': 'global.confirmAction.buttons.cancel'
              },
              modalType: 'modal-danger'
            }).then(function () {
              if (automation.id) {
                _.remove($ctrl.automations, { id: automation.id });
                ProcessService.deleteAutomationAction($ctrl.process.id, automation.id)
                  .then(function (res) {
                    Helper.showChangesSavedGrowl();
                  });
              } else {
                _.remove($ctrl.automations, { localId: automation.localId });
              }
              $ctrl.selectedIndex = 0;
            });

          }

          function copyAutomationLink(e, automation) {
            var text = ENV_CONFIG.TYFY_MAIN_URL + $state.href('process.edit', { slug: $ctrl.process.id, view: 'automations', automationId: automation.id });
            Helper.copyToClipboard(text, 'automationCopied', e);
          }

          function closeModal() {
            $ctrl.close();
          }

          /**
           * @ngdoc method
           * @name onWatchDiscoverAutomationsModal
           * @description view discover automations modal
           */
          function onWatchDiscoverAutomationsModal() {
            $uibModal.open({
              component: 'discoverAutomationsModal',
              windowClass: 'discover-automations-modal'
            });
          }
        }
    });
})();