/**
 * @ngdoc Service
 * @name BeaconService
 *
 * @module tallyfy
 *
 * @description
 * Beacon service
 *
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm )
 */
(function () {
  'use strict';

  angular.module('tallyfy')
    .service('BeaconService', beaconService);

  /*@ngInject*/
  function beaconService(_, $rootScope, DOMService) {
    var HS_KEY = '3ba9841b-7d00-48dc-a958-87dd555ba2db';
    var self = this,
      isOpen = false,
      isInitialized = false;

    /**
     * @ngdoc method
     * @private
     * 
     * @name _initializeHSBeaconPopup
     * 
     * @description
     * initialize HS object
     */
    function _initializeHSBeaconPopup(color) {
      if (!window.Beacon) return;
      var beacon = window.Beacon('info')
      if (_.get(beacon, 'beaconId')) {
        return;
      }
      window.Beacon('init', HS_KEY);
      window.Beacon('config', {
        color: color ? color : '#3fb65b',
        messagingEnabled: true
      });

      if (!isInitialized) {
        isInitialized = true;
      }
    }

    /**
     * @ngdoc method
     * @private
     * 
     * @name _initializeHSBeaconEvent
     * 
     * @description
     * initialize HS events
     */
    function _initializeHSBeaconEvent() {
      if (!window.Beacon) return;

      window.Beacon('on', 'close', function () {
        isOpen = false;
        DOMService.toggleHelpScoutVisibility(true);
        $rootScope.$emit('BEACON:CLOSE');
      });

      window.Beacon('on', 'open', function () {
        isOpen = true;
        DOMService.toggleHelpScoutVisibility(false);
        $rootScope.$emit('BEACON:OPEN');
      });
    }

    // entry point method for entire HS in app
    self.initializeHSBeacon = function (color) {
      if (!window.Beacon) return;
      _initializeHSBeaconPopup(color);
      _initializeHSBeaconEvent();
      isOpen = false;
      DOMService.toggleHelpScoutVisibility(true);
    };

    // identity setter
    self.setIdentity = function (identity) {
      if (!window.Beacon) return;
      window.Beacon('identify', identity);
    };

    // re-render HS object in app if object was destroyed in fly
    self.reRenderHSBeaconPopup = function () {
      if (!window.Beacon) return;
      window.Beacon('init', HS_KEY);
    };

    // open HS popup
    self.open = function () {
      if (!window.Beacon || isOpen) return;
      window.Beacon('open');
      DOMService.toggleHelpScoutVisibility(false);
    };

    // close HS popup
    self.close = function () {
      if (!window.Beacon || !isOpen) return;
      window.Beacon('close');
      DOMService.toggleHelpScoutVisibility(true);
    };

    // toggle HS popup
    self.togglePopup = function (_isOpen) {
      if (!window.Beacon) return;
      isOpen = typeof _isOpen !== 'undefined' ? _isOpen : !isOpen;
      window.Beacon(isOpen ? 'open' : 'close');
      DOMService.toggleHelpScoutVisibility(!isOpen);
    };

    // set prefill with subject
    self.prefill = function (subject) {
      if (!window.Beacon) return;
      window.Beacon('prefill', { subject: subject });
    };

    // HS user logout and clear user credential
    self.logout = function () {
      if (!window.Beacon) return;
      window.Beacon('logout');
    };

    // destroy HS popup from app
    self.destroy = function () {
      if (!window.Beacon) return;
      window.Beacon('destroy');
      isInitialized = false;
      isOpen = false;
    };

    // getter HS status - call this method to get the current status of HS DOM object
    self.getStatus = function () {
      if (!window.Beacon) return;
      var beacon = window.Beacon('info');
      return {
        isInitialized: !!_.get(beacon, 'beaconId'),
        isOpen: _.get(beacon, 'status.isOpened')
      };
    };

    $rootScope.$on('CHATLIO:EXPANDED', function () {
      self.close();
      DOMService.toggleHelpScoutVisibility(true);
    });

    return self;
  }

})();