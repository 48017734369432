/**
 * @ngdoc Component
 * @name tallyfy.settings.manage
 * @module tallyfy.settings
 *
 * @description
 * A manage component manages current account
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.settings')
    .component('manage', {
      template: '<div ng-include="$ctrl.templateUrl"></div>',
      controller:
        /*@ngInject*/
        function ManageController($q, UsersService, $rootScope, _, store, TagsService, StepService, USER_STATE, $state, $filter, $window, $scope, $timeout, AccountService) {
          var $ctrl = this, countUpdateEventHandler, userDeleteEventHandler,
          userInviteEventHandler, windowElem = angular.element($window);
          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;
          $ctrl.openInviteModal = UsersService.openInviteModal;
          $ctrl.getSelectedMenu = getSelectedMenu;
          $ctrl.toggleSettingsMenu = toggleSettingsMenu;
          $ctrl.checkSettingsMenuState = checkSettingsMenuState;


          windowElem.on('resize', onWindowResize);

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            onWindowResize();
            $ctrl.organization = _.get($rootScope.identity, 'default_organization', {});
            $ctrl.isGuestUrl = ($rootScope.userState === USER_STATE.GUEST);
            $ctrl.templateUrl = $ctrl.isGuestUrl ? 'app/modules/manage/guest-manage.html' : 'app/modules/manage/manage.html';
            if ($ctrl.isGuestUrl) {
              return;
            }
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            $ctrl.allow_user_invite = _.get($rootScope, 'orgSamlInfo.allow_user_invite');
            $ctrl.autoGenerated = AccountService.getPreference($rootScope.identity.preferences, 'auto_generated_filter', 'no');
            $q.all([
              store.getUsers(),
              store.getLightweightGuests(),
              TagsService.get({
                action: 'tags',
                page: 1,
                per_page: 1,
                with: 'statistics',
                auto_generated: $ctrl.autoGenerated === 'no' ? false : null
              }),
              store.getGroups(),
              StepService.getAllRoles()
            ]).then(function (data) {
              $ctrl.counts = {
                users: UsersService.usersWithoutBot(data[0]).length,
                guests: data[1].length,
                tags: _.get(data, '[2].meta.pagination.total', 0),
                groups: data[3].length,
                roles: data[4].data.length
              };
              if ($window.innerWidth < 1200) {
                getSelectedMenu();
              }
            });
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }
          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            countUpdateEventHandler();
            userInviteEventHandler();
            userDeleteEventHandler();
            windowElem.off('resize', onWindowResize);
          }

          //Window resize handler
          function onWindowResize () {
            $ctrl.showSettingsMenu = !($window.innerWidth < 1200);
            $ctrl.isMobile = !!($window.innerWidth < 1200);
            $timeout(function () {
              $scope.$apply();
            });
          }

          //Get current selected menu from state
          function getSelectedMenu () {
            switch ($state.current.name) {
              case 'settings.me.update':
                return $filter('translate')('myAccount.tabs.my_account') + ' > ' + $filter('translate')('myAccount.tabs.my_profile');
              case 'settings.me.favorites':
                return $filter('translate')('myAccount.tabs.my_account') + ' > ' + $filter('translate')('myAccount.tabs.favorites');
              case 'settings.me.personalize':
                return $filter('translate')('myAccount.tabs.my_account') + ' > ' + $filter('translate')('myAccount.tabs.personalization');
              case 'settings.me.preferences':
                return $filter('translate')('myAccount.tabs.my_account') + ' > ' + $filter('translate')('myAccount.tabs.email_notifications');
              case 'settings.org.updateOrgProfile':
                return $filter('translate')('myAccount.tabs.my_organization') + ' > ' + $filter('translate')('my_org_tabs.organization_profile');
              case 'settings.org.customize':
                return $filter('translate')('myAccount.tabs.my_organization') + ' > ' + $filter('translate')('myAccount.tabs.customization');
              case 'settings.org.usersRegular':
                return $filter('translate')('myAccount.tabs.my_organization') + ' > ' + $filter('translate')('my_org_tabs.coworkers', { totalMembers: $ctrl.counts.users || 0 });
              case 'settings.org.orgGuestUsers':
                return $filter('translate')('myAccount.tabs.my_organization') + ' > ' + $filter('translate')('my_org_tabs.guests', { totalGuests: $ctrl.counts.guests || 0 });
              case 'settings.org.groups':
                return $filter('translate')('myAccount.tabs.my_organization') + ' > ' + $filter('translate')('my_org_tabs.groups', { totalGroups: $ctrl.counts.groups || 0 });
              case 'settings.org.roles':
                return $filter('translate')('myAccount.tabs.my_organization') + ' > ' + $filter('translate')('my_org_tabs.roles', { totalRoles: $ctrl.counts.roles || 0 });
              case 'settings.org.tags':
                return $filter('translate')('myAccount.tabs.my_organization') + ' > ' + $filter('translate')('my_org_tabs.tags', { totalTags: $ctrl.counts.tags || 0 });
              case 'settings.org.branding':
                return $filter('translate')('myAccount.tabs.my_organization') + ' > ' + $filter('translate')('my_org_tabs.customBranding');
              case 'settings.org.email':
                return $filter('translate')('myAccount.tabs.my_organization') + ' > ' + $filter('translate')('my_org_tabs.emailSetting');
              case 'settings.org.textTemplate':
                return $filter('translate')('myAccount.tabs.my_organization') + ' > ' + $filter('translate')('my_org_tabs.textTemplate');
              case 'settings.org.personalization':
                return $filter('translate')('myAccount.tabs.my_organization') + ' > ' + $filter('translate')('my_org_tabs.personalization');
              case 'settings.org.domainOrg':
                return $filter('translate')('myAccount.tabs.my_organization') + ' > ' + $filter('translate')('my_org_tabs.domains');
              case 'settings.billing':
                return $filter('translate')('myAccount.tabs.billing');
              case 'settings.integration':
                return $filter('translate')('myAccount.tabs.integrations');
              case 'settings.compliance':
                return $filter('translate')('myAccount.tabs.compliance');
              default:
                return '';
            }
          }

          //Toggle settings menu
          function toggleSettingsMenu () {
            $ctrl.showSettingsMenu = !$ctrl.showSettingsMenu;
          }

          //Check settings menu state
          function checkSettingsMenuState () {
            return ((!$ctrl.isMobile) || ($ctrl.isMobile && $ctrl.showSettingsMenu));
          }

          countUpdateEventHandler = $rootScope.$on('COUNT_UPDATE', function(e, data) {
            _.set($ctrl.counts, data.type, data.count);
          });

          userInviteEventHandler = $rootScope.$on('USER:INVITED', function(e, data) {
            $ctrl.counts.users = $ctrl.counts.users + 1;
          });

          userDeleteEventHandler = $rootScope.$on('USER:DELETED', function(e, data) {
            $ctrl.counts.users = $ctrl.counts.users - 1;
          });
          //controller ends
        }
    });
})();