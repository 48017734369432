(function () {
  angular.module('tallyfy.pubnub', [])
    .provider('Pubnub',
      /*@ngInject*/
      function () {
        var self = this,
          _pubnub = null;
        self.$get =
          /*@ngInject*/
          function ($rootScope, $q, _) {
            var processMessageAndEmit = function (m) {
              var channelName = _.get(m, 'channel', '');
              if (channelName.includes('.')) {
                var getEmitValidityAndNameObj = getEmitValidityAndName(channelName);
                if (getEmitValidityAndNameObj.isValid) {
                  $rootScope.$emit('PN_LISTENING:' + getEmitValidityAndNameObj.name, _.get(m, 'message', {}));
                }
              }
            };
            
            var getEmitValidityAndName = function (channelName) {
              var validityAndNameObj = {
                  isValid: false,
                  name: ''
                },
                resultArr = channelName.split('.');
              if (resultArr[1].includes('sync_data')) {
                validityAndNameObj.isValid = true;
                validityAndNameObj.name = 'sync_data';
              } else if (resultArr[1].includes('run_')) {
                validityAndNameObj.isValid = true;
                validityAndNameObj.name = 'run';
              } else if (resultArr[1].includes('user_') && resultArr[1].includes('_notifications')) {
                validityAndNameObj.isValid = true;
                validityAndNameObj.name = 'user_notifications';
              }
              return validityAndNameObj;
            };
            return {
              init: function (config) {
                if (!window.PubNub) {
                  return;
                }
                if (!config) {
                  return;
                }
                _pubnub = new window.PubNub(config);
                _pubnub.addListener({
                  message: function (m) {
                    processMessageAndEmit(m);
                  }
                });
              },
              history: function (args) {
                var defer = $q.defer();
                if (!_pubnub) {
                  defer.resolve([]);
                } else {
                  _pubnub.fetchMessages({
                    channels: [args.channel],
                    count: args.count
                  }).then(function (res) {
                    defer.resolve(res.channels[args.channel]);
                  });
                }
                return defer.promise;
              },
              subscribe: function (args) {
                if (!_pubnub) {
                  return;
                }
                _pubnub.subscribe(args);
              },
              publish: function (args) {
                var defer = $q.defer();
                if (!_pubnub) {
                  defer.resolve({});
                } else {
                  _pubnub.publish({
                    channel: args.channel,
                    message: args.message
                  }).then(function (response) {
                    defer.resolve(response);
                  });
                }
                return defer.promise;
              },
              setUserId: function (userID) {
                if (!_pubnub) {
                  return;
                }
                _pubnub.setUserId(userID);
              },
              unsubscribeAll: function () {
                if (!_pubnub) {
                  return;
                }
                _pubnub.unsubscribeAll();
              }
            };
          };
      });
})();