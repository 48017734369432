/**
 * @ngdoc Service
 * @name tallyfy.filters.FilterModalService
 *
 * @module tallyfy.filters
 *
 * @description
 * FilterModalService
 *
 * @author kiran sompura ( gmail::kiranv.sompura@gmail.com )  
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.filters')
    .service('FilterModalService', FilterModalService);

  FilterModalService.$inject = ['_', 'store', 'OrganizationsRepository', '$uibModal'];

  function FilterModalService( _, store, OrganizationsRepository, $uibModal) {
    
    var self = this;
    self.callback= function(){};
    angular.extend(self, OrganizationsRepository);
    
    self.getFilterTypeValuesBySlug = function (organizationId, filterType, userID, callback) {
      self.callback = callback;
      
      // if sortFields is not available then sort by labelField
      var sortBy = filterType.sortFields ? filterType.sortFields : filterType.labelField;
      // only fetching data that needed(i.e. idField, labelField, labelOptionalField)
      var requiredFields = _.compact([filterType.idField, filterType.labelField, filterType.labelOptionalField]);
      if (filterType.slug === 'users') {
        requiredFields.push('type');
      }
      var requestParam = {
        org: organizationId,
        slug: filterType.slug,
        per_page: filterType.per_page,
        page: filterType.page,
        q: filterType.search,
        sort: sortBy,
        fields: _.join(requiredFields)
      };
      if (filterType.slug === 'users') {
        store.getUsers().then(successCallback, errorCallback);
      } else {
        OrganizationsRepository.getByType(requestParam).then(successCallback, errorCallback);
      }
      function successCallback(response) {
        if(filterType.slug === 'tags') {
          var tags = _.filter(response.data, function (tag) {
            tag.title = tag.title.replaceAll(/#/g,'');
            return !(_.startsWith(tag.title, 'task:') || _.startsWith(tag.title, 'from:') || _.startsWith(tag.title, 'step:'));
          });
          angular.copy(tags, response.data);
        }
        if(filterType.slug === 'users') {
          response = { data: response };
          _.remove(response.data, { type : 'bot' });
        }
        return populateAllData(response, callback, filterType, userID);
      }
    };
    
    function errorCallback(error) {
      return self.callback({'error': true, 'data': error});
    }
    
    function populateAllData(response, callback, filterType, userID) {
      var arrayData = [];
      _.forEach(response.data, function (val) {
        (filterType.id === 'owners' && val[filterType.idField] === userID)
         ? arrayData.unshift({id: val[filterType.idField], label: 'Me'}) 
         : arrayData.push({id: val[filterType.idField], label: _.trim(val[filterType.labelField]) === "" ? val[filterType.labelOptionalField] : val[filterType.labelField] });
      });
      return callback({ 'error': false, 'data': arrayData, 'meta':response.meta });
    }

    // Transform filter data
    self.openFilterModal = function (filter, filterFor, filterTypes) {
      var options = {
        filter: filter, // For current filter 
        filterFor: filterFor, // For  ex run,task etc
        filterTypes: filterTypes // For filter type ex owners,process..
      };
      return self.openModal(options);
    };

    // Open filter modal
    self.openModal = function (options) {
     return $uibModal.open({
        component: 'filtersModal',
        windowClass: 'filter-modal',
        resolve: {
          filterData: function () {
            return options;
          }
        }
      });
    };
  }
})();