(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('passwordConfirmModal', {
      templateUrl: 'app/modules/account/updateAccount/password-confirm-modal/password-confirm-modal.component.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (_, AccountService) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          $ctrl.confirm = confirm;
          $ctrl.cancel = cancel;

          function onInit() { }

          function onDestroy() { }

          function onChanges() { }

          function confirm() {
            $ctrl.saving = true;
            if ($ctrl.resolve.user) {
              AccountService.updateUserEmail($ctrl.resolve.user.id, $ctrl.resolve.user.email, $ctrl.password)
                .then(function () {
                  $ctrl.close({ $value: true });
                }, function (err) {
                  if (err.status === 422) {
                    $ctrl.errorMessages = _.concat(err.data.errors.email, err.data.errors.password);
                  }
                  $ctrl.saving = false;
                }, function () {
                  $ctrl.saving = false;
                })
            } else {
              AccountService.updateMyEmail($ctrl.resolve.email, $ctrl.password)
                .then(function () {
                  $ctrl.close({ $value: true });
                }, function (err) {
                  if (err.status === 422) {
                    $ctrl.errorMessages = _.concat(err.data.errors.email, err.data.errors.password);
                  }
                  $ctrl.saving = false;
                }, function () {
                  $ctrl.saving = false;
                })
            }
          }

          function cancel() {
            $ctrl.close({ $value: false });
          }

        }
    });
})();