/**
 * @ngdoc Component
 * @name tallyfy.steps.component.renderForm
 * @module tallyfy.steps
 *
 * @renderForm
 * A component to render form with available capture field
 *
 * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.steps')
    .component('renderForm', {
      bindings: {
        fields: '=?',
        onFieldUpdate: '&',
        fieldData: '<',
        fieldClass: '@',
        task: '<?',
        isDisabled: '<?',
        isReadonly: '<?',
        processView: '@?',
        formSubmitted: '<',
        onSaving: '<',
        isCompletableTask: '<?',
        errorFields: '<',
        isPrerun: '<',
        isGuest: '<',
        isPublicProcess: '<',
        isOnOffTask: '<?',
        hideFieldLabel: '<?',
        hideNoInfoData: '<',
        fieldAssignee: '=',
        koTasks: '=',
        users: '<',
        groups: '<',
        showAssignee: '<',
        readOnlyAssignee: '<',
        isApproval: '<?',
        translateEnable: '<?',
        asDefaultValue: '<',
        disableCompletion: '=?',
        isNewField: '<',
        disableAutoSizeTableColumn: "<",
        process: '<?',
        isOneOfTaskForms: '<?',
      },
      templateUrl: 'app/modules/steps/capture/form/render/render-form.html',
      controller:
        /*@ngInject*/
        function (_, $rootScope, $filter, FieldService, Helper, COMMON, $scope) {
          var $ctrl = this,
            fieldsUpdateWatcherHandler;
          /**
          * component's lifeCycle hooks
          */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public properties
           */
          $ctrl.validationErrors = {};
          $ctrl.isEditMode = [];
          $ctrl.requiredFieldNote = [];

          /**
           * public methods
           */
          $ctrl.updateFieldValue = updateFieldValue;
          $ctrl.titleClick = titleClick;

          /**
           * @function
           * @name initialization
           * @viewForm
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.fields = omitByType(['hotdocs']);
            $ctrl.organization = $ctrl.isPublicProcess
              ? $rootScope.publicOrganization : (
                $ctrl.isGuest ? $rootScope.identity.guest.organization
                  : $rootScope.identity.default_organization
              );
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(bindings) {
            if (bindings.formSubmitted && bindings.formSubmitted.currentValue) {
              validateFormFields();
            }
            if (bindings.errorFields && bindings.errorFields.currentValue) {
              validateFormFields(true);
            }
          }
          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            fieldsUpdateWatcherHandler();
          }

          function updateFieldValue(field) {
            $ctrl.onFieldUpdate({ field: field });
          }

          function titleClick(field, assignee) {
            if (_.get(field, 'required')) {
              $ctrl.requiredFieldNote[field.id] = !$ctrl.requiredFieldNote[field.id];
            } else if ($ctrl.readOnlyAssignee) {
              if (_.get(assignee, 'guests', []).length || _.get(assignee, 'groups', []).length || _.get(assignee, 'users', []).length) {
                $rootScope.$emit('KO_FIELD_ASSIGNEE:CLICK' + field.alias);
              } else {
                $rootScope.$emit('KO_FIELD_ASSIGNEE:CLICK', { field: field });
              }
            } else {
              $ctrl.isEditMode[field.id] = !$ctrl.isEditMode[field.id];
            }
          }

          /**
           * @function
           * @name validateFormFields
           * @description
           * This function is used to check empty form fields
           */
          function validateFormFields(isErrorFields) {
            if (isErrorFields) {
              _.mapKeys($ctrl.errorFields, function (value, key) {
                var field = key.split('.');
                if (field.length) {
                  $ctrl.validationErrors[field[1]] = value;
                  Helper.clearUniversalAlert('growlNotification');
                }
              });
            } else {
              _.each($ctrl.fields, function (field) {
                var value = $ctrl.fieldData ? $ctrl.fieldData[field.id] : field.value;
                if (field.field_type === 'multiselect' && field.required) {
                  var realTimeSelected = _.filter(field.options, { selected: true });
                  $ctrl.validationErrors[field.id] = (_.get(field, 'settings.must_all_checked') && realTimeSelected.length !== field.options.length) ? $filter('translate')('steps.messages.requiredAllSelectedField')
                   : realTimeSelected.length < 1 ? $filter('translate')('steps.messages.requiredMultipleField') : '';
                } else if (field.field_type !== 'multiselect' && field.required && (!value || (field.field_type === 'table' ? FieldService.isTableEmpty(field) : (field.field_type === 'assignees_form' ? !(value.users.length) : (_.isArray(value) && value.length < 1))))) {
                  $ctrl.validationErrors[field.id] = $filter('translate')('steps.messages.requiredField');
                } else {
                  if ((field.field_type === 'textarea' || field.field_type === 'text') && value && value.length > COMMON.MAX_TEXT_CHAR_LIMIT) {
                    $ctrl.validationErrors[field.id] = $filter('translate')('steps.messages.textareaCharacterExceed', { maxChar: COMMON.MAX_TEXT_CHAR_LIMIT, over: COMMON.MAX_TEXT_CHAR_LIMIT - value.length });
                  }
                }
              });
            }
            $ctrl.disableCompletion = !_.isEmpty($ctrl.validationErrors);
          }

          /**
          * @function
          * @name omitByType
          * @param fieldType {string|Array}
          * @description
          * An helper function that omit/remove fields by given type
          */
          function omitByType(fieldType) {
            fieldType = _.isArray(fieldType) ? fieldType : [fieldType];
            return _.filter($ctrl.fields, function (f) {
              return _.indexOf(fieldType, f.field_type) === -1;
            });
          }

          fieldsUpdateWatcherHandler = $scope.$watch('$ctrl.fields', function (values) {
            $ctrl.disableCompletion = false;
            if (values) {
              _.each(values, function (field) {
                var value = _.filter(field.options, { selected: true });
                if (field.required && ((field.field_type === 'table' && FieldService.isTableEmpty(field)) || (field.field_type === 'date' && !field.value) || (field.field_type !== 'date' && _.isEmpty(field.value)))) {
                  $ctrl.disableCompletion = true;
                } else {
                  if (field.required && field.field_type === 'multiselect' && _.get(field, 'settings.must_all_checked') && _.get(field, 'options.length', 0) !== value.length) {
                    $ctrl.disableCompletion = true;
                  }
                  if ((field.field_type === 'textarea' || field.field_type === 'text') && value && value.length > COMMON.MAX_TEXT_CHAR_LIMIT) {
                    $ctrl.disableCompletion = true;
                  }
                }
              });
            }
          }, true);
        }
    });
})();
