/**
 * @ngdoc Component
 * @name tallyfy.steps.component.powerTools.powerAssigner
 * @module tallyfy.steps
 *
 * @description
 * A component to manage bunch of steps to assign selected user
 *
 * @author Samier Sompura ( gmail::samier.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.steps')
    .component('powerAssigner', {
      templateUrl: 'app/modules/steps/powerTools/powerAssigner/powerAssigner.html',
      bindings: {
        type: '<',
        process: '<',
        cancel: '&',
        activeTab: '<',
        userAssignment: '='
      },
      controller:
         /*@ngInject*/
        function (_, StepService, $filter, $rootScope, UsersService, store, $timeout, $q, CompactTaskService, DATEFORMAT, moment, DateUtils, Growl, TasksService, Helper, blockUI) {
          var $ctrl = this,
            availableSteps,
            blockUI = blockUI.instances.get('powerAssigner'),
            isError = false,
            growl = new Growl();

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.assignTypeList = StepService.getAssignTypes();
          $ctrl.setAssignmentType = setAssignmentType;
          $ctrl.setSearchPlaceholder = setSearchPlaceholder;
          $ctrl.setAssignmentUser = setAssignmentUser;
          $ctrl.isUserAlreadyAssigned = isUserAlreadyAssigned;
          $ctrl.userAssignStep = userAssignStep;
          $ctrl.undoChanges = undoChanges;
          $ctrl.saveStepsAssignees = saveStepsAssignees;
          $ctrl.allStepUserAssign = allStepUserAssign;
          $ctrl.isUserAssignedToAll = isUserAssignedToAll;
          $ctrl.defaultAvatar = defaultAvatar;
          $ctrl.defaultAvatarText = defaultAvatarText;
          $ctrl.uiSelectOpenClose = uiSelectOpenClose;
          $ctrl.searchRecords = searchRecords;
          $ctrl.loadMore = loadMore;
          
          /**
           * public properties
           */
          $ctrl.onSaving = false;
          $ctrl.responseData = [];
          $ctrl.activeUsersUiSelectLimit = 10;
          
          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            if ($ctrl.process.type === 'task') {
              $ctrl.isTrackerView = true;
            }
            if($ctrl.activeTab.value === 'assign') {              
              $ctrl.stepsToUpdate = angular.copy($ctrl.process.steps);
              checkGuestAssignments();
              $ctrl.userAssignment = false;
              setAssignmentType(_.first($ctrl.assignTypeList));
              setUsersList();
              reOrderTasks();
            }
          }
          
          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() {
            if ($ctrl.process.type === 'task') {
              $ctrl.isTrackerView = true;
            }
            if ($ctrl.activeTab.value === 'assign') {  
              $ctrl.stepsToUpdate = angular.copy($ctrl.process.steps);
              checkGuestAssignments();
              $ctrl.userAssignment = false;
              setAssignmentType(_.first($ctrl.assignTypeList));
              setUsersList();
              reOrderTasks();
            }
          }
          
          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }
          
          /**
           * @ngdoc method
           * @name setAssignmentType
           * @description Set Assignment Type
           * @param {*} stepAssignType
           */
          function setAssignmentType(stepAssignType) {
            if($ctrl.selectedAssignType === stepAssignType)
              return;
            
            $ctrl.selectedAssignType = stepAssignType;
          }
          
          /**
           * @ngdoc method
           * @name setSearchPlaceholder
           * @description Set placeholder message from json file
           * @param {Object} $select
           */
          function setSearchPlaceholder($select) {
            if (!_.isUndefined($select.searchInput)) {
              _.head($select.searchInput).placeholder = $filter('translate')("global.task.messages.searchMemberGuest");
            }
          }

          /**
            * @ngdoc method
            * @name setUsersList
            * @description Set active users list in dropdown
          */
          function setUsersList() {
            blockUI.start();
            $q.all([
              store.getLightweightGuests(),
              store.getUsers(),
              store.getLightweightGroups(),
              StepService.getAllRoles()
            ]).then(function (response) {
              var activeUsers = UsersService.getBilledUsers(_.get(response, '[1]', [])), activeGuests = _.get(response, '[0]', []), activeGroups = _.get(response, '[2]', []);
              $ctrl.activeUsers = _.concat(activeUsers, activeGroups, activeGuests);
              $ctrl.selectedUser = _.find($ctrl.activeUsers, { id: $rootScope.identity.id });
              $ctrl.activeUsersCache = angular.copy($ctrl.activeUsers);
              $ctrl.orgGroups = activeGroups;
              $ctrl.usersInOrg = activeUsers;
              $ctrl.orgGuests = activeGuests;
              $ctrl.orgRoles = _.get(response, '[3].data', []);
              $ctrl.showTab = true;
              initTable();
            });
          }
          
          /**
            * @ngdoc method
            * @name setAssignmentUser
            * @param {Object} selected
            * @description Reset steps assignment 
          */
          function setAssignmentUser(selected) {
            if ($ctrl.selectedUser === selected) {
              return;
            }
            if (_.get(selected.selected, 'addNewGuest')) {
              selected.selected = _.omit(selected.selected, ['addNewGuest']);
            }
            blockUI.start();
            $ctrl.stepsToUpdate = angular.copy($ctrl.process.steps);
            initTable();
            reOrderTasks();
          }
          
          /**
            * @ngdoc method
            * @name isUserAlreadyAssigned
            * @description Step already assign user or not 
            * @param {Object} stepAssign
          */
          function isUserAlreadyAssigned(stepAssign) {
            if (!$ctrl.selectedUser) {
              return false;
            }
            var assignees;
            if ($ctrl.process.type === 'task') {
               assignees = _.get($ctrl.selectedUser, 'id') ? (_.get($ctrl.selectedUser, 'type') === 'member' ? stepAssign.owners.users : (_.get($ctrl.selectedUser, 'type') === 'group' ? stepAssign.owners.groups : stepAssign.owners.guests)) : stepAssign.owners.guests;
            } else {
               assignees = _.get($ctrl.selectedUser, 'id') ? (_.get($ctrl.selectedUser, 'type') === 'member' ? stepAssign.assignees : (_.get($ctrl.selectedUser, 'type') === 'group'? stepAssign.groups : stepAssign.guests)) : stepAssign.guests;
            }
            var isAssigned = _.get($ctrl.selectedUser, 'id') ? _.includes(assignees, $ctrl.selectedUser.id) : _.includes(assignees, $ctrl.selectedUser.email);
            return $ctrl.selectedAssignType.value === 'assign' ? isAssigned : !isAssigned;
          }
          
          /**
           * @ngdoc method
           * @name userAssignStep
           * @description Edit selected steps data
           * @param {Object} step
           */
          function userAssignStep(step) {
            $ctrl.userAssignment = true;
            var editStepData = angular.copy(step);
            if ($ctrl.selectedAssignType.value === 'assign') {
              if ($ctrl.process.type === 'task') {
                _.get($ctrl.selectedUser, 'id') ? (_.get($ctrl.selectedUser, 'type') === 'member' ? step.owners.users.push($ctrl.selectedUser.id) : (_.get($ctrl.selectedUser, 'type') === 'group' ? step.owners.groups.push($ctrl.selectedUser.id) : step.owners.guests.push($ctrl.selectedUser.email) )) : step.owners.guests.push($ctrl.selectedUser.email);
              } else {
                _.get($ctrl.selectedUser, 'id') ? (_.get($ctrl.selectedUser, 'type') === 'member' ? step.assignees.push($ctrl.selectedUser.id) : (_.get($ctrl.selectedUser, 'type') === 'group' ? step.groups.push($ctrl.selectedUser.id) : step.guests.push($ctrl.selectedUser.email))) : step.guests.push($ctrl.selectedUser.email);
              }
              step['assigneesUpdated'] = true;
              step['unassigneesUpdated'] = false;
            }
            if ($ctrl.selectedAssignType.value === 'unassign') {
              if ($ctrl.process.type === 'task' && _.get($ctrl.selectedUser, 'id') && (_.get(editStepData, 'owners.users.length', 0) + _.get(editStepData, 'owners.groups.length', 0)) === 1) {
                $timeout(function () {
                  growl.warning($filter('translate')('tasks.messages.taskUnassignees'), {
                    referenceId: 'growPowerTools',
                    disableIcons: true,
                    disableCloseButton: true
                  });
                }, 0);
                return;
              }
              var index;
              if ($ctrl.process.type === 'task') {
                if (_.get($ctrl.selectedUser, 'id')) {
                  if (_.get($ctrl.selectedUser, 'type') === 'member') {
                    index = _.indexOf(editStepData.owners.users, $ctrl.selectedUser.id);
                    (index !== -1) ? step.owners.users.splice(index, 1) : angular.noop();
                  } else if (_.get($ctrl.selectedUser, 'type') === 'group') {
                    index = _.indexOf(editStepData.owners.groups, $ctrl.selectedUser.id);
                    (index !== -1) ? step.owners.groups.splice(index, 1) : angular.noop();
                  } else {
                    index = _.indexOf(editStepData.owners.guests, $ctrl.selectedUser.email);
                    (index !== -1) ? step.owners.guests.splice(index, 1) : angular.noop();
                  }
                } else {
                  index = _.indexOf(editStepData.owners.guests, $ctrl.selectedUser.email);
                  (index !== -1) ? step.owners.guests.splice(index, 1) : angular.noop();
                }
              }
              else {
                if (_.get($ctrl.selectedUser, 'id')) {
                  if (_.get($ctrl.selectedUser, 'type') === 'member') {
                    index = _.indexOf(editStepData.assignees, $ctrl.selectedUser.id);
                    (index !== -1) ? step.assignees.splice(index, 1) : angular.noop();
                  } else if (_.get($ctrl.selectedUser, 'type') === 'group') {
                    index = _.indexOf(editStepData.groups, $ctrl.selectedUser.id);
                    (index !== -1) ? step.groups.splice(index, 1) : angular.noop();
                  } else {
                    index = _.indexOf(editStepData.guests, $ctrl.selectedUser.email);
                    (index !== -1) ? step.guests.splice(index, 1) : angular.noop();
                  }
                } else {
                  index = _.indexOf(editStepData.guests, $ctrl.selectedUser.email);
                  (index !== -1) ? step.guests.splice(index, 1) : angular.noop();
                }
              }
              step['assigneesUpdated'] = false;
              step['unassigneesUpdated'] = true;
            }
            var ownerKey = $ctrl.isTrackerView ? step.owners : step, flag = calculateFlag(step, ownerKey);
            angular.extend(step, { flag: flag });
            checkGuestAssignments();
          }

          function calculateFlag (step, ownerKey) {
            return step.allow_guest_owners ? ($ctrl.isTrackerView ? ownerKey.guests.length : step.guests.length) : 
              ($ctrl.isTrackerView ? (ownerKey.users.length || ownerKey.groups.length || (step.role_changes_every_time && step.roles.data.length)) 
                : (step.assignees.length || step.groups.length || (step.role_changes_every_time && step.roles.data.length)));
          }
          
          /**
           * @ngdoc method
           * @name removeUser
           * @description Remove selected step
           * @param {Integer} id
           */
          function undoChanges(id) {
            var index = _.findIndex($ctrl.process.steps, { id: id });
            if (index !== -1) {
              $ctrl.stepsToUpdate[index] = angular.copy($ctrl.process.steps[index]);
              var step = $ctrl.stepsToUpdate[index],
                ownerKey = $ctrl.isTrackerView ? step.owners : step,
                flag = calculateFlag(step, ownerKey);
                angular.extend(step, { flag: flag });
                if (step.role_changes_every_time && step.roles.data.length) {
                  _.forEach(step.roles.data, function (role) {
                    var selectedRole = _.find($ctrl.orgRoles, function (opt) { return opt.id === role.org_role_id; });
                    selectedRole ? angular.extend(role, { title: selectedRole.title }) : angular.noop();
                  });
                }
                var updatedStep = _.filter($ctrl.stepsToUpdate, function(step) {
                  return step.assigneesUpdated;
                });
                $ctrl.userAssignment = !!(updatedStep && (updatedStep.length > 0));
                checkGuestAssignments();
            }
          }
          
          /**
           * @ngdoc method
           * @name saveStepsAssignees
           * @description Update Step Assigner
           */
          function saveStepsAssignees() {
            $ctrl.userAssignment = false;
            var updatedSteps = _.filter($ctrl.stepsToUpdate , function(step) {
              return step.assigneesUpdated || step.unassigneesUpdated;
            });
            if (updatedSteps.length > 0) {
              if ($ctrl.type === 'launchBlueprint') {
                $ctrl.cancel({ value: updatedSteps, type: 'power-assigner' });
              } else {
                if ($ctrl.process.type === 'task') {
                  saveBulkAssign(updatedSteps);
                } else {
                  saveSteps(updatedSteps);
                }
              }
            }
          }

          /**
           * @ngdoc method
           * @name saveSteps
           * @description save steps
           * @private 
           * @param {*} allUpdatedSteps
           */
          function saveSteps(allUpdatedSteps) {
            var firstStep = _.omit(_.head(allUpdatedSteps), [ 'summary', 'original_summary' ]);
            availableSteps = allUpdatedSteps;
            $ctrl.onSaving = true;
            updateStep(firstStep).then(successStepResponse, failureStepResponse);
          }
          
          /**
           * @ngdoc method
           * @name successStepResponse
           * @description 
           * Calls after getting successful response from API
           * Used recursion to call step API to create bunch of steps
           * @param {*} response
           */
          function successStepResponse(response) {
            var step = $ctrl.process.type === 'task' ? response.task : response.data,
            remainSteps = availableSteps.slice(1);
            $ctrl.responseData.push(step);
            if (remainSteps.length) {
              saveSteps(remainSteps);
            }
            if (!remainSteps.length && !isError) {
              $ctrl.isSaved = true;
              $ctrl.onSaving = false;
              $ctrl.cancel({ value: $ctrl.responseData, type: 'power-assigner' });
            }
            if (isError) {
              resetVariables();
            }
          }
          
          /**
           * @function
           * @name resetVariables
           * @description Helper method to set falsy value in some variable's
           * @returns {void}
           */
          function resetVariables() {
            $ctrl.onSaving = false;
            $ctrl.isTimeOut = false;
          }

          /**
           * @ngdoc method
           * @name failureStepResponse
           * @description It will call when step API gets failed due to some reason
           */
          function failureStepResponse() {
            isError = true;
            resetVariables();
            $ctrl.isSaved = false;
            var resetSteps = availableSteps.slice(1);
            if (resetSteps.length) {
              saveSteps(resetSteps);
            }
            $ctrl.steps.length = 0;
          }
          
          /**
           * @ngdoc method
           * @name updateStep
           * @description Update Step Assigner
           * @param {Object} value
           */
          function updateStep(value) {
            var step = _.omit(value, ['assigneesUpdated', 'unassigneesUpdated']);
            if ($ctrl.process.type === 'task') {
              var params = { id: step.id, action_id: step.run_id, update_dependent_deadlines: true, with: 'threads' },
                deadline = DateUtils.toUTC(moment(step.deadline_unformatted)).format(DATEFORMAT.DEFAULT),
                savedTask = angular.extend({}, step, { deadline: deadline });
              return CompactTaskService.saveTask(params, savedTask);
            }
            else {
              return StepService.updateSteps({
                id: step.id,
                checklist_id: $ctrl.process.id
              }, step);
            }
          }
          
          /**
           * @ngdoc method
           * @name allStepUserAssign
           * @description All steps Selected User Assign
           */
          function allStepUserAssign() {
            _.forEach($ctrl.stepsToUpdate, function (step) {
              !isUserAlreadyAssigned(step) ? userAssignStep(step) : angular.noop();
            });
          }

          function isUserAssignedToAll() {
            if (!$ctrl.selectedUser) {
              return false;
            }
            var users = _.filter(angular.copy($ctrl.stepsToUpdate), function (step) {
              var assignees;
              if ($ctrl.process.type === 'task') {
                assignees = _.get($ctrl.selectedUser, 'id') ? (_.get($ctrl.selectedUser, 'type') === 'member' ? step.owners.users : (_.get($ctrl.selectedUser, 'type') === 'group' ? step.owners.groups : step.owners.guests)) : step.owners.guests;
              } else {
                assignees = _.get($ctrl.selectedUser, 'id') ? (_.get($ctrl.selectedUser, 'type') === 'member' ? step.assignees : (_.get($ctrl.selectedUser, 'type') === 'group' ? step.groups : step.guests)) : step.guests;
              }
              var selectedUser = _.get($ctrl.selectedUser, 'id') ? $ctrl.selectedUser.id : $ctrl.selectedUser.email;
              return _.includes(assignees, selectedUser);
            });
            if ($ctrl.selectedAssignType.value === 'assign') {
              return angular.copy($ctrl.stepsToUpdate).length === users.length;
            } else {
              return !users.length;
            }
          }
          
          /**
           * @ngdoc method
           * @name defaultAvatar
           * @public
           * @description set default avatar
           * @param {string} avatar
           * @return {boolean}
           */
          function defaultAvatar(avatar) {
            return TasksService.setDefaultAvatar(avatar);
          }
          
          /**
           * @ngdoc method
           * @name defaultAvatarText
           * @public
           * @description set default avatar Text
           * @param {object} user
           * @return {string} first character of name
           */
          function defaultAvatarText(user) {
            if (!user) {
              return '';
            }
            if (_.get(user, 'id')) {
              return (user.type === 'member') ? TasksService.setDefaultAvatarText(user.first_name, user.last_name) : TasksService.setDefaultAvatarText(user.name);
            } else {
              return TasksService.setDefaultAvatarText(user.email);
            }
          }
          
           /**
           * @ngdoc method
           * @name uiSelectOpenClose
           * @public
           * @description reset data on ui-select
           * @param {object} $select
           * open and close
           */
          function uiSelectOpenClose($select) {
            if (!$select.open) {
              return;
            }
            $ctrl.searchPreviousValue = '';
            $ctrl.activeUsersUiSelectLimit = 10;
            $ctrl.activeUsers = $ctrl.activeUsersCache;
          }
          
           /**
            * @ngdoc method
            * @name searchRecords
            * @private
            * @description search record locally
            * wher user search for record. 
            * @param {object} $select
            * @param {object} search
            */
          function searchRecords($select, search) {
            if (!$select.open && (!search && !$ctrl.searchPreviousValue)) {
              return;
            }
            $ctrl.activeUsersUiSelectLimit = 10;
            if (search) {
              $ctrl.searchPreviousValue = search;
              var searchedRecords = _.filter($ctrl.activeUsersCache, function (value) {
                return _.toLower(value.email).indexOf(_.toLower(search)) !== -1 || _.toLower(value.text).indexOf(_.toLower(search)) !== -1;
              });
              $ctrl.activeUsers = searchedRecords;
            }
            if(!search && $ctrl.searchPreviousValue) {
              $ctrl.activeUsers = $ctrl.activeUsersCache;
            }
            if($ctrl.activeUsers.length === 0 && Helper.isValidEmail(search)) {
              $ctrl.activeUsers.push({ addNewGuest: true, email: search });
            }
          }
          
           /**
           * @ngdoc method
           * @name loadMore
           * @public
           * @description triggers when scrolled down
           */
          function loadMore() {
            $ctrl.activeUsersUiSelectLimit += 10;
          }

          /**
           * @ngdoc method
           * @name saveBulkAssign
           * @description Update Step Bulk Assigner
           */
          function saveBulkAssign(updatedSteps){
            $ctrl.updateAssignee = [];
            $ctrl.onSaving = true;
            var run_id = _.get($ctrl.process, 'id');
            _.forEach(updatedSteps, function (step) {
              var assignees = {
                "id": step.id,
                "owners": _.omit(step.owners, ['taskUrls'])
              };
              assignees.owners.users = Helper.getFilteredUsers($ctrl.usersInOrg, assignees.owners.users);
              $ctrl.updateAssignee.push(assignees);
            });
            var savedTask = { tasks: $ctrl.updateAssignee },
            params = { org: _.get($rootScope, 'identity.default_organization.id'), run_id: run_id};
            TasksService.updateBulkTasks(params, savedTask).then(function (response) {
              var responseData = _.get(response, 'data');
              $ctrl.userAssignment = true;
              $ctrl.cancel({ value: responseData, type: 'power-assigner' });
            }, function () { });
          }

          //Check guest assignments on required tasks/steps
          function checkGuestAssignments () {
            var guestTasks = _.filter($ctrl.stepsToUpdate, function (step) { return step.allow_guest_owners; });
            $ctrl.totalGuestTasks = guestTasks.length;
            $ctrl.totalGuestTasksAssigned = _.filter(guestTasks, function (task) { return $ctrl.isTrackerView ? task.owners.guests.length : task.guests.length }).length;
          }

          //Init table
          function initTable () {
            _.forEach($ctrl.stepsToUpdate, function (step) {
              var ownerKey = $ctrl.isTrackerView ? step.owners : step, flag = calculateFlag(step, ownerKey);
              angular.extend(step, { flag: flag });
              if (step.role_changes_every_time && step.roles.data.length) {
                _.forEach(step.roles.data, function (role) {
                  var selectedRole = _.find($ctrl.orgRoles, function (opt) { return opt.id === role.org_role_id; });
                  selectedRole ? angular.extend(role, { title: selectedRole.title }) : angular.noop();
                })
              }
              blockUI.stop();
            });
          }

          function reOrderTasks() {
            $ctrl.stepsToUpdate = _.map($ctrl.stepsToUpdate, function (task) {
              var stageData = _.find(_.get($ctrl.process, 'data.stages.data', []), function (stage) {
                return !task.stage_id ? false : (stage.id === task.stage_id.toString());
              });
              task.stage_order = stageData ? stageData.position : null;
              return task;
            })
          }
          //controller ends
        }
    });
})();