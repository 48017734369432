/**
 * @ngdoc component
 * @name tallyfy.compactItemAssignee
 * @restrict 'A'
 *
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('compactItemAssignee', {
      templateUrl: 'app/components/compact/items/compactAssignee/compact-item-assignee.component.html',
      bindings: {
        isGuest: '<?',
        viewerId: '<',
        displayType: '<',
        iconSize: '<',
        usersInOrg: '<',
        maxItemDisplay: '<',
        guestsAssigned: '=',
        coworkersAssigned: '=',
        groupsAssigned: '=?',
        showTooltip: '<',
        tooltipDirection: '<?',
        isStepGuestAssigned: '<?',
        editMode: '<?',
        focusOnEdit: '<',
        allowGuestsAssigned: '<',
        showGuestIcon: '<',
        autoLoadProfileImage: '<?',
        viewType: '@',
        editTask: '&',
        inlineUsersEdit: '&?',
        inlineGuestsEdit: '&?',
        showInlineGuestsEdit: '<?',
        assignSelf: '&?',
        showAssignSelf: '=?',
        assignSelfCallback: '&?',
        moreItems: '=?',
        isStepAssignee: '<',
        isSpecificMember: '<',
        isRunStarter: '<',
        taskStatus: '<?',
        isPublic: '<',
        taskType: '<',
        hideInviteMember: '<?',
        hideAssigneeLabel: '<?',
        orgGroups: '<?',
        isPublicProcess: '<',
        appendPopupInBody: '<?',
        onTaskSave: '&?',
        assigneeUpdate: '=?',
        editModeType: '<?',
        allowEmptyValue: '<?',
        rolesInOrg: '<?',
        assignedRoles: '=?',
        rolesInString: '<?',
        hideAddAssigneesBtn: '<?',
        isAtLeastOneOverallAssignee: '<?',
        isClaimTask: '<',
        standaloneControl: '<?',
        tableFieldValue: '<?',
        launchAssigneePopup: '<?'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $scope, $filter, UsersService, Helper, $uibModal, store, $confirm, $timeout) {
          var $ctrl = this,
            unRegisteredHandlerStepGuestWatcher,
            unRegisteredHandlerCoworkerWatcher,
            unRegisteredHandlerGroupWatcher,
            unRegisteredHandlerGuestWatcher,
            unRegisteredHandlerRoleWatcher,
            viewer,
            recentGuests = {
              added: [],
              invited: []
            };

          /**
           * angularjs lifecycle hook
           */
          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          // public method
          $ctrl.filterUsers = filterUsers;
          $ctrl.filterGuests = filterGuests;
          $ctrl.onRemovingUser = onRemovingUser;
          $ctrl.onUserRemoved = onUserRemoved;
          $ctrl.onAddingUser = onAddingUser;
          $ctrl.onUserAdded = onUserAdded;
          $ctrl.openInviteModal = UsersService.openInviteModal;
          $ctrl.onUserAddClick = onUserAddClick;
          $ctrl.onGuestAddClick = onGuestAddClick;
          $ctrl.onAssignSelfClick = onAssignSelfClick;
          $ctrl.onIconClick = onIconClick;
          $ctrl.onClaimTask = onClaimTask;
          $ctrl.onTaskSaveHandler = onTaskSaveHandler;

          /**
           * @ngdoc method
           * @name onInit
           *
           * @description
           * angular life cycle hook method
           */
          function onInit() {
            if ($ctrl.editMode) {
              if (!$ctrl.isGuest) {
                store.getLightweightGuests().then(function (res) {
                  $ctrl.guestsInOrg = res;
                  _.forEach($ctrl.guestsInOrg, function(guestItem) {
                    guestItem.id = guestItem.email;
                    guestItem.type = 'guest';
                  });
                });
              } else {
                $ctrl.guestsInOrg = [];
              }
            }
            viewer = $ctrl.isGuest ? $ctrl.viewerId : _.find($ctrl.usersInOrg, { id: $ctrl.viewerId });
            $ctrl.currentUser = $rootScope.identity;
            if (!viewer && $ctrl.viewerId && !$ctrl.isPublicProcess) {
              viewer = $ctrl.viewerId;
            }

            if ($ctrl.showAssignSelf) {
              checkCurrentUserPresentInAGroup();
            }
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            $ctrl.allow_user_invite = _.get($rootScope, 'orgSamlInfo.allow_user_invite');
            setAssigneeList();
          }

          function onChanges(changes) {
            if (changes.editMode) {
              $ctrl.assignClicked = false;

              if ($ctrl.showAssignSelf) {
                checkCurrentUserPresentInAGroup();
              }
            }
            if (changes.usersInOrg && changes.usersInOrg.currentValue !== changes.usersInOrg.previousValue) {
              setAssigneeList();
            }
          }

          /**
           * @ngdoc method
           * @name onDestroy
           *
           * @description
           * angular life cycle hook method
           */
          function onDestroy() {
            unRegisteredHandlerCoworkerWatcher();
            unRegisteredHandlerGroupWatcher();
            unRegisteredHandlerGuestWatcher();
            unRegisteredHandlerStepGuestWatcher();
            unRegisteredHandlerRoleWatcher();
          }

          /**
           * @ngdoc method
           * @name setAssigneeList
           *
           * @description
           * set assignee list
           */
          function setAssigneeList() {
            var maxDisplay = $ctrl.maxItemDisplay || 2, members;
            $ctrl.tooltipAssigneeList = '';
            $ctrl.assigneeList = generateTaskAssignee();
            $ctrl.guestList = generateTaskGuestAssignee();
            $ctrl.roleList = generateTaskRoleAssignee();
            $ctrl.assignee = $ctrl.assigneeList.concat($ctrl.guestList.concat($ctrl.roleList));
            members = _.filter($ctrl.assigneeList, function (assignee) {
              return assignee.type === 'coworker';
            });
            $ctrl.iAmAssigned = Helper.isObjectEmpty(_.find(members, { id: $ctrl.viewerId }));
            if ($ctrl.displayType !== 'inline' && $ctrl.displayType !== 'inline-and-cut') {
              if ($ctrl.assignee.length >= maxDisplay) {
                var totalAssignees = $ctrl.assignee.length, skipCount = $ctrl.assignee.length - maxDisplay;
                if (skipCount) {
                  if (!$ctrl.viewType || $ctrl.viewType !== 'one-off-task') {
                    skipCount = totalAssignees >= maxDisplay ? skipCount + 1 : skipCount;
                  }
                  var moreAssignees = angular.copy($ctrl.assignee);
                  $ctrl.assignee = _.slice(moreAssignees, 0, totalAssignees - skipCount);
                  if ($ctrl.viewType && $ctrl.viewType === 'one-off-task') {
                    moreAssignees = moreAssignees.splice(totalAssignees - skipCount);
                    if (moreAssignees.length) {
                      $ctrl.tooltipAssigneeList = '';
                      _.forEach(moreAssignees, function (value, key) {
                        $ctrl.tooltipAssigneeList += value.text + ((moreAssignees.length !== (key + 1)) ? ', ' : '');
                      });
                    }
                  }
                  $ctrl.assignee.push({
                    icon: {
                      type: 'counter',
                      value: '+' + skipCount
                    },
                    type: 'counter'
                  });
                }
              }
            }

            if ($ctrl.showAssignSelf) {
              checkCurrentUserPresentInAGroup();
            }
          }

          function generateTaskRoleAssignee() {
            var usersAssigned = [], roles = $ctrl.assignedRoles || [];
            for (var i = 0; i < roles.length; i++) {
              var role = _.find($ctrl.rolesInOrg, { id: roles[i].org_role_id });
              if (role) {
                if (!$ctrl.isGuest) {
                  var initial, strArr = role.title.split(' ');
                  if (strArr.length) {
                    if (strArr.length === 1) {
                      initial = _.upperCase(strArr[0].slice(0, 2));
                    } else {
                      initial = _.upperCase(strArr[0].slice(0, 1)) + _.upperCase(strArr[1].slice(0, 1));
                    }
                  }

                  $ctrl.tooltipAssigneeList += $ctrl.tooltipAssigneeList ? ', ' + role.escaped_title : role.escaped_title;
                  usersAssigned.push({
                    id: roles[i].id,
                    icon: {
                      type: 'initial',
                      value: initial
                    },
                    type: 'role'
                  });
                }
              }
            }
            return usersAssigned;
          }

          function generateTaskGuestAssignee() {
            var usersAssigned = [];
            if ($ctrl.isGuest) {
              if ($ctrl.displayType === 'completer-text-only') {
                var isGuestACompleter = _.indexOf($ctrl.guestsAssigned, viewer) !== -1;
                if (isGuestACompleter) {
                  $ctrl.tooltipAssigneeList += viewer;
                  usersAssigned.push({
                    text: viewer,
                    icon: {
                      type: 'initial',
                      value: viewer ? _.upperCase(viewer.slice(0, 2)) : ''
                    },
                    type: 'guest'
                  });
                }
              } else {
                $ctrl.tooltipAssigneeList += viewer;
                usersAssigned.push({
                  text: viewer,
                  icon: {
                    type: 'initial',
                    value: viewer ? _.upperCase(viewer.slice(0, 2)) : ''
                  },
                  type: 'guest'
                });
              }
            }
            if (!$ctrl.isPublic) {
              // get from guest
              var guestOwners = _.compact($ctrl.guestsAssigned) || [];
              for (var i = 0; i < guestOwners.length; i++) {
                if (!$ctrl.isGuest || guestOwners[i] !== viewer) {
                  $ctrl.tooltipAssigneeList += $ctrl.tooltipAssigneeList ? ', ' + guestOwners[i] : guestOwners[i];
                  usersAssigned.push({
                    id: guestOwners[i],
                    text: guestOwners[i],
                    email: guestOwners[i],
                    icon: {
                      type: 'initial',
                      value: _.upperCase(guestOwners[i].slice(0, 2))
                    },
                    type: 'guest',
                    showGuestInvitePrompt: (recentGuests.added.indexOf(guestOwners[i]) > -1) && (recentGuests.invited.indexOf(guestOwners[i]) < 0),
                    status: (recentGuests.invited.indexOf(guestOwners[i]) > -1) ? 'invited' : null,
                    onGuestAssigneeConfirm: (recentGuests.added.indexOf(guestOwners[i]) > -1) ? onGuestAssigneeConfirm : angular.noop()
                  });
                }
              }
            }
            return usersAssigned;
          }


          /**
           * @ngdoc method
           * @name generateTaskAssignee
           *
           * @description
           * generate task assignee
           */
          function generateTaskAssignee() {
            var usersAssigned = [], activeUsersInOrg = $ctrl.usersInOrg || [];
            if ($ctrl.isGuest) {
              if ($ctrl.displayType === 'completer-text-only') {
                var isGuestACompleter = _.indexOf($ctrl.guestsAssigned, viewer) !== -1;
                if (isGuestACompleter) {
                  $ctrl.tooltipAssigneeList += viewer;
                  usersAssigned.push({
                    text: viewer,
                    icon: {
                      type: 'initial',
                      value: _.upperCase(viewer.slice(0, 2))
                    },
                    type: 'guest'
                  });
                }
              }
            }
            if (!$ctrl.isPublic) {
              // get from coworker
              var userOwners = $ctrl.coworkersAssigned || [];
              for (var i = 0; i < userOwners.length; i++) {
                if (!$ctrl.isGuest && userOwners[i] === _.get(viewer, 'id')) {
                  if (viewer.id) {
                    var icon = getUserIcon(viewer);
                    usersAssigned = _.concat({
                      id: $ctrl.viewerId,
                      text: viewer.full_name,
                      email: viewer.email,
                      icon: icon,
                      type: 'coworker'
                    }, usersAssigned);
                    $ctrl.tooltipAssigneeList = ($ctrl.tooltipAssigneeList.length) ? viewer.full_name + ', ' + $ctrl.tooltipAssigneeList : viewer.full_name;
                  }
                } else {
                  var owner = _.find(activeUsersInOrg, userOwners[i] === 'bot' ? { type: 'bot' } : { id: _.get(userOwners[i], 'id') || _.get(userOwners, '[' + i + ']') }) || _.get(userOwners, '[' + i + ']');
                  if (owner && owner.id) {
                    var coworkerIcon = getUserIcon(owner);
                    var text = owner.text || owner.full_name;
                    $ctrl.tooltipAssigneeList += $ctrl.tooltipAssigneeList ? ', ' + text : text;
                    usersAssigned.push({
                      id: owner.id,
                      text: text,
                      email: owner.email,
                      icon: coworkerIcon,
                      type: 'coworker'
                    });
                  }
                }
              }

              if (_.get($ctrl.orgGroups, 'length', 0)) {
                // Iterate over the groups
                var groupsAssigned = $ctrl.groupsAssigned || [];
                _.forEach(groupsAssigned, function (groupItem) {
                  var logoText, groupBaseObj = _.find($ctrl.orgGroups, { id: groupItem.id ? groupItem.id : groupItem }),
                    groupText = _.split(groupBaseObj.name, " ");
                  $ctrl.tooltipAssigneeList += $ctrl.tooltipAssigneeList ? ', ' + groupBaseObj.name : groupBaseObj.name;
                  if (groupText.length > 1) {
                    logoText = _.upperCase(groupText[0].slice(0, 1) + groupText[1].slice(0, 1)).replace(/[\s]/g, '');
                  } else {
                    logoText = _.upperCase(groupBaseObj.name.slice(0, 2));
                  }
                  groupBaseObj.icon = {
                    type: 'initial',
                    value: logoText
                  };
                  usersAssigned.push(groupBaseObj);
                });
              }
            }
            return usersAssigned;
          }

          /**
           * @ngdoc method
           * @name getUserIcon
           * @param {*} user
           *
           * @description
           * get user icon
           */
          function getUserIcon(user) {
            if (!user) {
              return;
            }
            var icon = {};
            if (user.profile_pic.indexOf('www.gravatar.com') >= 0) {
              icon.type = 'initial';
              icon.value = (user.full_name && !user.first_name && !user.last_name)
                ? _.upperCase(user.full_name.slice(0, 2))
                : _.upperCase(user.first_name.slice(0, 1)) + _.upperCase(user.last_name.slice(0, 1));
            } else {
              icon.type = 'avatar';
              icon.value = user.profile_pic;
            }
            return icon;
          }

          function filterUsers(q) {
            return _.filter(_.concat($ctrl.usersInOrg, $ctrl.orgGroups || []), function (value) {
              var text = value.text || '';
              return text.toLowerCase().includes(q.toLowerCase());
            });
          }

          function filterGuests(q) {
            return _.filter(_.concat($ctrl.guestsInOrg, []), function (value) {
              var text = value.email || '';
              return text.toLowerCase().includes(q.toLowerCase());
            });
          }

          /**
           * @function
           * @name onRemovingUser
           * @description check before removing user. if only one coworker then it won't delete the user
           * @param $owner
           */
          function onRemovingUser($owner) {
            if (!$ctrl.launchAssigneePopup) {
              return $ctrl.isGuest ? false : (($owner.type === 'coworker') || ($owner.type === 'group')) ? ($ctrl.isAtLeastOneOverallAssignee ? true : ($ctrl.coworkersAssigned.length + _.get($ctrl.groupsAssigned, 'length', 0)) !== ($ctrl.allowEmptyValue ? 0 : 1)) : true;
            } else {
              return $ctrl.isGuest ? false : (($owner.type === 'coworker') || ($owner.type === 'group')) ? ($ctrl.isAtLeastOneOverallAssignee ? true : ($ctrl.assigneeList.length + _.get($ctrl.guestList, 'length', 0)) !== ($ctrl.allowEmptyValue ? 0 : 1)) : true;
            }
          }

          /**
           * @function
           * @name onUserRemoved
           * @description User removed after removing in the tags input
           * @param $owner
           */
          function onUserRemoved($owner) {
            if (!$ctrl.isGuest) {
              $ctrl.assigneeUpdate = true;
              if ($owner.type === 'coworker') {
                if ($ctrl.coworkersAssigned.indexOf($owner.id) > -1) {
                  $ctrl.coworkersAssigned.splice($ctrl.coworkersAssigned.indexOf($owner.id), 1);
                } else if (_.find($ctrl.coworkersAssigned, { id: $owner.id })){
                  $ctrl.coworkersAssigned = angular.copy(_.filter($ctrl.coworkersAssigned, function(item) {
                    return item.id !== $owner.id;
                  }));
                }
              } else if ($owner.type === 'guest') {
                $ctrl.guestsAssigned.splice($ctrl.guestsAssigned.indexOf($owner.text), 1);
                var idxAdded = recentGuests.added.indexOf($owner.text), idxInvited = recentGuests.invited.indexOf($owner.text);
                if (idxAdded > -1) {
                  recentGuests.added.splice(idxAdded, 1);
                }
                if (idxInvited > -1) {
                  recentGuests.invited.splice(idxInvited, 1);
                }
              } else if ($owner.type === 'group') {
                var index = _.indexOf($ctrl.groupsAssigned, $owner.id);
                if (index > -1) {
                  $ctrl.groupsAssigned.splice(index, 1);
                }
              }
            }
          }

          function onAddingUser(user) {
            if (!user.id) {
              if (!Helper.isValidEmail(user.text || user.email)) {
                return false;
              } else if ($ctrl.groupsAssigned && $ctrl.groupsAssigned.length && $ctrl.groupsAssigned[0].id && user.type !== 'group' && doesUserExist($ctrl.usersInOrg, user)) {
                angular.extend(user, _.find($ctrl.usersInOrg, function (value) {
                  return value.email === user.text;
                }));
                return !Helper.isMemberPresentInGroup(user, angular.copy($ctrl.groupsAssigned), $ctrl.orgGroups, true, true);
              } else {
                var text = user.email || user.text;
                $ctrl.assigneeUpdate = true;
                return ((!!$ctrl.allowGuestsAssigned) && (_.indexOf($ctrl.guestsAssigned, text) === -1));
              }
            } else if ($ctrl.groupsAssigned && $ctrl.groupsAssigned.length && user.type !== 'group' && Helper.isMemberPresentInGroup(user, angular.copy($ctrl.groupsAssigned), $ctrl.orgGroups, false, true)) {
              return false;
            } else if (user.type === 'guest' && Helper.isGuestPresentInGroup(user, angular.copy($ctrl.groupsAssigned), $ctrl.orgGroups, false, true)) {
              return false;
            }
            $ctrl.assigneeUpdate = true;
            return true;
          }

          function onUserAdded($owner) {
            if (!doesUserExist($ctrl.usersInOrg, $owner)) {
              if ($owner.type === 'group') {
                if ($ctrl.launchAssigneePopup) {
                  if (!$ctrl.groupsAssigned) {
                    $ctrl.groupsAssigned = [];
                  }
                  var group = _.find($ctrl.orgGroups, { id: $owner.id });
                  angular.extend($owner, {
                    id: group.id,
                    text: group.text,
                    icon: {
                      type: 'initial',
                      value: _.upperCase(group.text.slice(0, 2))
                    },
                    type: 'group'
                  });
                } else {
                  $ctrl.groupsAssigned.push($owner.id);
                }
              } else {
                var text = $owner.email || $owner.text;
                angular.extend($owner, {
                  id: text,
                  text: text,
                  icon: {
                    type: 'initial',
                    value: _.upperCase(text.slice(0, 2))
                  },
                  type: 'guest'
                });
                if (_.indexOf($ctrl.guestsAssigned, text) === -1) {
                  if (!$ctrl.guestsAssigned) {
                    $ctrl.guestsAssigned = [];
                  }
                  if (!$ctrl.launchAssigneePopup) {
                    $ctrl.guestsAssigned.push(text);
                  }
                  recentGuests.added.push(text);
                }
              }
            } else {
              var text = $owner.email || $owner.text, user = _.find($ctrl.usersInOrg, { email: text });
              angular.extend($owner, {
                id: user.id,
                text: user.text,
                icon: getUserIcon(user),
                type: 'coworker'
              });
              if (_.indexOf($ctrl.coworkersAssigned, user.id) === -1) {
                if (!$ctrl.coworkersAssigned) {
                  $ctrl.coworkersAssigned = [];
                }
                if (!$ctrl.launchAssigneePopup) {
                  $ctrl.coworkersAssigned.push(user.id);
                }
              }
            }
          }

          /**
           * @function
           * @name doesUserExist
           * @description Check a user already exist
           * @param {Object} users
           * @param {String} user
           */
          function doesUserExist(users, user) {
            return !!_.filter(users, function (userItem) {
              return (userItem.email) && ((userItem.email === user.text) || (userItem.email === user.email));
            }).length;
          }

          /**
           * @name onUserAddClick
           * @param {*} e
           *
           * @description
           * call inline user add method callback
           */
          function onUserAddClick(e) {
            $ctrl.inlineUsersEdit({ e: e });
          }

          /**
           * @name onGuestAddClick
           * @param {*} e
           *
           * @description
           * call inline guest add method callback
           */
          function onGuestAddClick(e) {
            $ctrl.inlineGuestsEdit({ e: e });
          }

          function onGuestAssigneeConfirm(invite, data) {
            if (invite) {
              var modal = $uibModal.open({
                component: 'inviteUserComponent',
                windowClass: 'member-invite-modal',
                resolve: {
                  email: function () {
                    return data.text;
                  },
                  oneTime: true
                }
              });
              modal.result.then(function (res) {
                data.status = res.invited ? 'invited' : angular.noop();
                data.showGuestInvitePrompt = false;
                recentGuests.invited.push(data.text);
              }, function () {
                data.showGuestInvitePrompt = false;
              });
            } else {
              data.showGuestInvitePrompt = false;
            }
          }

          /**
           * @name onAssignSelfClick
           * @param {*} e
           *
           * @description
           * call assign my self callback
           */
          function onAssignSelfClick(e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            onUserAdded(_.find($ctrl.usersInOrg, { id: $rootScope.identity.id || $ctrl.viewerId }));
            $ctrl.assignSelfCallback({ reInitAssignee: true });
            $ctrl.assignClicked = true;
          }

          function checkCurrentUserPresentInAGroup() {
            $ctrl.isCurrentUserPresentInAGroup = ($ctrl.groupsAssigned && $ctrl.groupsAssigned.length && Helper.isMemberPresentInGroup($ctrl.currentUser, angular.copy($ctrl.groupsAssigned), $ctrl.orgGroups, false, false));
          }

          /**
           * @name onIconClick
           * @param {*} $event
           *
           * @description
           * Click on assign user icon
           */
          function onIconClick($event) {
            $event.preventDefault();
            if ($ctrl.taskStatus !== 'completed') {
              $ctrl.editTask({ event: $event, editMode: true });
            }
          }

          function onClaimTask(e) {
            $('body').addClass('claim-task');
            $confirm({
              'header': $filter('translate')('compact.task.detail.form.label.takeOver'),
              'subtitletext': $filter('translate')('compact.task.detail.form.label.removeEveryoneElse'),
              'body': $filter('translate')('compact.task.detail.form.label.takeOverHintConfirm'),
              'buttons': {
                'accept': $filter('translate')('global.confirmAction.buttons.accept', { actionName: 'Take Over' }),
                'cancel': 'tags.confirmAction.buttons.cancel'
              },
              'modalType': 'modal-claim-task'
            }).then(function () {
              $('body').removeClass('claim-task');
              $ctrl.coworkersAssigned = [];
              $ctrl.groupsAssigned = [];
              $ctrl.coworkersAssigned.push($ctrl.currentUser.id);
              $timeout(function () {
                $ctrl.onTaskSave(e, false);
              }, 10);
            }, function () {
              $('body').removeClass('claim-task');
            });
          }

          function onTaskSaveHandler(e, reInitAssignee) {
            if ($ctrl.launchAssigneePopup) {
              $ctrl.coworkersAssigned = [];
              $ctrl.guestsAssigned = [];
              $ctrl.groupsAssigned = [];
              _.forEach($ctrl.assigneeList, function(assign) {
                if(assign.type === 'coworker') {
                  $ctrl.coworkersAssigned.push(assign.id)
                }
                if(assign.type === 'group') {
                  $ctrl.groupsAssigned.push(assign.id)
                }
              });
              _.forEach($ctrl.guestList, function(guest) {
                $ctrl.guestsAssigned.push(guest.id)
              });
            }
            $timeout(function () {
              $ctrl.onTaskSave(e, reInitAssignee);
            }, 10);
            if ((typeof $ctrl.onTaskSave === 'function') && $ctrl.editMode && !$ctrl.isGuest) {
              var guestsList = _.compact($ctrl.guestsAssigned) || [];
              $timeout(function () {
                Helper.isNewGuestAddedProcessAndReturn(guestsList).then(function (res) {
                  if (res.isNewGuestAdded) {
                    $ctrl.guestsInOrg = res.updatedGuests;
                  }
                });
              }, 3000);
            }
          }

          unRegisteredHandlerStepGuestWatcher = $scope.$watch('$ctrl.isStepGuestAssigned', function () {
            $scope.$applyAsync(setAssigneeList());
          }, true);

          // watch for coworkersAssigned
          unRegisteredHandlerCoworkerWatcher = $scope.$watchCollection('$ctrl.coworkersAssigned', function () {
            $scope.$applyAsync(setAssigneeList());
          });

          // watch for groupsAssigned
          unRegisteredHandlerGroupWatcher = $scope.$watchCollection('$ctrl.groupsAssigned', function () {
            $scope.$applyAsync(setAssigneeList());
          });

          // watch for guestsAssigned
          unRegisteredHandlerGuestWatcher = $scope.$watchCollection('$ctrl.guestsAssigned', function () {
            $scope.$applyAsync(setAssigneeList());
          });

          // watch for roleAssigned
          unRegisteredHandlerRoleWatcher = $scope.$watchCollection('$ctrl.assignedRoles', function () {
            $scope.$applyAsync(setAssigneeList());
          });
        }
    });
})();
