/**
 * @ngdoc Controller
 * @name tallyfy.Controller.ApplicationController
 * @module tallyfy
 *
 * @description
 * ApplicationController is base controller of application
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */

(function () {
  'use strict';
  angular
    .module('tallyfy')
    .controller('ApplicationController', ApplicationController);
  /*@ngInject*/
  function ApplicationController($location, $rootScope, $scope, GuestPreferenceRepository, Growl, $filter, $anchorScroll, $window, $state, _, $analytics, $interval, CONFIG, modal, AccountService, ENV_CONFIG, $log, $translate,
    PLANS, TasksService, RunDetailService, Helper, AuthPlan, ProcessService, preferencesStore, DOMService, appRefresher, $timeout, UsersService, menuActionLinksService, OrganizationsService, FroalaService,
    $cookies, BeaconService, TranslationService, universalSearchService, $stateParams, USER_STATE, $uibModal, ChatlioService, DateUtils, moment, $tallyfyWebSocketFactory, WS, store, $localStorage, $q, UtilsService, realtime, Auth) {
    var $ctrl = this,
      unregisterUpdateFreeTrialHandler,
      unregisterManageSidebarByPlanHandler,
      unRegisterGAIDValueWatcher,
      unRegisteredChatlioStatusHandler,
      unRegisterMixpanelTokenValueWatcher,
      unRegisteredWsMessageWatcher,
      unRegisteredWsReadyHandler,
      unRegisterIdentityWatcher,
      unRegisterLiveChatSupportWatcher,
      unRegisteredOrgIdWatcher,
      unRegisteredOrgAzureCognitiveServiceConfigWatcher,
      unRegisteredPreferenceWatcherHandler,
      unRegisteredGuestOrganizations,
      unRegisteredGuestDefaultOrganization,
      unRegisteredGlobalCssOrganization,
      unRegisteredSupportBarMessageHandler,
      unRegisteredNavFavoritesHandler,
      unRegisteredPubnubUsersGuestsAndGroupsHandler,
      growl = new Growl(),
      globalStyleId,
      leftNavHandler,
      chatlioStatus = {},
      syncDataTimeoutHandlers = {
        users: null,
        guests: null,
        groups: null
      },
      syncDataTimeoutVal = 15000;

    appRefresher.init();

    /*
     * @access public
     * public methods
     */
    $ctrl.isIE = isIE();
    $ctrl.isSmart = isSmart();
    $ctrl.getPreferences = getPreferences;
    $ctrl.isMasquarade = Helper.isMasquarade;
    $ctrl.isDrawerOpen = isDrawerOpen;
    $ctrl.isTemplateDrawer = isTemplateDrawer;
    $ctrl.checkPlan = checkPlan;
    $ctrl.openBluePrintModal = ProcessService.openBluePrintModal;
    $ctrl.bluePrintTypes = ProcessService.getBlueprintTypes();
    $ctrl.chatWithExpert = Helper.showCalendlyModal;
    $ctrl.$onDestroy = onDestroy;
    $ctrl.openInviteModal = UsersService.openInviteModal;
    $ctrl.stopNextEvent = stopNextEvent;
    $ctrl.avatar = {
      profile_pic: '',
      text: ''
    };
    $ctrl.isForceOnboardingEnable = ProcessService.isForceOnboarding;
    $ctrl.getOnboardingCompletedFlow = getOnboardingCompletedFlow;
    $ctrl.trialPlans = AuthPlan.getPlans();
    $ctrl.tallyfyQuickTutorial = tallyfyQuickTutorial;
    $ctrl.leavetutorial = leavetutorial;
    $ctrl.toggleHSIcon = toggleHSIcon;
    $ctrl.closeHSPopup = closeHSPopup;
    $ctrl.hsBeaconPopupStatus = BeaconService.getStatus;
    $ctrl.openGlobalSearchModal = universalSearchService.openGlobalSearchModal;
    $ctrl.shouldAddGreyBG = shouldAddGreyBG;
    $ctrl.onChatlioChatClick = onChatlioChatClick;
    $ctrl.onWatchIntroModal = onWatchIntroModal;
    $ctrl.onShowExample = onShowExample;
    $ctrl.menuResizeHandler = menuResizeHandler;
    $ctrl.setOverlayActive = setOverlayActive;
    $ctrl.scrollDown = scrollDown;
    $ctrl.toggleLeftNav = toggleLeftNav;
    $ctrl.toggleSubMenu = toggleSubMenu;
    $ctrl.saveAppLanguage = saveAppLanguage;
    $ctrl.saveContentLanguage = saveContentLanguage;
    $ctrl.getLanguageLabel = getLanguageLabel;
    $ctrl.languages = TranslationService.getAppLanguages();
    $ctrl.contentLanguages = TranslationService.getContentLanguages();
    $ctrl.appLanguageDropdownClick = appLanguageDropdownClick;
    $ctrl.contentTranslatingCheck = contentTranslatingCheck;
    $ctrl.bodyClickCallback = bodyClickCallback;
    $ctrl.guestChangeStateAndReload = guestChangeStateAndReload;
    $ctrl.onAppExplanationClose = onAppExplanationClose;

    /*
     * @access public
     * public properties
     */
    $ctrl.tallyfySupportUrl = ENV_CONFIG.TALLYFY_SUPPORT_URL;
    $ctrl.tallyfyChangelogUrl = ENV_CONFIG.TALLYFY_CHANELOG_URL;
    $ctrl.tallyfyUrl = CONFIG.MARKETING_URL;
    $ctrl.isPaymentStatePastDue = false;
    $ctrl.isVisibleSubMenu = false;
    $ctrl.bookingUrl = ENV_CONFIG.TALLYFY_BOOKING_URL;
    $ctrl.guideLink = ENV_CONFIG.TALLYFY_GUIDE_LINK;
    $ctrl.showMoreFavorites = null;
    $ctrl.favoritesLimit = 5;
    $ctrl.displayShowMoreButton = false;
    $ctrl.showLeftNav = false;
    $ctrl.showAppExplanation = false;
    $ctrl.isRedirectToOrgTemplates = true;

    // config
    $ctrl.app = {
      name: 'Tallyfy',
      version: '2.0.0',
      // for chart colors
      color: {
        'primary': '#0cc2aa',
        'accent': '#a88add',
        'warn': '#fcc100',
        'info': '#6887ff',
        'success': '#6cc788',
        'warning': '#f77a99',
        'danger': '#f44455',
        'white': '#ffffff',
        'light': '#f1f2f3',
        'dark': '#2e3e4e',
        'black': '#2a2b3c'
      },
      setting: {
        theme: {
          primary: 'primary',
          accent: 'accent',
          warn: 'warn'
        },
        folded: false,
        boxed: false,
        container: false,
        themeID: 4,
        bg: ''
      }
    };

    setColor();

    function setColor() {
      $ctrl.app.setting.color = {
        primary: getColor($ctrl.app.setting.theme.primary),
        accent: getColor($ctrl.app.setting.theme.accent),
        warn: getColor($ctrl.app.setting.theme.warn)
      };
    }

    /**
     * @function
     * @name onDestroy
     * @description destroy event handler
     */
    function onDestroy() {
      unregisterUpdateFreeTrialHandler();
      unregisterManageSidebarByPlanHandler();
      unRegisteredOrgIdWatcher();
      if (_.toLower(ENV_CONFIG.APP_ENV) === 'prod') {
        unRegisterGAIDValueWatcher();
      }
      unRegisteredChatlioStatusHandler();
      unRegisterMixpanelTokenValueWatcher();
      unRegisteredWsMessageWatcher();
      unRegisteredWsReadyHandler();
      leftNavHandler();
      unRegisterIdentityWatcher();
      unRegisterLiveChatSupportWatcher();
      unRegisteredOrgAzureCognitiveServiceConfigWatcher();
      unRegisteredPreferenceWatcherHandler();
      unRegisteredGuestOrganizations();
      unRegisteredGuestDefaultOrganization();
      unRegisteredGlobalCssOrganization();
      unRegisteredSupportBarMessageHandler();
      unRegisteredNavFavoritesHandler();
      unRegisteredPubnubUsersGuestsAndGroupsHandler();
      if (syncDataTimeoutHandlers.users) {
        $timeout.cancel(syncDataTimeoutHandlers.users);
      }
      if (syncDataTimeoutHandlers.guests) {
        $timeout.cancel(syncDataTimeoutHandlers.guests);
      }
      if (syncDataTimeoutHandlers.groups) {
        $timeout.cancel(syncDataTimeoutHandlers.groups);
      }
    }

    function getColor(name) {
      return $ctrl.app.color[name] ? $ctrl.app.color[name] : palette.find(name);
    }

    $rootScope.$on('$stateChangeSuccess', openPage);

    function openPage(event, toState, toParams, fromState) {
      $ctrl.isMobileDevice = Helper.isMobileDevice();
      $ctrl.currentPage = _.get(toState, 'name', 'process.templates');
      $ctrl.userState = $rootScope.userState;
      $ctrl.organizationId = $ctrl.userState === USER_STATE.GUEST
        ? _.get($rootScope.identity, 'guest.organization.id')
        : _.get($rootScope.identity, 'default_organization.id');
      if ($ctrl.organizationId && $ctrl.userState === USER_STATE.MEMBER) {
        store.getUsers().then(function (data) {
          $ctrl.canCreateOrEditTemplates = UtilsService.canCreateOrEditTemplates();
        });
      }
      if ($ctrl.userState === USER_STATE.GUEST) {
        $ctrl.registerURL = ENV_CONFIG.AUTH_HOST + '/register?utm_source=guest-view&utm_medium=guest-view&utm_campaign=' + _.get($rootScope.identity, 'guest.organization.name') + '&utm_content=' + _.get($rootScope.identity, 'guest.organization.id');
      }
      $ctrl.userId = _.get($rootScope.identity, 'id');
      // goto top
      $location.hash('content');
      $anchorScroll();
      $location.hash('');
      // hide open menu
      $('#aside').modal('hide');
      $('body').removeClass('modal-open').find('.modal-backdrop').remove();
      $('.navbar-toggleable-sm').collapse('hide');
      $('body').find('.modal-menu-overlay').remove();
      $ctrl.isDocsPlan = AuthPlan.isRestrictedWithDocsPlan();
      $ctrl.selectedTrialPlan = _.find($ctrl.trialPlans, { 'name': _.get($ctrl.organization, 'preferred_trial_plan') || _.first($ctrl.trialPlans).name });
      $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
      $ctrl.tallyfyRecurlyUrl = _.get($rootScope.identity, 'default_organization.subscription.data.account_manage_url');
      if ($ctrl.userState === USER_STATE.MEMBER && $rootScope.identity && !$localStorage.isAppExplanationDismissed) {
        $ctrl.showAppExplanation = Helper.getDiffInDays($rootScope.identity.activated_at) <= 1;
      }
      checkScroll();
      if ($ctrl.userState === USER_STATE.MEMBER && (!(fromState && fromState.name) || $ctrl.currentPage === 'dashboard')) {
        setSwitchOrgInMenu();
      }
    }

    $ctrl.goBack = function () {
      $window.history.back();
    };

    function changeLanguage() {
      $timeout(function () {
        $translate.use($ctrl.appLanguage);
      }, 100);
    }

    function saveAppLanguage(e) {
      e.stopImmediatePropagation();
      if (!Helper.checkAccessAuthority())
        return;
      if (!$ctrl.appLanguageEditMode) {
        $ctrl.appLanguageEditMode = !$ctrl.appLanguageEditMode;
      } else {
        $ctrl.appLanguageSaved = true;
        Growl.clearAllMessages('global');
        var request = ($rootScope.userState === USER_STATE.MEMBER) ?
          AccountService.setUserPreference({
            slug: "app_language",
            value: $ctrl.appLanguage ? 'yes' : 'no',
            metadata: $ctrl.appLanguage ? { lang: $ctrl.appLanguage } : void 0
          }) : ($rootScope.userState === USER_STATE.GUEST)
            ? GuestPreferenceRepository.updatePreferences({
              slug: "app_language",
              value: !!$ctrl.appLanguage,
              metadata: $ctrl.appLanguage ? { lang: $ctrl.appLanguage } : void 0
            }) : void 0;
        if (request) {
          request.then(
            function (response) {
              if ($rootScope.userState === USER_STATE.MEMBER) {
                preferencesStore.updatePreferences(response.data);
                $ctrl.appLanguageEditMode = false;
                $ctrl.appLanguageSaved = false;
                $timeout(function () {
                  showSavedAlert();
                }, 100);
              } else {
                var preference = _.find($rootScope.identity.guest.preferences, { slug: 'app_language' });
                if (preference) {
                  preference.metadata.lang = $ctrl.appLanguage;
                } else {
                  $rootScope.identity.guest.preferences.push(response.data);
                }
                $ctrl.appLanguageEditMode = false;
                $ctrl.appLanguageSaved = false;
                $timeout(function () {
                  showSavedAlert();
                }, 100);
              }
            }, function () {
              $ctrl.appLanguageSaved = false;
            });
        }
      }
    }

    function saveContentLanguage(e) {
      e.stopImmediatePropagation();
      if (!Helper.checkAccessAuthority() || !$ctrl.isAzureCognitiveServiceEnabled)
        return;
      if (!$ctrl.contentLanguageEditMode) {
        $ctrl.contentLanguageEditMode = !$ctrl.contentLanguageEditMode;
      } else {
        $ctrl.contentLanguageSaved = true;
        Growl.clearAllMessages('global');
        var request = ($rootScope.userState === USER_STATE.MEMBER) ?
          AccountService.setUserPreference({
            slug: "content_language",
            value: $ctrl.contentLanguage ? 'yes' : 'no',
            metadata: $ctrl.contentLanguage ? { lang: $ctrl.contentLanguage } : void 0
          }) : ($rootScope.userState === USER_STATE.GUEST) ? GuestPreferenceRepository.updatePreferences({
            slug: 'content_language',
            value: !!$ctrl.contentLanguage,
            metadata: $ctrl.contentLanguage ? { lang: $ctrl.contentLanguage } : void 0
          }) : void 0;
        if (request) {
          request.then(
            function (response) {
              if ($rootScope.userState === USER_STATE.MEMBER) {
                preferencesStore.updatePreferences(response.data);
                $ctrl.contentLanguageEditMode = false;
                $ctrl.contentLanguageSaved = false;
                $rootScope.$emit('CONTENT_LANGUAGE_CHANGED');
                $timeout(function () {
                  showSavedAlert();
                }, 100);
              } else {
                var preference = _.find($rootScope.identity.guest.preferences, { slug: 'content_language' });
                if (preference) {
                  preference.metadata.lang = $ctrl.contentLanguage;
                } else {
                  $rootScope.identity.guest.preferences.push(response.data);
                }
                $ctrl.contentLanguageEditMode = false;
                $ctrl.contentLanguageSaved = false;
                $rootScope.$emit('CONTENT_LANGUAGE_CHANGED');
                $timeout(function () {
                  showSavedAlert();
                }, 100);
              }
            }, function () {
              $ctrl.contentLanguageSaved = false;
            });
        }
      }
    }

    function getLanguageLabel() {
      var lang = _.find($ctrl.languages, { value: $ctrl.appLanguage });
      return lang ? lang.label : '';
    }

    function isIE() {
      return !!navigator.userAgent.match(/MSIE/i) || !!navigator.userAgent.match(/Trident.*rv:11\./);
    }

    function isSmart() {
      // Adapted from http://www.detectmobilebrowsers.com
      var ua = $window['navigator']['userAgent'] || $window['navigator']['vendor'] || $window['opera'];
      // Checks for iOs, Android, Blackberry, Opera Mini, and Windows mobile devices
      return (/iPhone|iPod|iPad|Silk|Android|BlackBerry|Opera Mini|IEMobile/).test(ua);
    }

    function setAnalyticsUser(user) {
      if ($ctrl.userState === USER_STATE.GUEST) {
        return;
      }
      var $preference = getPreferences(user.preferences);
      $analytics.setUsername(user.email.toLowerCase());
      $analytics.setAlias(1);

      var analyticUserProperties = {
        "ID": user.email.toLowerCase(),
        "Username": user.username,
        "First Name": user.first_name,
        "Last Name": user.last_name,
        "Name": user.full_name,
        "Email": user.email.toLowerCase(),
        "User Role": user.role,
        "User Total Comments": $preference('user_total_comments'),
        "User Has Started Process": $preference('user_has_started_run'),
        "User Has Created 3 Steps": $preference('user_has_created_3_steps'),
        "User Visited Active Process": $preference('user_visited_active_run'),
        "User Visited Create Template": $preference('user_visited_create_run'),
        "User Number Of Logins": $preference('user_number_of_logins'),
        "Created": user.created_at,
        "Activated": user.is_active,
        "Profile Pic": user.profile_pic,
        "Organization ID": user.default_organization.id,
        "Organization Name": user.default_organization.name,
        "Number Of Org Memberships": user.organizations.length,
        "Plan Code": _.get(user, 'default_organization.subscription.data.plan_code'),
        "User Timezone": user.timezone,
        "Job Title": user.job_title,
        "Job Description": user.job_description,
        "Team": user.team,
        "Phone Number": user.phone,
        "Country": _.get(user, 'country.name')
      };

      if (user.organizations.length > 1) {
        analyticUserProperties["Number Of Users In Smallest Org"] = getSmallestOrganization(user.organizations);
        analyticUserProperties["Number Of Users In Largest Org"] = getLargestOrganization(user.organizations);
      }
      else {
        analyticUserProperties["Number Of Active Users In Org"] = _.get(user, 'default_organization.subscription.data.quantity');
      }

      if ($rootScope.identity.default_organization.owner_id === user.id) {
        analyticUserProperties = _.assign(analyticUserProperties, { "Account Owner": true });
      }

      if (mixpanel) {
        mixpanel.init(ENV_CONFIG.MIXPANEL_TOKEN, { ignore_dnt: true });
      }

      var mixpanelUserProperties = _.mapKeys(analyticUserProperties, function (value, key) {
        key = key.toLowerCase();
        key = '$' + _.split(key, ' ').join('_');
        return key;
      });
      $analytics.setUserProperties(mixpanelUserProperties);
      if (_.toLower(ENV_CONFIG.APP_ENV) === 'prod') {
        $window.gtag('set', { 'user_id': user.email.toLowerCase() });
        $window.ga('set', 'userId', user.email.toLowerCase());
      }
    }

    function setGoogleAnalyticsForNewUser() {
      if (!$rootScope.identity) {
        return;
      }
      // Trigger GA for UA-23300407-1, if user is max of 14 days old
      $window.ga('tracker2.remove');
      if (Helper.getDiffInDays($rootScope.identity.created_at) < 15) {
        $window.ga('create', 'UA-23300407-1', 'auto', 'tracker2');
        $window.ga('tracker2.send', 'pageview');
      }
    }
    /**
    * @function
    * @name getPreferences
    * @description helper function which converts array in map set. make slug as a key
    * @param {any} preferences
    * @returns clouser
    */
    function getPreferences(preferences) {
      var _preferences = _.keyBy(preferences, 'slug');
      return function (key) {
        return _preferences[key] ? _preferences[key]['value'] : null;
      };
    }

    function setSentryUser(user) {
      Sentry.setUser(user ? { id: user.id, email: user.email } : null);
    }

    /**
     * @function
     * @name setHelpScoutUser
     * @description Pre-populate Beacon’s contact form
     * @param {object} user
     */
    function setHelpScoutUser(user) {
      var fullName = _.get(user, 'full_name'),
        email = _.get(user, 'email');

      if (fullName && email) {
        BeaconService.setIdentity({
          name: user.full_name,
          email: user.email,
          "First Name": user.first_name,
          "Last Name": user.last_name,
          "Organization ID": _.get(user, 'default_organization.id'),
          "Organization Name": _.get(user, 'default_organization.name'),
          "Number of Active Users": _.get(user, 'default_organization.users_count')
        });
      }
    }

    function setTrackJs(user) {
      if ($window.trackJs) {
        $window.trackJs.configure({
          userId: _.toString(user.id)
        });
        $window.trackJs.addMetadata("email", user.email);
      }
    }

    /**
     * keep watching on identity.preferences
     * set Analytics user properties
     */
    $rootScope.$watch('identity.preferences', function (newIdentity, oldIdentity) {
      if (!((_.isArray(newIdentity) && newIdentity.length === 0) || (newIdentity === oldIdentity) || !newIdentity)) {
        setUserPreferences();
        $timeout(function () {
          BeaconService.initializeHSBeacon();
          BeaconService.close();
        }, 2000);
      }
    }, true);

    function setUserPreferences() {
      $log.info('Current UserID:', $rootScope.identity.id);
      setHelpScoutUser($rootScope.identity);
      setSentryUser($rootScope.identity);
      setAnalyticsUser($rootScope.identity);
      setTrackJs($rootScope.identity);
      countTrialDays($rootScope.identity.default_organization);
      setMenuActionLinkData();
      ChatlioService.setIdentity($rootScope.identity.username, {
        orgId: $rootScope.identity.default_organization.id,
        orgName: $rootScope.identity.default_organization.name,
        firstName: $rootScope.identity.first_name,
        lastName: $rootScope.identity.last_name,
        email: $rootScope.identity.email
      });
      $ctrl.appLanguage = TranslationService.getMyAppLanguage($rootScope.userState === USER_STATE.MEMBER ? $rootScope.identity.preferences : _.get($rootScope.identity, 'guest.preferences', {}));
      $ctrl.contentLanguage = TranslationService.getMyContentLanguage();
    }

    $rootScope.$on('authenticationSuccess', getLoginPreferences);

    function getLoginPreferences() {
      preferencesStore.getPreferences().then(function (pref) {
        //get total number of time user logged-in.
        var loginAttempts = AccountService.getPreference(pref, 'user_number_of_logins', 1);
        // open upgrade modal
        if (((loginAttempts % CONFIG.UPGRADE_INTERSTITIAL_RATE) === 0) && checkPlan()) {
          modal.show({
            'header': 'global.upgradeAccount.header',
            'body': 'global.upgradeAccount.body'
          });
        }
        setUserPreferences();
      });
    }

    /**
     * @function
     * @name checkPlan
     * @description will check plan type either it is free or navigator
     * @returns boolean value
     * */
    function checkPlan() {
      return AuthPlan.getCurrentPlanCode() === PLANS.FREE;
    }
    /**
     * @ngdoc method
     * @name isDrawerOpen
     * @description  Check if run more detail drawer is open or not
     * @returns void
     */
    function isDrawerOpen() {
      return RunDetailService.isDrawerOpen();
    }

    /**
     * @ngdoc method
     * @name isTemplateDrawer
     * @description  Check if state is "process.edit" and drawer is open/not
     * @returns {Boolean} true/false
     */
    function isTemplateDrawer() {
      return $state.is('process.edit') && $ctrl.isDrawerOpen();
    }

    /**
     * @ngdoc method
     * @name shouldAddGreyBG
     * @description  Check the states to add Grey BG to the app body
     * @returns {Boolean} true/false
     */
    function shouldAddGreyBG() {
      return $state.is('public.library.organization.detail') || $state.is('public.library.combo.detail') || $state.is('run.view') || ($state.is('process.edit') && ($stateParams.view === 'edit')) || $state.is('guest.runs.view') || $state.is('public.process.detail');
    }

    /**
     * @name getSmallestOrganization
     * @param {*} organizations
     * return number
     *
     * @description
     * get smallest number of organization subscription
     */
    function getSmallestOrganization(organizations) {
      return _.minBy(organizations, function (org) {
        return org.subscription.data.quantity;
      }).subscription.data.quantity;
    }

    /**
     * @name getLargestOrganization
     * @param {*} organizations
     * return number
     *
     * @description
     * get largest number of organization subscription
     */
    function getLargestOrganization(organizations) {
      return _.maxBy(organizations, function (org) {
        return org.subscription.data.quantity;
      }).subscription.data.quantity;
    }

    /**
     * @function
     * @name updateAvatar
     * @description update $ctrl.avatar
     * @private
     * @returns void
     */
    function updateAvatar() {
      var profile_pic, text;
      profile_pic = TasksService.setDefaultAvatar($rootScope.identity.profile_pic);
      text = TasksService.setDefaultAvatarText($rootScope.identity.first_name, $rootScope.identity.last_name);
      angular.extend($ctrl.avatar, {
        profile_pic: profile_pic,
        text: text
      });
    }

    /**
     * watching for user identity value
     */
    unRegisterIdentityWatcher = $rootScope.$watch('identity', function (newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      if (newValue && oldValue && newValue.id && oldValue.id && (newValue.id === oldValue.id)) {
        return;
      }
      if (_.toLower(ENV_CONFIG.APP_ENV) === 'prod') {
        setGoogleAnalyticsForNewUser();
      }
      var payment_state = _.get(newValue, 'default_organization.payment_state', '');
      $ctrl.isPaymentStatePastDue = (payment_state.toLowerCase() === 'past_due');
      newValue && updateAvatar();
      $ctrl.organization = _.get($rootScope.identity, 'default_organization', {});
      $ctrl.userState = $rootScope.userState;
      if ($ctrl.userState === USER_STATE.GUEST) {
        $ctrl.organizationId = _.get($rootScope.identity, 'guest.organization.id');
        $ctrl.guestCode = $stateParams.guest_code || $rootScope.identity.guest.guest_code;
      }
      if ($ctrl.userState) {
        $tallyfyWebSocketFactory.$init();
      }
      $ctrl.isOrganizationInTrial = _.get($rootScope, 'identity.default_organization.in_trial', false);
      if ($ctrl.isOrganizationInTrial) {
        ProcessService.getProcess({ org: $ctrl.organization.id, page: 1, per_page: 10 }).then(function (response) {
          $ctrl.isRedirectToOrgTemplates = _.get(response, 'data').length > 1;
        });
      } else {
        $ctrl.isRedirectToOrgTemplates = true;
      }
      DOMService.toggleChatlioVisibility(true);
    }, true);

    unregisterUpdateFreeTrialHandler = $rootScope.$on('UPDATE_FREE_TRIAL_DAYS', function () {
      countTrialDays($rootScope.identity.default_organization);
    });

    unregisterManageSidebarByPlanHandler = $rootScope.$on('MANAGE_SIDEBAR_BY_PLAN', function (e, trialPlan) {
      $ctrl.isProcessMenuHidden = AuthPlan.isRestrictedWithDocsPlan();
      if (trialPlan) {
        $ctrl.selectedTrialPlan = trialPlan;
      }
    });

    function countTrialDays(org) {
      if ($ctrl.userState === USER_STATE.GUEST) {
        return;
      }
      $ctrl.organization = _.get($rootScope.identity, 'default_organization', {});
      $ctrl.isProcessMenuHidden = AuthPlan.isRestrictedWithDocsPlan();
      $ctrl.trialMessage = null;
      $ctrl.isFreePlan = _.get(org, 'subscription.data.plan_code') === PLANS.FREE;
      var timeDiff = Helper.getDiffInDays(org.created_on);
      $ctrl.pendingTrialDays = org.free_trial_days > 0 ? (timeDiff <= org.free_trial_days ? true : false) : false;
      if ($ctrl.pendingTrialDays) {
        $ctrl.trialDay = org.free_trial_days - timeDiff;
        $ctrl.trialEndedMsgMobile = false;
      } else {
        $ctrl.trialEndedMsgMobile = true;
      }
    }

    /**
     * @function
     * @name setMenuActionLinkData
     * @description Initialize data for menu
     * @returns {Void}
     */
    function setMenuActionLinkData() {
      $ctrl.dropDownOptions = menuActionLinksService.getDropdownOptions();
      $ctrl.hotkeyCombos = [{
        key: 84 /*t*/,
        callback: menuActionLinksService.standaloneModal
      }, {
        key: 80 /*p*/,
        callback: menuActionLinksService.goToNewProcess
      }];
    }

    function setSwitchOrgInMenu() {
      var orgList = [], requestParams = { per_page: 999, page: 1 };
      OrganizationsService.myOrganizations().then(function (res) {
        if (res.data.length) {
          orgList = _.concat(orgList, res.data);
        }
        if (!_.get($rootScope, 'identity.email')) {
          $ctrl.isSwitchOrgVisible = false;
          return;
        }
        OrganizationsService.myGuestOrganizations({ action: $rootScope.identity.email }, requestParams).then(function (response) {
          if (response.data.length) {
            orgList = _.concat(orgList, response.data);
          }
          $ctrl.isSwitchOrgVisible = (orgList.length > 1);
        }, function (error) {
          $ctrl.isSwitchOrgVisible = (orgList.length > 1);
        });
      }, function (err) {
        $ctrl.isSwitchOrgVisible = false;
      });
    }

    /**
     * @function
     * @name stopNextEvent
     * @param {*} event
     * @param {Integer} id
     * @returns {Void}
     */
    function stopNextEvent(event, id) {
      $('body').removeClass('modal-open').find('.modal-backdrop').remove();
      ($window.innerWidth < 1280 && id === 2) ? event.stopPropagation() : openPage();
    }

    /**
     * @function
     * @name getOnboardingCompletedFlow
     * @description get onboarding flow is completed or not
     * @param {String} completeFlow
     */
    function getOnboardingCompletedFlow(completeFlow) {
      if (completeFlow === 'HasCreatedBP') {
        return !$ctrl.isForceOnboardingEnable('HasCreatedBP');
      }
      if (completeFlow === 'HasLaunchedBP') {
        return !$ctrl.isForceOnboardingEnable('HasLaunchedBP') && !$ctrl.isForceOnboardingEnable('HasCreatedBP');
      }
      if (completeFlow === 'HasCompletedProcess') {
        return !$ctrl.isForceOnboardingEnable('HasCompletedProcess') && !$ctrl.isForceOnboardingEnable('HasLaunchedBP') && !$ctrl.isForceOnboardingEnable('HasCreatedBP');
      }
    }

    /**
     * @function
     * @name popStateListener
     * @description fire when pop state
     */
    function popStateListener() {
      if (ProcessService.isForceOnboarding()) {
        $window.history.forward(1);
      } else {
        $window.removeEventListener('popstate', popStateListener);
      }
    }

    if (_.toLower(ENV_CONFIG.APP_ENV) === 'prod') {
      unRegisterGAIDValueWatcher = $rootScope.$watch('identity.default_organization.google_analytics_id', function (newValue) {
        var orgId = _.get($rootScope.identity, 'default_organization.id');
        Helper.removeOrgGoogleAnalyticsTracker(orgId);
        if (newValue && orgId) {
          Helper.setOrgGoogleAnalyticsTracker(orgId, newValue, $rootScope.identity);
        }
      });
    }

    unRegisterMixpanelTokenValueWatcher = $rootScope.$watch('identity.default_organization.mixpanel_token', function (newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      var mixpanelToken = _.get($rootScope, 'identity.default_organization.mixpanel_token');
      if (mixpanelToken) {
        mixpanel.init(mixpanelToken, { ignore_dnt: true }, 'organization_mixpanel_project');
      }
    });

    /**
     * popstate listener
     */
    $window.addEventListener('popstate', popStateListener);

    /**
     * @ngdoc method
     * @name tallyfyQuickTutorial
     * @description go to tallyfy quick tutorial
     */
    function tallyfyQuickTutorial() {
      $ctrl.goBackUrlName = $rootScope.toState.name;
      $ctrl.goBackUrlParams = $rootScope.toStateParams;
      ProcessService.setForceOnboardingFlowData();
      $state.go('process.create', {
        blueprintType: 'quickProcedure'
      });
    }

    /**
     * @ngdoc method
     * @name leavetutorial
     * @description leave tallyfy tutorial
     */
    function leavetutorial() {
      $cookies.remove('forcedOnboardingFlow');
      $(".app").removeClass("user-onboarding");
      if ($ctrl.goBackUrlName) {
        $state.go($ctrl.goBackUrlName, $ctrl.goBackUrlParams);
      } else {
        $state.go('process.templates');
      }
    }

    /**
     * @ngdoc method
     * @name toggleHSIcon
     * @description
     * toggle HS Beacon menu
     */
    function toggleHSIcon() {
      BeaconService.togglePopup();
    }

    /**
     * @ngdoc method
     * @name closeHSPopup
     * @description
     * close HS Beacon menu
     */
    function closeHSPopup() {
      BeaconService.close();
    }

    function onChatlioChatClick() {
      ChatlioService.showOrHideWidget();
    }

    /**
     * @ngdoc method
     * @name onWatchIntroModal
     * @description view intro video modal
     */
    function onWatchIntroModal() {
      menuActionLinksService.watchIntroModal();
    }

    /**
     * @ngdoc method
     * @name onShowExample
     * @param {*} subMenu
     *
     * @description show example menu
     */
    function onShowExample(subMenu) {
      $ctrl.activeSubMenu = $ctrl.activeSubMenu === subMenu ? '' : subMenu;
    }

    // Toggle submenu
    function toggleSubMenu() {
      $ctrl.showSubMenus = !$ctrl.showSubMenus;
      $ctrl.activeSubMenu = '';
    }

    function menuResizeHandler(e) {
      var parent = angular.element('#nav-parent-controller');
      var menu = parent.find('.nav');
      if (!menu.length) {
        return;
      }
      if (menu[0].scrollHeight <= menu[0].clientHeight) {
        parent.removeClass('scrollbar-active')
          .removeClass('top-offset-active')
          .removeClass('bottom-offset-active');
      } else {
        parent.addClass('scrollbar-active');
        menu[0].scrollTop > 60
          ? parent.addClass('top-offset-active') : parent.removeClass('top-offset-active');
        (Math.ceil(menu[0].scrollTop + menu[0].clientHeight) < menu[0].scrollHeight)
          ? parent.addClass('bottom-offset-active') : parent.removeClass('bottom-offset-active');
      }
    }

    function setOverlayActive(val) {
      if ($ctrl.showLeftNav) {
        return;
      }
      if ($ctrl.userState === 'MEMBER') {
        if (val === true) {
          $('.nav-active-primary').addClass('nav-overlay-active');
        }
        else {
          $('.nav-active-primary').removeClass('nav-overlay-active');
        }
      }
    }

    function scrollDown() {
      document.getElementById('createNav').scrollBy(0, 200);
    }

    function guestChangeStateAndReload() {
      $timeout(function () {
        $window.location.reload();
      }, 1000);
      $state.go('guest.tasks', { org_id: $rootScope.identity.guest.organization.id, guest_code: $rootScope.identity.guest.guest_code });
    }

    function setChatlioVisibility(isChatLioOnline, liveSupportEnabled) {
      $ctrl.isChatlioOnline = isChatLioOnline && liveSupportEnabled;
      if ($ctrl.isChatlioOnline) {
        $('body').addClass('chatlio-active');
      } else {
        $('body').removeClass('chatlio-active');
      }
    }

    function showSavedAlert() {
      growl.success($filter('translate')('myAccount.messages.update.account'), {
        referenceId: 'global',
        disableIcons: true,
        disableCloseButton: true
      });
    }

    function setMyLanguage() {
      changeLanguage();
    }

    function appLanguageDropdownClick() {
      $ctrl.dropdownLanguageOpen = !$ctrl.dropdownLanguageOpen;
      $ctrl.appLanguage = TranslationService.getMyAppLanguage($rootScope.userState === USER_STATE.MEMBER ? $rootScope.identity.preferences : $rootScope.identity.guest.preferences);
      $ctrl.contentLanguage = TranslationService.getMyContentLanguage();
      if (!$ctrl.dropdownLanguageOpen) {
        $ctrl.appLanguageEditMode = false;
        $ctrl.contentLanguageEditMode = false;
      }
    }

    function contentTranslatingCheck() {
      return TranslationService.geTotalContentTranslationInProgress();
    }

    function isAzureConfigEnabled(orgInfo) {
      var result = false;
      if (orgInfo) {
        var config = OrganizationsService.getAzureCognitiveServiceConfig(orgInfo);
        if (!Helper.isObjectEmpty(config)) {
          result = config.key && config.resource_name && config.region;
        }
      }
      return result;
    }

    function bodyClickCallback(e) {
      var element = e.target.closest('.language-dropdown');
      if (!Helper.isObjectEmpty(element)) {
        e.stopImmediatePropagation();
        $ctrl.appLanguageDropdownClick();
      } else if (e.srcElement.className === 'modal-menu-overlay' && $ctrl.showLeftNav) {
        $ctrl.toggleLeftNav(e, false);
      } else {
        $ctrl.appLanguage = TranslationService.getMyAppLanguage(
          $rootScope.userState === USER_STATE.MEMBER
            ? _.get($rootScope.identity, 'preferences', {})
            : _.get($rootScope.identity, 'guest.preferences', {})
        );
        $ctrl.contentLanguage = TranslationService.getMyContentLanguage();
      }
    }

    function onAppExplanationClose() {
      $ctrl.showAppExplanation = false;
      $localStorage.isAppExplanationDismissed = true;
    }

    unRegisteredOrgAzureCognitiveServiceConfigWatcher = $rootScope.$watch('identity.default_organization.azure_cognitive_service', function (value, oldValue) {
      $ctrl.isAzureCognitiveServiceEnabled = (value) ? isAzureConfigEnabled($rootScope.identity.default_organization) : false;
    });

    unRegisteredChatlioStatusHandler = $rootScope.$on('CHATLIO:STATUS', function (e, data) {
      chatlioStatus = data;
      setChatlioVisibility(_.get(chatlioStatus, 'isOnline', false), _.get($rootScope.identity, 'default_organization.live_support_enabled'));
    });

    unRegisteredOrgIdWatcher = $rootScope.$watchGroup(['identity.default_organization.id', 'identity.guest.organization.id'], function (newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      Auth.setUserState();
      setMyLanguage();
      $rootScope.organizationTemplates = {};
      var newOrg = newValue[$rootScope.userState === USER_STATE.MEMBER ? 0 : 1],
        oldOrg = oldValue[$rootScope.userState === USER_STATE.MEMBER ? 0 : 1];
      if (oldOrg) {
        if (_.toLower(ENV_CONFIG.APP_ENV) === 'prod') {
          Helper.removeOrgGoogleAnalyticsTracker(oldOrg);
        }
        realtime.resetAndUnsubscribeAll();
      }
      if (newOrg) {
        if (!realtime.isAlreadyInitialized()) {
          realtime.init();
        } else {
          realtime.setCurrentUserID();
        }
        if ($rootScope.userState === USER_STATE.MEMBER) {
          realtime.subscribe('sync_data');
        }

        if (_.toLower(ENV_CONFIG.APP_ENV) === 'prod') {
          var gaId = _.get($rootScope.identity, 'default_organization.google_analytics_id');
          if (gaId) {
            Helper.setOrgGoogleAnalyticsTracker(newOrg, gaId, $rootScope.identity);
          }
          var mixpanelToken = _.get($rootScope, 'identity.default_organization.mixpanel_token');
          if (mixpanel && mixpanelToken) {
            mixpanel.init(mixpanelToken, { ignore_dnt: true }, 'organization_mixpanel_project');
          }
        }
      }
    });

    //Toggle left nav
    function toggleLeftNav(e, val, openAnotherModal) {
      if (val === true && $ctrl.showLeftNav !== val) {
        $ctrl.setOverlayActive(true);
        $ctrl.showLeftNav = val;
        $ctrl.isHover = true;
        $('#aside').addClass('nav-overlay');
        if ($window.innerWidth > 992) {
          $('body').append(angular.element('<div>').addClass('modal-menu-overlay'));
        }
      } else {
        $ctrl.showLeftNav = false;
        $ctrl.isHover = false;
        $('body').find('.modal-menu-overlay').remove();
        $('#aside').removeClass('nav-overlay');
        if (openAnotherModal || (val && $window.innerWidth < 992)) {
          $('#aside').modal('hide');
        }
        $ctrl.isOpenPopOver = null;
        $ctrl.favoritesLimit = 5;
        $ctrl.displayShowMoreButton = true;
        if (e) {
          e.stopPropagation();
        }
        $rootScope.$emit('FOLDER_CLICK:VIEW_BLUEPRINT');
        $ctrl.setOverlayActive(false);
      }
    }

    //Check scroll for +Create container
    function checkScroll() {
      $ctrl.showScrolled = false;
      $timeout(function () {
        var clientHeight, viewHeight, element = document.getElementsByClassName('create-nav')[0];
        if (element) {
          clientHeight = element.clientHeight;
          viewHeight = element.scrollHeight;
          $ctrl.showScrolled = viewHeight > clientHeight;
        }
      }, 200);
    }

    //Process Pubnub Sync data
    function processPubnubSyncData(data) {
      switch (_.get(data, 'action')) {
        case 'members_list_refresh':
          if (syncDataTimeoutHandlers.users) {
            $timeout.cancel(syncDataTimeoutHandlers.users);
          }
          syncDataTimeoutHandlers.users = $timeout(function () {
            store.clearUserStore();
            store.getUsers();
          }, syncDataTimeoutVal);
          break;
        case 'guests_list_refresh':
          if (syncDataTimeoutHandlers.guests) {
            $timeout.cancel(syncDataTimeoutHandlers.guests);
          }
          syncDataTimeoutHandlers.guests = $timeout(function () {
            store.clearGuestsStore();
            store.getLightweightGuests();
          }, syncDataTimeoutVal);
          break;
        case 'groups_list_refresh':
          if (syncDataTimeoutHandlers.groups) {
            $timeout.cancel(syncDataTimeoutHandlers.groups);
          }
          syncDataTimeoutHandlers.groups = $timeout(function () {
            store.clearGroupsStore();
            store.getLightweightGroups();
          }, syncDataTimeoutVal);
          break;
      }
    }

    unRegisteredGuestDefaultOrganization = $rootScope.$watch('identity.guest.organization.id', function (value, oldValue) {
      if (value === oldValue) {
        return;
      }
      var currentOrg = _.get($rootScope.identity, 'guest.organization.id');
      if (!currentOrg) {
        return;
      }
      var orgInfo = _.find(value, { id: currentOrg });
      $ctrl.isAzureCognitiveServiceEnabled = isAzureConfigEnabled(orgInfo);
    });

    unRegisteredWsMessageWatcher = $rootScope.$on(WS.EVENTS.ON_MESSAGE, function (e, eventData) {
      if (eventData.data.type === 'auth') {
        $tallyfyWebSocketFactory.$setIsAuth(eventData.data.status === 'ok');
        if (eventData.data.status === 'ok') {
          var wsConfig = $tallyfyWebSocketFactory.$getWsConfig();
          $interval(function () {
            $tallyfyWebSocketFactory.$send({ type: 'heartbeat' });
          }, (wsConfig.heartbeat || 60) * 1000);
        }
      }
    });

    unRegisteredWsReadyHandler = $rootScope.$on(WS.READY, function () {
      $tallyfyWebSocketFactory.$setAuth();
    });

    leftNavHandler = $rootScope.$on('LEFT_NAV_CLOSE', function (e) {
      $ctrl.toggleLeftNav(e, true);
    });

    unRegisterLiveChatSupportWatcher = $rootScope.$watch('identity.default_organization.live_support_enabled', function (newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      setChatlioVisibility(_.get(chatlioStatus, 'isOnline', false), newValue);
    });

    unRegisteredPreferenceWatcherHandler = $scope.$watch('appCtrl.appLanguage', function (value) {
      if (!value || !value.length) {
        return;
      }
      setMyLanguage();
    });

    unRegisteredGuestOrganizations = $rootScope.$watchCollection('identity.guest.guestOrganizations', function (value, oldValue) {
      if (!value || !value.length) {
        return;
      }
      var currentOrg = _.get($rootScope.identity, 'guest.organization.id');
      if (!currentOrg) {
        return;
      }
      var orgInfo = _.find(value, { id: currentOrg });
      $ctrl.isAzureCognitiveServiceEnabled = isAzureConfigEnabled(orgInfo);
    });

    function applyGlobalStyle() {
      $timeout(function () {
        if (globalStyleId) {
          FroalaService.applyGlobalStyle(globalStyleId, 'unloadCSS');
        }
      }, 1000).then(function () {
        globalStyleId = Helper.guid();
        FroalaService.applyGlobalStyle(globalStyleId, 'loadCSS');
      }, 0);
    }

    unRegisteredGlobalCssOrganization = $rootScope.$watch('identity.default_organization.global_css', function (value, oldValue) {
      if (value === oldValue) {
        return;
      }
      applyGlobalStyle();
    });

    unRegisteredSupportBarMessageHandler = $rootScope.$on('SHOW_SUPPORT_BAR_MESSAGE', function (e, data) {
      $ctrl.supportBarMessage = data.message;
    });

    unRegisteredNavFavoritesHandler = $rootScope.$on('NAV_FAVORITES:DATA_LOADED', function () {
      checkScroll();
    });

    /**
     * event handler when users / guests / groups value has been updated on the API side. Event supported by Pubnub
     * @param {*} event
     * @param {*} data
     * @type {*|(function())}
     */
    unRegisteredPubnubUsersGuestsAndGroupsHandler = $rootScope.$on('PN_LISTENING:sync_data', function (e, data) {
      processPubnubSyncData(data);
    });
  }
})();
